import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import { acquireToken } from "../../authconfig";

axios.defaults.baseURL = process.env.REACT_APP_BASEURL;

axios.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = await acquireToken();
    if (config.headers) {
      config.headers["Authorization"] = token ? `Bearer ${token}` : "";
      config.headers["Access-Control-Allow-Origin"] = "*";
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedError) {
      console.error("Logging unexpected error :::", error);
    }

    return Promise.reject(error);
  }
);

export default axios;
