import { Dropdown } from "primereact/dropdown";

const PreferedProductTabView = (): React.ReactElement => {
  return (
    <div className="grid">
      <div className="col-6">
        <div className="h-full border-right-1">
          <h3 className="text-center mb-6">Payor</h3>
          <div className="mb-8">
            <label>IVIG:</label>
            <div className="w-full flex flex-column gap-3 align-items-center">
              <Dropdown
                className="w-8"
                id=""
                value=""
                // onChange={handleChange}
                options={[]}
                filter
                // optionLabel={optionLabel}
                placeholder="Product1"
              />
              <Dropdown
                className="w-8"
                id=""
                value=""
                // onChange={handleChange}
                options={[]}
                filter
                // optionLabel={optionLabel}
                placeholder="Product2"
              />
              <Dropdown
                className="w-8"
                id=""
                value=""
                // onChange={handleChange}
                options={[]}
                filter
                // optionLabel={optionLabel}
                placeholder="Product3"
              />
            </div>
          </div>
          <div>
            <label>SCIG:</label>
            <div className="w-full flex flex-column gap-3 align-items-center">
              <Dropdown
                className="w-8"
                id=""
                value=""
                // onChange={handleChange}
                options={[]}
                filter
                // optionLabel={optionLabel}
                placeholder="Product1"
              />
              <Dropdown
                className="w-8"
                id=""
                value=""
                // onChange={handleChange}
                options={[]}
                filter
                // optionLabel={optionLabel}
                placeholder="Product2"
              />
              <Dropdown
                className="w-8"
                id=""
                value=""
                // onChange={handleChange}
                options={[]}
                filter
                // optionLabel={optionLabel}
                placeholder="Product3"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="h-full">
          <h3 className="text-center mb-6">InfuCare</h3>
          <div className="mb-8">
            <label>IVIG:</label>
            <div className="w-full flex flex-column gap-3 align-items-center">
              <Dropdown
                className="w-8"
                id=""
                value=""
                // onChange={handleChange}
                options={[]}
                filter
                // optionLabel={optionLabel}
                placeholder="Product1"
              />
              <Dropdown
                className="w-8"
                id=""
                value=""
                // onChange={handleChange}
                options={[]}
                filter
                // optionLabel={optionLabel}
                placeholder="Product2"
              />
              <Dropdown
                className="w-8"
                id=""
                value=""
                // onChange={handleChange}
                options={[]}
                filter
                // optionLabel={optionLabel}
                placeholder="Product3"
              />
            </div>
          </div>
          <div className="mb-6">
            <label>SCIG:</label>
            <div className="w-full flex flex-column gap-3 align-items-center">
              <Dropdown
                className="w-8"
                id=""
                value=""
                // onChange={handleChange}
                options={[]}
                filter
                // optionLabel={optionLabel}
                placeholder="Product1"
              />
              <Dropdown
                className="w-8"
                id=""
                value=""
                // onChange={handleChange}
                options={[]}
                filter
                // optionLabel={optionLabel}
                placeholder="Product2"
              />
              <Dropdown
                className="w-8"
                id=""
                value=""
                // onChange={handleChange}
                options={[]}
                filter
                // optionLabel={optionLabel}
                placeholder="Product3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreferedProductTabView;
