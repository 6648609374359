import React, { useState } from "react";
import "../../assets/css/notesmanager.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  SortDecre,
  SortIncre,
  IconViewNM,
  GeneratePDFNM,
} from "../../assets/images/Icon";

const NotesManagerDataTable = ({
  data,
  filters,
  onStatusUpdateLoading,
  search_string,
  onRowClick,
  onActionClick,
}: {
  data: any;
  filters: any;
  search_string: string;
  onStatusUpdateLoading: (isLoading: boolean) => void;
  onRowClick: (event: any, rowData: any) => void; // Callback for row click
  onActionClick: (action: string, rowData: any) => void; // Callback for action icons
}) => {
  const pageOptions = [5, 10, 25, 50, 75, 100, 125];

  const powerappUrl = process.env.REACT_APP_Notes_MANAGER_LINK;

  // Sort Icon Logic
  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  const MrnBody = (rowData: any) => {
    return (
      <span
        className="mrn font-semibold font-italic"
        style={{ color: "#0a3e71", cursor: "pointer" }}
        onClick={() =>
          (window.location.href = `${powerappUrl}&uid=${Number(rowData.mrn)
            .toString(16)
            .toUpperCase()}`)
        }
      >
        {rowData.mrn}
      </span>
    );
  };

  // Table columns
  const columns = [
    { field: "date", header: "Date", sortable: true, body: (rowData: any) => <span>{formatDate(rowData.date)}</span> },
    { field: "invoice_number", header: "Inv#", sortable: true },
    { field: "mrn", header: "MRN", sortable: true, body : MrnBody },
    { field: "patient_name", header: "Patient Name", sortable: true },
    { field: "notes_type", header: "Type", sortable: true },
    { field: "entered_by", header: "Entered By", sortable: true },
    { field: "subject", header: "Subject", sortable: true },
    { field: "fp_date", header: "F/U Date", sortable: true, body: (rowData: any) => <span>{formatDate(rowData.fp_date)}</span> },
    { field: "fp_assigned", header: "F/U Assigned", sortable: true },
  ];

  // Date formatting function
  const formatDate = (value: string | Date) => {
    if (!value) return "";
    const date = new Date(value);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  // Custom date template
  const dateBodyTemplate = (rowData: any) => formatDate(rowData.date);

  // Action icons template
  const actionTemplate = (rowData: any) => (
    <div className="action-icons">
      <img
        src={IconViewNM}
        alt="View"
        onClick={(e) => {
          e.stopPropagation(); // Prevent row click
          onActionClick("edit", rowData);
        }}
        width={22}
        height={22}
        className="icon clickable"
      />
      <img
        src={GeneratePDFNM}
        alt="Info"
        onClick={(e) => {
          e.stopPropagation(); // Prevent row click
          onActionClick("info", rowData);
        }}
        width={22}
        height={22}
        className="icon clickable"
      />
    </div>
  );

  return (
    <DataTable
      value={data}
      showGridlines
      scrollable
      removableSort
      sortIcon={sortIcon}
      scrollHeight="645px"
      paginator
      rowsPerPageOptions={pageOptions}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      rows={25}
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      filters={filters}
      globalFilterFields={columns.map((column) => column.field)}
      emptyMessage="No Data Found"
      rowHover
      onRowClick={(e) => onRowClick(e, e.data)} // Handle row click
    >
      {columns.map((column, index) => (
        <Column
          key={index}
          field={column.field}
          header={column.header}
          sortable={column.sortable}
          body={column.body}
        />
      ))}
      {/* Add action column */}
      <Column header="Actions" body={actionTemplate} />
    </DataTable>
  );
};

export default NotesManagerDataTable;
