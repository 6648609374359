import React, { useCallback, useEffect, useState } from "react";
import { AppDispatch, RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import { getAssignRoleByUser } from "../redux/store/RoutePermission";

interface ProtectedRouteProps {
  element: React.ReactElement;
  userEmail?: string;
}

const PrivateRoute = ({ element, userEmail }: ProtectedRouteProps) => {
  const [userPermission, setUserPermission] = useState<
    Record<string, { read: string; deny: string; role: string }> | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);
  const [fromNavigation, setFromNavigation] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();

  const { assignRoleByUser } = useSelector(
    (state: RootState) => state.routePermissionReducer
  );

  const location = useLocation();

  useEffect(() => {
    if (userEmail) {
      setLoading(true);
      dispatch(getAssignRoleByUser());
    }
  }, [userEmail, dispatch]);

  useEffect(() => {
    if (assignRoleByUser.length > 0) {
      // Aggregate permissions by page
      const permissions = assignRoleByUser.reduce(
        (acc, { page, read, deny, role }) => {
          if (!acc[page]) {
            acc[page] = { read, deny, role };
          } else {
            const existingPermission = acc[page];
            
            const updatedPermission = {
              read: existingPermission.read === "Y" || read === "Y" ? "Y" : "N",
              deny: existingPermission.deny === "Y" || deny === "Y" ? "Y" : "N",
              role: `${existingPermission.role},${role}`, 
            };
  
            acc[page] = updatedPermission;
          }
          return acc;
        },
        {} as Record<string, { read: string; deny: string; role: string }>
      );
  
      setUserPermission(permissions);
      setLoading(false);
    }
  }, [assignRoleByUser]);

  useEffect(() => {
    const currentPage = sidebarItems.find(
      (item) => location.pathname.startsWith(item.to)
    );
    if (currentPage) {
      setFromNavigation(currentPage.page);
    }
  }, [location.pathname]);

  const sidebarItems = [
    {
      to: "/fax",
      label: "Fax",
      SchemaName: "fax",
      isExternal: false,
      page: "Fax Module",
    },
    {
      to: "/census",
      label: "Census",
      SchemaName: "census",
      isExternal: false,
      page: "Census",
    },
    {
      to: "/ar-manager",
      label: "A/R Manager",
      SchemaName: "ar-manager",
      isExternal: false,
      page: "AR Manager",
    },
    {
      to: "/notes-manager",
      label: "Notes Manager",
      SchemaName: "notes-manager",
      isExternal: false,
      page: "Notes Manager",
    },
    {
      to: "/insurance",
      label: "Insurance",
      SchemaName: "insurance",
      isExternal: false,
      page: "Insurance",
    },
    {
      to: "/pricing",
      label: "Pricing",
      SchemaName: "pricing",
      isExternal: false,
      page: "Pricing",
    },
    {
      to: "/tasklist",
      label: "Task List",
      SchemaName: "task-list",
      isExternal: false,
      page: "Task List",
    },
    {
      to: "/inventory",
      label: "Inventory",
      SchemaName: "inventory",
      isExternal: false,
      page: "Inventory",
    },
    
    {
      to: "/shipment",
      label: "Shipment",
      SchemaName: "shipment",
      isExternal: false,
      page: "Shipment",
    }
  ];

  const render = useCallback(() => {
    if (loading) {
      return null;
    }

    if (userPermission) {
      const permissions = userPermission[fromNavigation] || null;
      if (!permissions || permissions.deny === "Y") {
        return (
          <Navigate
            to="/access-denied"
            state={{ from: location.pathname, page: fromNavigation }}
          />
        );
      }

      if (permissions.role === "Exception") {
        if (permissions.deny === "Y") {
          return (
            <Navigate
              to="/access-denied"
              state={{ from: location.pathname, page: fromNavigation }}
            />
          );
        } else {
          if (permissions.read === "Y") {
            return element;
          } else {
            <Navigate
              to="/access-denied"
              state={{ from: location.pathname, page: fromNavigation }}
            />;
          }
        }
      }
      
      return permissions.read === "Y" ? (
        element
      ) : (
        <Navigate
          to="/access-denied"
          state={{ from: location.pathname, page: fromNavigation }}
        />
      );
    }

    return null;
  }, [loading, userPermission, fromNavigation, location.pathname, element]);

  return <>{render()}</>;
};

export default PrivateRoute;
