export const QuestionList = [
    {
        "response_id": 122470,
        "question_id": 3683,
        "question": "Is This Payor Billed in HCPC Units?",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 124620,
        "question_id": 3684,
        "question": "Split Compound Ingredients into Separate Claims (Used only for WebMD Pharmacy electronic claims)",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 126948,
        "question_id": 3685,
        "question": "Use Unique Rx#s Per Claim",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 129098,
        "question_id": 3686,
        "question": "Special Pricing Based On HCPC",
        "Question_type": "DropDown",
        "answer": "N"
    },
    {
        "response_id": 132186,
        "question_id": 3687,
        "question": "Automatic Billing Method",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 134194,
        "question_id": 3688,
        "question": "Automatic Billing Method Type",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 136658,
        "question_id": 3689,
        "question": "Prescription Billing Method",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 138054,
        "question_id": 3690,
        "question": "Update Special Prices on Cost/Price Update",
        "Question_type": "DropDown",
        "answer": "Y"
    },
    {
        "response_id": 140382,
        "question_id": 3691,
        "question": "Update Expected Prices on Cost/Price Update",
        "Question_type": "DropDown",
        "answer": "N"
    },
    {
        "response_id": 142532,
        "question_id": 3692,
        "question": "Create Text Image File When Printing",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 144860,
        "question_id": 3693,
        "question": "Billing Defaults:Biller",
        "Question_type": "DropDown",
        "answer": "0"
    },
    {
        "response_id": 147010,
        "question_id": 3694,
        "question": "Billing Defaults:Collector",
        "Question_type": "DropDown",
        "answer": "0"
    },
    {
        "response_id": 149338,
        "question_id": 3695,
        "question": "Add NDC On Second Line When Copying From DT",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 151487,
        "question_id": 3696,
        "question": "Use Special Codes in Box 44 On UB92",
        "Question_type": "DropDown",
        "answer": "Y"
    },
    {
        "response_id": 153816,
        "question_id": 3697,
        "question": "Print Provider Address and Box 2-3 on 30-1",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 155965,
        "question_id": 3698,
        "question": "Print Time When Billing Nursing Visits",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 158294,
        "question_id": 3699,
        "question": "Remove Invoice #/Payor From Right Hand Corner On HCFA 1500",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 160443,
        "question_id": 3700,
        "question": "Use Daily Rental Price On DT",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 162772,
        "question_id": 3701,
        "question": "Use Medicare Rules for Recurring Rentals",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 164921,
        "question_id": 3702,
        "question": "Create Billing Follow Up Note With Due Date in # Days",
        "Question_type": "Single Line Text",
        "answer": "0"
    },
    {
        "response_id": 167627,
        "question_id": 3703,
        "question": "Auto Split Delivery Tickets When Confirmed",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 169909,
        "question_id": 3704,
        "question": "This Payor Requires Authorizations",
        "Question_type": "DropDown",
        "answer": "N"
    },
    {
        "response_id": 172294,
        "question_id": 3705,
        "question": "Collapse Like Items",
        "Question_type": "DropDown",
        "answer": "0"
    },
    {
        "response_id": 173878,
        "question_id": 3706,
        "question": "Collapse Like Items Based On HCPC",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 176207,
        "question_id": 3707,
        "question": "Split Line Items That Span Months",
        "Question_type": "DropDown",
        "answer": "0"
    },
    {
        "response_id": 178355,
        "question_id": 3708,
        "question": "Signed Document Required Prior to Billing",
        "Question_type": "DropDown",
        "answer": "0"
    },
    {
        "response_id": 180685,
        "question_id": 3709,
        "question": "Send Payor Address",
        "Question_type": "DropDown",
        "answer": "0"
    },
    {
        "response_id": 182833,
        "question_id": 3710,
        "question": "Number of Days from DOS for Timely Filing (Leave Blank for N/A)",
        "Question_type": "Single Line Text",
        "answer": null
    },
    {
        "response_id": 185163,
        "question_id": 3711,
        "question": "Default Percent of Coverage",
        "Question_type": "Single Line Text",
        "answer": "0.0000"
    },
    {
        "response_id": 187311,
        "question_id": 3712,
        "question": "Is This A Supplementary Payor?",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 189639,
        "question_id": 3713,
        "question": "Combine Like Orders when Batch Billing",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 191788,
        "question_id": 3714,
        "question": "340B Payor",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 194117,
        "question_id": 3715,
        "question": "Include in Charge Export",
        "Question_type": "Checkboxes",
        "answer": null
    },
    {
        "response_id": 196268,
        "question_id": 3716,
        "question": "Charge Export Adjustment Codes",
        "Question_type": "Checkboxes",
        "answer": null
    },
    {
        "response_id": 198594,
        "question_id": 3717,
        "question": "Span Dates on Recurring Rental Claims",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 200745,
        "question_id": 3718,
        "question": "Create Claims by Calendar Month (Line Item Only)",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 203074,
        "question_id": 3719,
        "question": "Use Medi-Cal Modifier On Invoice Line Items",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 205222,
        "question_id": 3720,
        "question": "Use Medi-Cal Formatting For Supplemental Info Line On CMS/UB04",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 207552,
        "question_id": 3721,
        "question": "Print Medi-Cal Itemized Listing (HCFA/CMS1500 Invoices Only)",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 209700,
        "question_id": 3722,
        "question": "Minimum Acceptable Profit Margin (NCPDP Claims)",
        "Question_type": "Single Line Text",
        "answer": "0.0000"
    },
    {
        "response_id": 212030,
        "question_id": 3723,
        "question": "Auto Move SPRx Claim if Minimum Profit Margin is Met",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 214178,
        "question_id": 3724,
        "question": "Hold Subsequent Rental Claims Until Initial Claim Is Paid (Line Item Only)",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 216508,
        "question_id": 3725,
        "question": "Bill Recurring Rental Claims in Arrears (Line Item Only)",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 218656,
        "question_id": 3726,
        "question": "Auto Transfer On ERN Payment",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 220986,
        "question_id": 3727,
        "question": "Auto Transfer Payment Threshold %",
        "Question_type": "Single Line Text",
        "answer": "0.0000"
    },
    {
        "response_id": 223754,
        "question_id": 3728,
        "question": "Auto Transfer Adjustment Code",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 225463,
        "question_id": 3729,
        "question": "Payor Requires Original Signature on Supporting Documents",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 227611,
        "question_id": 3730,
        "question": "Stop Oxygen Billing for Denial Reason Code (Line Item Only)",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 229941,
        "question_id": 3731,
        "question": "Stop Oxygen Billing for Denial Reason Code (Line Item Only)-Denial Reason",
        "Question_type": "DropDown",
        "answer": "0"
    },
    {
        "response_id": 232386,
        "question_id": 3732,
        "question": "Import Box 32 Address",
        "Question_type": "DropDown",
        "answer": "0"
    },
    {
        "response_id": 234419,
        "question_id": 3733,
        "question": "Auto Split Per Diem Line Items for Daily Billing (Batch Billing, Line Item Only)",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 236567,
        "question_id": 3734,
        "question": "Generate supporting documentation for non-billable and $0 billable items",
        "Question_type": "Checkboxes",
        "answer": "1"
    },
    {
        "response_id": 238897,
        "question_id": 3735,
        "question": "Split Delivery Tickets by Authorization (Paper Claims Only)",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 241045,
        "question_id": 3736,
        "question": "Require Benefit Verification for SPRx",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 243376,
        "question_id": 3737,
        "question": "Always Bill for Denial (Medical Claims Only)",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 245524,
        "question_id": 3738,
        "question": "Automated Per Diem Template",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 247854,
        "question_id": 3739,
        "question": "Do Not Status Claims for Missing Diagnosis",
        "Question_type": "Checkboxes",
        "answer": "0"
    },
    {
        "response_id": 249926,
        "question_id": 3740,
        "question": "Create Adjustment for Claim Submission Fee Code (Line Item Only)",
        "Question_type": "DropDown",
        "answer": "130"
    },
    {
        "response_id": 252332,
        "question_id": 3741,
        "question": "Create Adjustment for Claim Submission Fee Code (Line Item Only):Adj. Code",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 255706,
        "question_id": 3742,
        "question": "Supports Billing of Discarded Compounded Medications",
        "Question_type": "DropDown",
        "answer": null
    },
    {
        "response_id": 256810,
        "question_id": 3743,
        "question": "Requires Authorization by Dose/Freq",
        "Question_type": "Checkboxes",
        "answer": null
    },
    {
        "response_id": 258960,
        "question_id": 3744,
        "question": "Always Transfer Deductibles",
        "Question_type": "Checkboxes",
        "answer": null
    }
]
