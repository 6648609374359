import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "../../utils/api/index";

// Define base API URL
const baseUrl = process.env.REACT_APP_BASEURL;

// Define types for state and actions
interface Referral {
  referral_id: string;
  first_name: string;
  last_name: string;
  referral_date: string | null;
  date_of_birth: string | null;
  mrn: string;
  document_path: string;
  type: string;
  email: string;
}

interface FaxState {
  loading: boolean;
  modalLoading: boolean;
  item: Referral[];
  isOpen: boolean;
  isEditOpen: boolean;
  rowData: any | null;
  isUserExpAuthPermission: boolean;
  address: any[];
  isDeleteOpen: boolean;
  documentItem: any[];
  isDocumentOpen: boolean;
  isAddDocumentOpen: boolean;
  faxData: any[];
  junkFaxData: any[];
  archiveData: any[];
  prescriber: boolean;
  btnLoading: boolean;
  otpResponse: any | null;
  prescriberList: any | null;
  sendEmailModal: boolean;
  historyModal: boolean;
  categoryOptions: any[];
  DocumentDescription: any[];
  state: any[];
  city: any[];
  error: string | null;
  patients: PatientResponse[];
  sites: any[];
  faxHistory: [];
  historyloader: boolean;
  patientLoading?: boolean;
  assignFaxLoading?: boolean;
  allFaxData: any[];
  assign_fax: [];
  userOrGroupList?: [];
  emailLoading?: boolean;
  markJunkLoading?: boolean;
  assignLoading?: boolean;
  historyModalHeader?: string;
  faxFileData?: string;
  faxFileLoading?: boolean;
}

type SplitAndAssignType = {
  document_description: string;
  document_type: string;
  doc_key: string;
  mrn: string;
  pat_first_name: string;
  pat_last_name: string;
  notes: string;
  site_no: string;
  original_filePath: string;
  doc_status: string;
  category: string;
  faxsrc: string;
  faxsite: string;
  doc_no: string;
  email_address: string;
  folder: string;
  pageRange: string;
  remaining_DOC_count: string;
  doC_NAME: string;
  fax_folder_id: string;
  type: string;
  detail_id: string;
  send_mail: boolean;
  sendMailAssign: {
    to: string;
    cc: string;
    subject: string;
    folder: string;
    file: string;
    emailBody: string;
    splitPath: string;
  };
};

// Define async thunk action payload types
interface FetchAllPayload {
  userEmail: string | null;
}

// Async thunk to fetch fax data
export const faxfetchAll = createAsyncThunk<FaxState, FetchAllPayload>(
  "fax/faxfetchAll",
  async ({ userEmail }) => {
    const defaultState: FaxState = {
      loading: false,
      modalLoading: false,
      item: [],
      isOpen: false,
      isEditOpen: false,
      rowData: null,
      isUserExpAuthPermission: false,
      address: [],
      isDeleteOpen: false,
      documentItem: [],
      isDocumentOpen: false,
      isAddDocumentOpen: false,
      prescriber: true,
      btnLoading: false,
      otpResponse: null,
      prescriberList: null,
      sendEmailModal: false,
      historyModal: false,
      categoryOptions: [],
      DocumentDescription: [],
      faxData: [],
      archiveData: [],
      junkFaxData: [],
      state: [],
      city: [],
      error: null,
      patients: [],
      sites: [],
      faxHistory: [],
      historyloader: false,
      allFaxData: [],
      assign_fax: [],
    };

    if (userEmail == null) {
      return defaultState;
    }

    try {
      const response = await fetch(
        `${baseUrl}/api/PhysicianPortal/PhysicianPatientData/${userEmail}`
      );
      const jsonRes = await response.json();
      return {
        ...defaultState,
        loading: false,
        item: jsonRes || [],
        isUserExpAuthPermission: jsonRes.permission?.IsUserPermission ?? false,
      };
    } catch (error) {
      console.error("Error fetching patient data:", error);
      throw new Error("Failed to fetch patient data");
    }
  }
);
interface SearchPatientPayload {
  query: string;
  mail: string | undefined;
}
// Define a type for a single fax detail
interface PatientResponse {
  // Example properties, adjust based on the API response
  id: number;
  name: string;
  // Add other properties as needed
}
interface FaxDetail {
  fax_detail_id: number;
  fax_line: string;
  fax_folder_id: number;
  fax_folder_name: string;
  file_path: string;
  recipient_fax_number: string;
  fax_number: string;
  date_and_time: string;
  caller: string;
  pages: number;
  assignment_status: string;
  file_name: string;
  is_junk: boolean;
  patient_name: string | null;
  pt_name: string | null;
  doc_category_description: string | null;
  doc_description: string | null;
  owner_name: string | null;
  fax_record_id: number | null;
  address: [];
  state: [];
  city: [];
  sites: [];
  error: string | null;
  faxHistory: [];
  historyloader: string;
  assign_fax: [];
}

export const fetchFaxData = createAsyncThunk("fax/fetchFaxData", async () => {
  try {
    // const response = await fetch(`${baseUrl}/api/FaxModule/getFaxQueueList`);
    const response = await axios.get(
      `${baseUrl}/api/FaxModule/getFaxQueueList`
    );
    if (!response.statusText) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    const data = await response.data?.data;
    return data;
    // const jsonRes = await response.json();
    // return jsonRes.data;
  } catch (error) {
    console.error("Error fetching fax details:", error);
    throw new Error("Failed to fetch fax details");
  }
});

export const getDocumentCatogory = createAsyncThunk<FaxDetail[], void>(
  "fax/getDocumentCatogory",
  async () => {
    try {
      //   const response = await fetch(
      //     `${baseUrl}/api/FaxModule/getDocumentCatogory`
      //   );
      const response = await axios.get(
        `${baseUrl}/api/FaxModule/getDocumentCatogory`
      );
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data?.data;
      return data;
      //   const jsonRes = await response.json();
      //   if (!Array.isArray(jsonRes.data)) {
      //     throw new Error("Invalid response format");
      //   }
      //   return jsonRes.data;
    } catch (error) {
      console.error("Error fetching document category:", error);
      throw new Error("Failed to fetch document category");
    }
  }
);

export const getDocumentDescription = createAsyncThunk<FaxDetail[], void>(
  "fax/getDocumentDescription",
  async () => {
    try {
      //   const response = await fetch(
      //     `${baseUrl}/api/FaxModule/getDocumentDescription`
      //   );
      const response = await axios.get(
        `${baseUrl}/api/FaxModule/getDocumentDescription`
      );
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data?.data;
      return data;
      //   const jsonRes = await response.json();
      //   if (!Array.isArray(jsonRes.data)) {
      //     throw new Error("Invalid response format");
      //   }
      //   return jsonRes.data;
    } catch (error) {
      console.error("Error fetching document description:", error);
      throw new Error("Failed to fetch document description");
    }
  }
);

export const getAddressMappingByState = createAsyncThunk<FaxDetail[], number>(
  "fax/getAddressMappingByState",
  async (stateId) => {
    try {
      //   const response = await fetch(
      //     `${baseUrl}/api/PhysicianPortal/getAddressMappingByState/${stateId}`
      //   );
      const response = await axios.get(
        `${baseUrl}/api/PhysicianPortal/getAddressMappingByState/${stateId}`
      );
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data?.data;
      return data;
      //   const jsonRes = await response.json();
      //   if (!Array.isArray(jsonRes.data)) {
      //     throw new Error("Invalid response format");
      //   }
      //   return jsonRes.data;
    } catch (error) {
      console.error("Error fetching address mapping:", error);
      throw new Error("Failed to fetch address mapping");
    }
  }
);

export const GetAddressMasterInfo = createAsyncThunk<FaxDetail[], void>(
  "fax/GetAddressMasterInfo",
  async () => {
    try {
      //   const response = await fetch(
      //     `${baseUrl}/api/PhysicianPortal/GetAddressMasterInfo`
      //   );
      const response = await axios.get(
        `${baseUrl}/api/PhysicianPortal/GetAddressMasterInfo`
      );
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data?.data;
      return data;
      //   const textResponse = await response.text();

      //   const jsonString = atob(textResponse);
      //   const jsonRes = JSON.parse(jsonString);
      //   return jsonRes.Data.StateInfoDTO;
    } catch (error) {
      console.error("Error fetching address master info:", error);
      throw new Error("Failed to fetch address master info");
    }
  }
);
export const getSearchPatientList = createAsyncThunk<
  PatientResponse[], // Return type
  SearchPatientPayload & { cancelToken: any } // Input payload type
>(
  "patients/getSearchPatientList",
  async ({ query, mail, cancelToken }, { rejectWithValue }) => {
    try {
      const searchQuery = `{"patient_status":["Active","Inactive","Cancelled","On Hold","Pending"],"patientSearch":"${query}"}`;
      //   const response = await fetch(
      //     `${baseUrl}/api/FaxModule/getSearchPatienList?json=${searchQuery}&mail=${mail}`
      //   );
      const response = await axios.get(
        `${baseUrl}/api/FaxModule/getSearchPatienList?json=${searchQuery}&mail=${mail}`, { cancelToken }
      );
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data?.data;
      //   console.log('data', data);

      return data;
      //   if (!response.ok) {
      //     // Customize the error message based on response
      //     const errorResponse = await response.json();
      //     throw new Error(errorResponse.title || "Failed to fetch patients");
      //   }
      //   return await response.json();
    } catch (error: unknown) {
      // Explicitly type the error as unknown
      if (error instanceof Error) {
        // Check if error is an instance of Error
        return rejectWithValue(error.message); // Use the message from the Error object
      }
      return rejectWithValue("An unknown error occurred"); // Fallback message for unknown errors
    }
  }
);

export const getSites = createAsyncThunk("fax/getSites", async () => {
  try {
    // const response = await fetch(`${baseUrl}/api/PhysicianPortal/GetSites`);
    const response = await axios.get(`${baseUrl}/api/PhysicianPortal/GetSites`);
    if (!response.statusText) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    //   const data = await response.data;
    const base64Res = await response.data; // Get the Base64 encoded string

    // Decode Base64 to a JSON string
    const decodedRes = atob(base64Res); // atob decodes Base64 to plain text

    // Parse the decoded JSON string to an object
    const jsonRes = JSON.parse(decodedRes);

    return jsonRes.Data; // Assuming `Data` holds the array of sites
    //   return data;
  } catch (error) {
    console.error("Error fetching sites:", error);
    throw new Error("Failed to fetch sites");
  }
});

export const getFaxHistory = createAsyncThunk<
  any, // Specify the type of the return value here if known
  number // Specify the type of the first argument (the id)
>("fax/getFaxHistory", async (id: number) => {
  try {
    // const response = await fetch(
    //   `${baseUrl}/api/FaxModule/getFaxDetailHistory/${id}`
    // );
    const response = await axios.get(
      `${baseUrl}/api/FaxModule/getFaxDetailHistory/${id}`
    );
    if (!response.statusText) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    const data = await response.data?.data;
    return data;
    // const jsonRes = await response.json(); // Assuming it returns JSON data
    // return jsonRes.data; // Ensure `data` holds the array of history entries
  } catch (error) {
    console.error("Error fetching fax history:", error);
    throw new Error("Failed to fetch fax history");
  }
});

export const updateFaxOwnerName = createAsyncThunk(
  "fax/updateFaxOwnerName",
  async (data: {
    fax_record_id: number;
    fax_owner_name: string;
    created_by: string;
    delete: number;
  }) => {
    // const response = await fetch(
    //   `${baseUrl}/api/FaxModule/updateFaxDirectoryOwnerName`,
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(data),
    //   }
    // );
    const response = await axios.post(
      `${baseUrl}/api/FaxModule/updateFaxDirectoryOwnerName`,
      data
    );
    if (!response.statusText) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    // if (!response.ok) {
    //   throw new Error("Failed to update fax owner name");
    // }

    return await response.data; // Return the response data
  }
);

export const markJunkFax = createAsyncThunk(
  "fax/markJunkFax",
  async (
    data: {
      action_type: string;
      description: string;
      LoginEmail: string;
      fax_detail_id: number;
      is_junk: boolean;
      status: string;
    },
    apiThunk
  ) => {
    try {
      //   const response = await fetch(`${baseUrl}/api/FaxModule/updateFaxDetail`, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(data),
      //   });
      const response = await axios.post(
        `${baseUrl}/api/FaxModule/updateFaxDetail`,
        data
      );
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      //   if (!response.ok) {
      //     throw new Error("Failed to update fax owner name");
      //   }
      await apiThunk.dispatch(fetchFaxData());
      return await response.data; // Return the response data
    } catch (error) {
      console.error("Error", error);
    }
  }
);

export const getFaxDocDrugList = createAsyncThunk(
  "fax/getFaxDocDrugList",
  async (mrn: number) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/FaxModule/getFaxDocDrugList/${mrn}`
      );
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data?.data;
      return data;
      //   const jsonRes = await response.json();
      //   return jsonRes.data;
    } catch (error) {
      console.error("Error fetching fax history:", error);
      throw new Error("Failed to fetch fax history");
    }
  }
);

export const sendEmail = createAsyncThunk(
  "fax/sendEmail",
  async ({
    data,
    callback,
  }: {
    data: { to: string; cc: string; subject: string; body: string };
    callback?: () => void;
  }) => {
    try {
      //   const response = await fetch(
      //     `${baseUrl}/api/FaxModule/SplitAssignFaxMail`,
      //     {
      //       method: "POST",
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //       body: JSON.stringify(data),
      //     }
      //   );

      const response = await axios.post(
        `${baseUrl}/api/FaxModule/SplitAssignFaxMail`,
        data
      );
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      if (response.statusText && callback) {
        callback();
      }
      const jsonRes = await response.data;
    } catch (error) {
      console.error("Error fetching sites:", error);
      throw new Error("Failed to fetch sites");
    }
  }
);

export const splitAndAssign = createAsyncThunk(
  "fax/splitAndAssign",
  async ({
    data,
    callback,
  }: {
    data: SplitAndAssignType;
    callback?: () => void;
  }) => {
    try {
      //   const response = await fetch(`${baseUrl}/api/FaxModule/SplitAssignFax`, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(data),
      //   });

      const response = await axios.post(`${baseUrl}/api/FaxModule/SplitAssignFax`, data);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      if (response.statusText && callback) {
        callback();
      }
      const jsonRes = await response.data;
    } catch (error) {
      console.error("Error fetching sites:", error);
      throw new Error("Failed to fetch sites");
    }
  }
);

export const getUsersAndGroups = createAsyncThunk(
  "fax/getUsersAndGroups",
  async ({ query, isUser }: { query: string; isUser: boolean }) => {
    try {
      const apiEndpoint = `${baseUrl}/api/FaxModule/SearchOffice365Users?searchTerm=${query}&isUser=${isUser}`;
      //   const response = await fetch(apiEndpoint);

      const response = await axios.get(apiEndpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      const sortedData = data.sort(
        (
          a: { displayName: string; userPrincipalName: string; mail: string },
          b: { displayName: string; userPrincipalName: string; mail: string }
        ) => {
          if (a.displayName?.toLowerCase() < b.displayName?.toLowerCase()) {
            return -1;
          }
          if (a.displayName?.toLowerCase() > b.displayName?.toLowerCase()) {
            return 1;
          }
          return 0;
        }
      );
      return sortedData;
    } catch (error) {
      console.error("Error fetching sites:", error);
      throw new Error("Failed to fetch sites");
    }
  }
);

export const getFaxFile = createAsyncThunk(
  "fax/getFaxFile",
  async ({ fileName }: { fileName: string; }) => {
    try {
      const apiEndpoint = `${baseUrl}/api/AzureFileShare/getFaxFile/${fileName}`;
      //   const response = await fetch(apiEndpoint);

      const response = await axios.get(apiEndpoint);
      if (response.status !== 200) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      console.log("DatA", data);
      return data;
    } catch (error) {
      console.error("Error fetching sites:", error);
      throw new Error("Failed to fetch sites");
    }
  }
);

// Initial state for the fax slice
const initialState: FaxState = {
  loading: false,
  modalLoading: false,
  item: [],
  isOpen: false,
  isEditOpen: false,
  rowData: null,
  isUserExpAuthPermission: false,
  address: [],
  isDeleteOpen: false,
  documentItem: [],
  isDocumentOpen: false,
  isAddDocumentOpen: false,
  faxData: [],
  junkFaxData: [],
  archiveData: [],
  prescriber: true,
  btnLoading: false,
  otpResponse: null,
  prescriberList: null,
  sendEmailModal: false,
  historyModal: false,
  historyModalHeader: "",
  categoryOptions: [],
  DocumentDescription: [],
  state: [],
  city: [],
  error: null,
  patients: [],
  sites: [],
  faxHistory: [],
  historyloader: false,
  patientLoading: false,
  allFaxData: [],
  assign_fax: [],
  emailLoading: false,
  markJunkLoading: false,
  assignLoading: false,
  userOrGroupList: [],
  faxFileData: undefined,
  faxFileLoading: false,
};

const faxSlice = createSlice({
  name: "fax",
  initialState,
  reducers: {
    changePrescriberState(state, action: PayloadAction<boolean>) {
      state.prescriber = action.payload;
    },
    deleteToggle(state, action: PayloadAction<{ isOpen: boolean; rowData: any }>) {
      state.isDeleteOpen = action.payload.isOpen;
      state.documentItem = action.payload.rowData;
    },
    toggleEmailModal(state, action: PayloadAction<{ sendEmailModal: boolean }>) {
      state.sendEmailModal = action.payload.sendEmailModal;
    },
    makeAssignFaxListEmpty(state, action) {
      state.assign_fax = [];
    },
    toggleHistoryModal(
      state,
      action: PayloadAction<{
        historyModal: boolean;
        historyModalHeader: string;
      }>
    ) {
      state.historyModal = action.payload.historyModal;
      if (action.payload.historyModal === false) {
        state.faxHistory = [];
        state.historyModalHeader = "";
      } else {
        state.historyModalHeader = action.payload.historyModalHeader;
      }
    },
    handleClearFaxFile(state, action) {
      state.faxFileData = undefined;
      state.faxFileLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(faxfetchAll.fulfilled, (state, action: PayloadAction<FaxState>) => {
        const { item, isUserExpAuthPermission } = action.payload;
        state.item = item;
        state.isUserExpAuthPermission = isUserExpAuthPermission;
        state.loading = false;
      })
      .addCase(faxfetchAll.pending, (state) => {
        state.loading = true;
      })
      .addCase(faxfetchAll.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchFaxData.fulfilled, (state, action: PayloadAction<FaxDetail[]>) => {
        state.loading = false;
        state.allFaxData = action.payload;
        if (Array.isArray(action.payload)) {
          state.faxData = action.payload.filter(
            (item) => !item.is_junk && item.assignment_status === "Unassigned"
          );
          state.junkFaxData = action.payload.filter(
            (item) => item.is_junk && item.assignment_status === "Unassigned"
          );
          state.archiveData = action.payload.filter(
            (item) => item.assignment_status === "Assigned"
          );
        } else {
          console.error(
            "Invalid payload received in fetchFaxData:",
            action.payload
          );
          state.faxData = [];
          state.junkFaxData = [];
          state.archiveData = [];
        }
      })
      .addCase(fetchFaxData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFaxData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(
        getDocumentCatogory.fulfilled,
        (state, action: PayloadAction<FaxDetail[]>) => {
          if (Array.isArray(action.payload)) {
            state.categoryOptions = action.payload;
          } else {
            console.error(
              "Expected an array for categoryOptions but got:",
              action.payload
            );
            state.categoryOptions = [];
          }
        }
      )
      .addCase(
        getDocumentDescription.fulfilled,
        (state, action: PayloadAction<FaxDetail[]>) => {
          if (Array.isArray(action.payload)) {
            state.DocumentDescription = action.payload;
          } else {
            console.error(
              "Expected an array for DocumentDescription but got:",
              action.payload
            );
            state.DocumentDescription = [];
          }
        }
      )
      .addCase(
        GetAddressMasterInfo.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          if (Array.isArray(action.payload)) {
            state.state = action.payload;
          } else {
            console.error(
              "Expected an array for state data but got:",
              action.payload
            );
            state.state = [];
          }
        }
      )
      .addCase(
        getAddressMappingByState.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          if (Array.isArray(action.payload)) {
            state.city = action.payload;
          } else {
            console.error(
              "Expected an array for city data but got:",
              action.payload
            );
            state.city = [];
          }
        }
      )
      .addCase(getSearchPatientList.pending, (state) => {
        state.patientLoading = true;
        state.error = null; // Reset previous error
      })
      .addCase(
        getSearchPatientList.fulfilled,
        (state, action: PayloadAction<PatientResponse[]>) => {
          state.patientLoading = false;
          // @ts-ignore
          state.patients = action.payload.data;
        }
      )
      .addCase(getSearchPatientList.rejected, (state, action) => {
        state.patientLoading = false; // Set loading to false if fetching failed
        state.error = (action.payload as string) || "Failed to fetch patients"; // Store the error message
      })
      .addCase(getSites.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSites.fulfilled, (state, action) => {
        state.loading = false;
        state.sites = action.payload; // Update the state with fetched sites
      })
      .addCase(getSites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An unknown error occurred";
      })
      .addCase(getFaxHistory.pending, (state) => {
        state.historyloader = true;

        state.error = null;
      })
      .addCase(getFaxHistory.fulfilled, (state, action) => {
        state.historyloader = false;
        state.faxHistory = action.payload; // Save the fetched history data
      })
      .addCase(getFaxHistory.rejected, (state, action) => {
        state.historyloader = false; // Stop loading
        state.error = action.error.message || "An unknown error occurred";
      })
      .addCase(getFaxDocDrugList.pending, (state) => {
        state.assignFaxLoading = true;
        state.error = null;
        state.assign_fax = [];
      })
      .addCase(getFaxDocDrugList.fulfilled, (state, action) => {
        state.assignFaxLoading = false;
        state.assign_fax = action.payload;
      })
      .addCase(getFaxDocDrugList.rejected, (state, action) => {
        state.assignFaxLoading = false; // Stop loading
        state.error = action.error.message || "An unknown error occurred";
      })
      .addCase(sendEmail.pending, (state) => {
        state.emailLoading = true;
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.emailLoading = false;
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.emailLoading = false;
      })
      .addCase(markJunkFax.pending, (state) => {
        state.markJunkLoading = true;
      })
      .addCase(markJunkFax.fulfilled, (state, action) => {
        state.markJunkLoading = false;
      })
      .addCase(markJunkFax.rejected, (state, action) => {
        state.markJunkLoading = false;
      })
      .addCase(splitAndAssign.pending, (state) => {
        state.assignLoading = true;
      })
      .addCase(splitAndAssign.fulfilled, (state, action) => {
        state.assignLoading = false;
      })
      .addCase(splitAndAssign.rejected, (state, action) => {
        state.assignLoading = false;
      })
      .addCase(getUsersAndGroups.pending, (state) => { })
      .addCase(getUsersAndGroups.fulfilled, (state, action) => {
        state.userOrGroupList = action.payload;
      })
      .addCase(getUsersAndGroups.rejected, (state, action) => { })
      .addCase(getFaxFile.pending, (state, action) => {
        state.faxFileLoading = true;
      })
      .addCase(getFaxFile.fulfilled, (state, action) => {
        state.faxFileData = action.payload;
        state.faxFileLoading = false;
      })
      .addCase(getFaxFile.rejected, (state, action) => {
        state.faxFileData = undefined;
        state.faxFileLoading = false;
      });
  },
});

// Export actions
export const {
  changePrescriberState,
  deleteToggle,
  toggleEmailModal,
  toggleHistoryModal,
  makeAssignFaxListEmpty,
  handleClearFaxFile,
} = faxSlice.actions;

// Export the reducer
export default faxSlice.reducer;
