import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { Column } from "primereact/column";
import { FormatDate } from "../../../utils/formatDate";
import { SortDecre, SortIncre } from "../../../assets/images/Icon";
import { Dropdown } from "primereact/dropdown";
import { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { getPatinetRxInvoice } from "../../../redux/store/CensusReducer";

const SearchInvoiceDialogBox = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: any;
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const searchOptions = [
    { label: "Rx", value: "Rx" },
    { label: "Invoice", value: "Invoice" },
  ];

  const { transactionHistory, historyLoading } = useSelector(
    (state: RootState) => state.arManagerReducer
  );

  const dispatch = useDispatch<AppDispatch>();

  const progressColumns = [
    { field: "mrn", header: "MRN", sortable: true },
    { field: "first_name", header: "First Name", sortable: true },
    { field: "last_name", header: "Last Name", sortable: true },
  ];

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      // Ascending
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      // Descending
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  const SearchRxInvoice = () => {
    dispatch(getPatinetRxInvoice({searchData: {
        "searchValues": "string",
        "searchType": 0,
        "userType": 0
      }}))
  }

  return (
    <>
      <Dialog
        style={{ width: "1000px", zIndex: 1111 }}
        draggable
        header="Search Rx/Invoice"
        resizable
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <div className="grid">
          <div className="col-2">
            <Dropdown
              className="w-full"
              value={selectedOption}
              optionLabel="label"
              onChange={(e) => setSelectedOption(e.value)}
              placeholder="Select Rx/Invoice"
              options={searchOptions}
            />
          </div>
          <div className="col-3">
            <InputText
              className="pl-1"
              placeholder={`${
                selectedOption === "Rx"
                  ? "Search Rx"
                  : selectedOption === "Invoice"
                  ? "Search Invoice"
                  : "Select a Rx or Invoice"
              }`}
            />
          </div>
          <div className="col-2">
            <Button label="Search" onClick={SearchRxInvoice}/>
          </div>
        </div>
        <DataTable
          value={transactionHistory}
          emptyMessage="No Data Found"
          scrollable
          removableSort
          scrollHeight="612px"
          sortIcon={sortIcon}
          loading={historyLoading}
        >
          {progressColumns.map((column, index) => (
            <Column
              key={index}
              field={column.field}
              header={column.header}
              sortable={column.sortable}
            />
          ))}
        </DataTable>
      </Dialog>
    </>
  );
};

export default SearchInvoiceDialogBox;
