import React, { useState } from "react";
import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { SortDecre, SortIncre } from "../../assets/images/Icon";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import SearchInvoiceDialogBox from "../Dialog/CensusDialogBox/SearchInvoiceDialogBox";

const powerappUrl = process.env.REACT_APP_AR_MANAGER_LINK;

const CensusDataTable = ({
  name,
  userEmail,
  visible,
  setVisible,
  data,
  filters,
  onStatusUpdateLoading,
  filterUpdate,
}: {
  name: string | undefined;
  userEmail: string | undefined;
  visible: boolean;
  setVisible: any;
  data: any;
  filters?: DataTableFilterMeta;
  onStatusUpdateLoading: (isLoading: boolean) => void;
  filterUpdate: string;
}) => {
  const [AssingToVisible, setAssingToVisible] = useState(false);
  const [AddNotesVisible, setAddNotesVisible] = useState(false);
  const [checked, setChecked] = useState<any[]>([]);
  const [rowClick, setRowClick] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const onRowSelectChange = (e: any) => {};

  // Handle Row Click for Redirection
  const handleRowClick = (event: any, rowData: any) => {
    const invoiceSpan = document.getElementById(
      `invoice-span-${rowData.invClaimNo}`
    );
    if (
      event.originalEvent.target.closest("td div img") ||
      event.originalEvent.target.closest("td span.mrn")
    ) {
      return;
    }
    invoiceSpan?.click();
  };

  const handleStatusUpdate = () => {
    onStatusUpdateLoading(false);
  };

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      // Ascending
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      // Descending
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  // Custom column bodies
  const LastNameBody = (rowData: any) => (
    <div className="flex align-items-center">
      <span className={`mr-2 patient-status ${rowData.patient_status === "Active" ? 'active-status' : rowData.patient_status === "Cancelled" ? 'cancelled-status' : rowData.patient_status === "Inactive" ? 'inactive-status' : rowData.patient_status === "On Hold" ? 'onhold-status' : rowData.patient_status === "Pending" ? "pending-status" : ""}`}></span>
      <span>{rowData.last_name}</span>
    </div>
  );

  // Columns
  const columns = [
    {
      field: "mrn",
      header: "MRN",
      sortable: true,
    },
    {
      field: "last_name",
      header: "Last Name",
      sortable: true,
      body: LastNameBody,
    },
    {
      field: "first_name",
      header: "First Name",
      sortable: true,
    },
    { field: "date_of_birth", header: "Date of Birth", sortable: true },
    { field: "referral_date", header: "Ref Date", sortable: true },
    { field: "prescriber_name", header: "Prescriber", sortable: true },
    { field: "therapy", header: "Category", sortable: true },
    { field: "insurance_name", header: "Insurance", sortable: true },
    { field: "site", header: "Site", sortable: true },
    { field: "phone_number", header: "Phone", sortable: true },
  ];

  return (
    <>
      <DataTable
        value={data}
        paginator
        rows={15}
        rowsPerPageOptions={[5, 10, 15, 50]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        emptyMessage="No Data Found"
        globalFilterFields={columns.map((column) => column.field)}
        filters={filters}
        scrollable
        sortIcon={sortIcon}
        sortField="last_name"
        sortOrder={-1}
        selection={checked}
        onSelectionChange={onRowSelectChange}
        onRowClick={(e) => handleRowClick(e, e.data)} // Handle row click for redirection
        selectionPageOnly
      >
        {columns.map((column, index) => (
          <Column
            key={index}
            field={column.field}
            header={column.header}
            sortable={column.sortable}
            body={column.body}
          />
        ))}
      </DataTable>
    </>
  );
};

export default CensusDataTable;
