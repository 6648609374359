import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { toggleEmailModal } from "../../redux/store/FaxSliceReducer";
import { markJunkFax } from "../../../redux/store/FaxSliceReducer";
import { AppDispatch, RootState } from "../../../redux/store";
import { useNavigate, useParams } from "react-router-dom";

interface DocumentHeadProps {
    isUserModal: boolean;
    setIsUserModal: (isOpen: boolean) => void;
    isEmailModal: boolean;
    setIsEmailModal: (isOpen: boolean) => void;
    userEmail: string;
    isDisabled: boolean;
}

type PermissionsType = {
    role_id: number;
    previlege_id: number;
    user_role_id: number;
    page_id: number;
    section_id: number;
    role: string;
    section: string;
    page: string;
    read: string;
    modify: string;
    write: string;
    delete: string;
    all: string;
    deny: string;
    prescriber_id: number;
    user_id: number;
};

const DocumentHead: React.FC<DocumentHeadProps> = ({
    isUserModal,
    setIsUserModal,
    isEmailModal,
    setIsEmailModal,
    userEmail,
    isDisabled
}) => {

    const { faxData } = useSelector((state: RootState) => state.FaxSliceReducer);

    const { assignRoleByUser } = useSelector((state: RootState) => state.routePermissionReducer);

    const params = useParams();

    const dispatch = useDispatch<AppDispatch>();

    const [faxDetail, setFaxDetail] = useState({});

    useEffect(() => {
        const filteredData = faxData.filter((val) => {
            return val.fax_detail_id == params.id;
        });
        if (filteredData.length > 0) {
            setFaxDetail(filteredData[0]);
        }
    }, [faxData, params]);

    const navigate = useNavigate();

    const handleToggleEmailModal = () => {
        // const data = {
        //     fax_detail_id: faxDetail.fax_detail_id,
        //     action_type: faxDetail.assignment_status,
        //     description: "string",
        //     is_junk: true,
        //     status: "string",
        //     loginEmail: "string"
        // }

        const data = {
            // @ts-ignore
            action_type: faxDetail?.assignment_status,
            description: "",
            // @ts-ignore
            fax_detail_id: faxDetail.fax_detail_id,
            is_junk: true,
            status: "",
            LoginEmail: userEmail
        }

        dispatch(markJunkFax(data)).then((res) => {
            if (res?.payload?.success) {
                navigate(`/fax`);
            }
        });
    };

    const checkAssignPermissions = () => {
        const findAssign = assignRoleByUser.find((data: PermissionsType) => data.section === "Assign Fax");
        if (findAssign) {
            // @ts-ignore
            if (findAssign?.modify === "Y") {
                return true;
            } else {
                return false;
            }
        } else {
            const findAll = assignRoleByUser.find((data: PermissionsType) => data.section === "All Section" && data.page === "Fax Module");
            if (findAll) {
                // @ts-ignore
                if (findAll?.modify === "Y") {
                    return true;
                } else {
                    return false;
                }
            }
        }
    };

    const checkForwardPermissions = () => {
        const findAssign = assignRoleByUser.find((data: PermissionsType) => data.section === "Forward Fax");
        if (findAssign) {
            // @ts-ignore
            if (findAssign?.modify === "Y") {
                return true;
            } else {
                return false;
            }
        } else {
            const findAll = assignRoleByUser.find((data: PermissionsType) => data.section === "All Section" && data.page === "Fax Module");
            if (findAll) {
                // @ts-ignore
                if (findAll?.modify === "Y") {
                    return true;
                } else {
                    return false;
                }
            }
        }
    };

    return (
        <div>
            <div className="custom-form d-flex justify-content-end mb-3">
                <div className="d-flex right-buttons">
                    {checkAssignPermissions() && (
                        <button
                            className={`${isDisabled ? "custom-btn-disabled" : "custom-btn"} ${isUserModal ? "common-btn" : ""}`}
                            onClick={() => setIsUserModal(!isUserModal)}
                            disabled={isDisabled}
                        >
                            <i className="pi pi-user" />
                        </button>
                    )}
                    {checkForwardPermissions() && (
                        <button
                            className={`${isDisabled ? "custom-btn-disabled" : "custom-btn"} ${isEmailModal ? "common-btn" : ""}`}
                            onClick={() => setIsEmailModal(!isEmailModal)}
                            disabled={isDisabled}
                        >
                            <i className="pi pi-send" />
                        </button>
                    )}
                    <button className={`${isDisabled ? "custom-btn-disabled" : "custom-btn"}`} onClick={handleToggleEmailModal} disabled={isDisabled}>
                        Mark Junk
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DocumentHead;