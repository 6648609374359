import React, { createContext, useContext, useState } from 'react';

// Define the shape of the selectedPatientmrn object
interface SelectedPatientType {
    mrn: string;
    name: string;
    desc: string; // Ensure this is a string type
    pat_first_name: string
}

// Define the context type
interface PatientContextType {
    selectedPatientmrn: SelectedPatientType;
    setSelectedPatientmrn: React.Dispatch<React.SetStateAction<SelectedPatientType>>;
}

// Create the context with an initial value of undefined
const PatientContext = createContext<PatientContextType | undefined>(undefined);

// Create a Provider component
export const PatientProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    // Initialize state with an object that matches the SelectedPatientType
    const [selectedPatientmrn, setSelectedPatientmrn] = useState<SelectedPatientType>({
        mrn: '',
        name: '',
        desc: '', // Ensure this is initialized as an empty string
        pat_first_name: ''
    });


    return (
        <PatientContext.Provider value={{ selectedPatientmrn, setSelectedPatientmrn }}>
            {children}
        </PatientContext.Provider>
    );
};

// Create a custom hook to use the PatientContext
export const usePatientContext = () => {
    const context = useContext(PatientContext);
    if (!context) {
        throw new Error('usePatientContext must be used within a PatientProvider');
    }
    return context;
};