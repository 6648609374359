import { InputNumber } from 'primereact/inputnumber'
import React from 'react'

const InputRange = ({ from, to, label, Fromid, Toid, className, onChange }: { from: number | null, to: number | null, label: string, Fromid: string, Toid: string, className?: string, onChange: any }): React.ReactElement => {
    return (
        <div className="grid m-0 align-items-center">
            <div className="col-4 custom-padding">
                <label className='font-medium text-sm'>{label}</label>
            </div>
            <div className="flex gap-2 col-8 custom-padding align-items-center">
                <InputNumber value={from} id={Fromid} onValueChange={onChange} />
                <div>-</div>
                <InputNumber value={to} id={Toid} onValueChange={onChange} />
            </div>
        </div>
    )
}

export default InputRange