import { Document, Page, Text, View } from "@react-pdf/renderer";
import {
  ReportFooter,
  ReportGrid,
  ReportGridColumn,
  ReportGridRow,
  ReportHeader,
  ReportPage,
} from "../ReportsComponents";
import { BlackAndWhiteFormData } from "../../types/IHealthAppTypes";
import { convertDateTime } from "../../utils/formatDate";

const InsuranceClaimInvoice = ({
  res,
  comments,
}: {
  res: BlackAndWhiteFormData[];
  comments: string;
}) => {
  const blackAndWhiteData = res[0];

  return (
    <Document>
      <ReportPage>
        {/* Header */}
        <ReportHeader data={blackAndWhiteData} />
        <Text
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: 13,
            marginBottom: "10px",
          }}
        >
          INVOICE
        </Text>

        <ReportGrid>
          <ReportGridRow>
            <ReportGridColumn width="10%"></ReportGridColumn>
            <ReportGridColumn label width="15%">
              To
            </ReportGridColumn>
            <ReportGridColumn width="20%">
              {blackAndWhiteData.pt_name}
            </ReportGridColumn>
            <ReportGridColumn label width="20%">
              Re
            </ReportGridColumn>
            <ReportGridColumn width="20%">
              {blackAndWhiteData.pt_name_re}
            </ReportGridColumn>
          </ReportGridRow>

          <ReportGridRow>
            <ReportGridColumn width="10%"></ReportGridColumn>
            <ReportGridColumn width="15%"></ReportGridColumn>
            <ReportGridColumn width="20%">
              {blackAndWhiteData.pt_adress1}
            </ReportGridColumn>
            <ReportGridColumn width="20%"></ReportGridColumn>
            <ReportGridColumn width="20%">
              {blackAndWhiteData.re_adressLine1}
            </ReportGridColumn>
          </ReportGridRow>

          <ReportGridRow>
            <ReportGridColumn width="10%"></ReportGridColumn>
            <ReportGridColumn width="15%"></ReportGridColumn>
            <ReportGridColumn width="20%">
              {blackAndWhiteData.pt_adress2}
            </ReportGridColumn>
            <ReportGridColumn width="20%"></ReportGridColumn>
            <ReportGridColumn width="20%">
              {blackAndWhiteData.re_adressLine2}
            </ReportGridColumn>
          </ReportGridRow>

          <ReportGridRow>
            <ReportGridColumn width="10%"></ReportGridColumn>
            <ReportGridColumn width="15%"></ReportGridColumn>
            <ReportGridColumn width="20%"></ReportGridColumn>
            <ReportGridColumn label width="20%">
              Insured
            </ReportGridColumn>
            <ReportGridColumn width="20%">
              {blackAndWhiteData.insurance_pt_name}
            </ReportGridColumn>
          </ReportGridRow>

          <ReportGridRow>
            <ReportGridColumn width="10%"></ReportGridColumn>
            <ReportGridColumn label width="15%">
              Policy#
            </ReportGridColumn>
            <ReportGridColumn width="20%">
              {blackAndWhiteData.policy_value}
            </ReportGridColumn>
            <ReportGridColumn width="20%"></ReportGridColumn>
            <ReportGridColumn width="20%"></ReportGridColumn>
          </ReportGridRow>

          <ReportGridRow>
            <ReportGridColumn width="10%"></ReportGridColumn>
            <ReportGridColumn label width="15%">
              Group#
            </ReportGridColumn>
            <ReportGridColumn width="20%"></ReportGridColumn>
            <ReportGridColumn width="20%"></ReportGridColumn>
            <ReportGridColumn width="20%"></ReportGridColumn>
          </ReportGridRow>

          <ReportGridRow>
            <ReportGridColumn width="10%"></ReportGridColumn>
            <ReportGridColumn label width="15%">
              Description
            </ReportGridColumn>
            <ReportGridColumn width="20%"></ReportGridColumn>
            <ReportGridColumn width="20%"></ReportGridColumn>
            <ReportGridColumn width="20%"></ReportGridColumn>
          </ReportGridRow>

          <ReportGridRow>
            <ReportGridColumn width="10%"></ReportGridColumn>
            <ReportGridColumn label width="15%">
              Service Dates
            </ReportGridColumn>
            <ReportGridColumn width="20%">
              From : {convertDateTime(blackAndWhiteData.serviceDates_from)}
            </ReportGridColumn>
            <ReportGridColumn width="20%">
              To : {convertDateTime(blackAndWhiteData.serviceDates_to)}
            </ReportGridColumn>
            <ReportGridColumn width="20%">
              Days : {blackAndWhiteData.serviceDays}
            </ReportGridColumn>
          </ReportGridRow>

          {/* Pricing */}
          <View style={{ marginTop: "20px" }}>
            <ReportGrid>
              <ReportGridRow bordered>
                <ReportGridColumn width="5%"></ReportGridColumn>
                <ReportGridColumn label width="10%">
                  QTY
                </ReportGridColumn>
                <ReportGridColumn label width="20%">
                  Delivered
                </ReportGridColumn>
                <ReportGridColumn label width="30%">
                  Description
                </ReportGridColumn>
                <ReportGridColumn label width="10%">
                  Unit Price
                </ReportGridColumn>
                <ReportGridColumn label width="15%">
                  Total Price
                </ReportGridColumn>
                <ReportGridColumn width="10%"></ReportGridColumn>
              </ReportGridRow>

              {res.map((tableData, index) => (
                <ReportGridRow key={index}>
                  <ReportGridColumn width="5%"></ReportGridColumn>
                  <ReportGridColumn width="10%">
                    {tableData.qty}
                  </ReportGridColumn>
                  <ReportGridColumn width="20%">
                    {convertDateTime(tableData.serviceDates_from)}
                  </ReportGridColumn>
                  <ReportGridColumn width="30%">
                    {tableData.desc}
                  </ReportGridColumn>
                  <ReportGridColumn width="10%">
                    ${tableData.unitPrice}
                  </ReportGridColumn>
                  <ReportGridColumn width="10%">
                    ${tableData.totalPrice_lineitem}
                  </ReportGridColumn>
                  <ReportGridColumn width="15%"></ReportGridColumn>
                </ReportGridRow>
              ))}
            </ReportGrid>
          </View>
          {/* Summary */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "50px",
            }}
          >
            <View style={{ width: "40%" }}>
              <ReportGridRow>
                <ReportGridColumn label width="60%">
                  Total Price
                </ReportGridColumn>
                <ReportGridColumn label width="40%">
                  ${blackAndWhiteData.total_price}
                </ReportGridColumn>
              </ReportGridRow>

              <ReportGridRow>
                <ReportGridColumn label width="60%">
                  Amount Paid
                </ReportGridColumn>
                <ReportGridColumn label width="40%">
                  ${blackAndWhiteData.total_paid}
                </ReportGridColumn>
              </ReportGridRow>

              <ReportGridRow>
                <ReportGridColumn label width="60%">
                  Amount Adjusted
                </ReportGridColumn>
                <ReportGridColumn label width="40%">
                  $
                </ReportGridColumn>
              </ReportGridRow>
              <View style={{ borderTop: "1px solid #000" }}>
                <ReportGridRow>
                  <ReportGridColumn label width="60%">
                    Balance Due
                  </ReportGridColumn>
                  <ReportGridColumn label width="40%">
                    ${blackAndWhiteData.balance_due}
                  </ReportGridColumn>
                </ReportGridRow>
              </View>
            </View>
          </View>
        </ReportGrid>
        <View style={{margin: "15pt 0pt"}}>
          <Text
            style={{
              fontWeight: "ultrabold",
            }}
          >
            Reason for bill: {" "}
            <Text>{comments}</Text>
          </Text>
        </View>
        {/* Footer */}
        <ReportFooter />
      </ReportPage>
    </Document>
  );
};

export default InsuranceClaimInvoice;
