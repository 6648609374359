import React, { useEffect, useState } from "react";
import "../../../assets/css/notesmanager.css";
import InputField from "../../formElement/InputField";
import MultiSelectFilter from "../../formElement/MultiSelect";
import InputDropdown from "../../formElement/InputDropdown";
import InputDate from "../../formElement/InputDate";
import { Button } from "primereact/button";
import { AddNotes } from "../../../assets/images/Icon";
import {
  getSubjectListData,
  getEmployeeList,
  getNotesManagerData,
  getNotesManagerPatientInvoicesID,
} from "../../../redux/store/NotesManagerReducer";
import { AsyncThunk, ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { FormatDate } from "../../../utils/formatDate";
import AddBillingNotesDialogBox from "../../Dialog/NotesManagerDialogBox/AddBillingNotesDialogBox";
import {
  getCprUser,
  getPatientBillingSubject,
} from "../../../redux/store/ARManagerReducer";

interface Type {
  name: string;
}

interface Option {
  label: string;
  value: string;
}

const NotesManagerFilter = ({
  setVisible,
  userEmail,
  setNoData,
}: {
  setVisible: any;
  userEmail: string | undefined;
  //  setFilterUpdate: any;
  setNoData: any;
}) => {
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  console.log(todayDate, "date");

  const formatToDateInput = (date: Date) => {
    return date.toISOString().split("T")[0]; // Formats as "YYYY-MM-DD"
  };

  const getMonday = (date: Date) => {
    const monday = new Date(date);
    monday.setDate(date.getDate() - ((date.getDay() + 6) % 7));
    return monday;
  };
  // Function to calculate start date of the quarter
  const getQuarterStartDate = (date: Date) => {
    const quarter = Math.floor((date.getMonth() + 3) / 3);
    return new Date(date.getFullYear(), (quarter - 1) * 3, 1);
  };

  // Function to calculate end date of the quarter
  const getQuarterEndDate = (date: Date) => {
    const quarter = Math.floor((date.getMonth() + 3) / 3);
    return new Date(date.getFullYear(), quarter * 3, 0);
  };

  const [AddBillingNotesVisible, setAddBillingNotesVisible] = useState(false);
  const [SearchType, setSearchType] = useState<Option[] | null>(null);
  const [SearchSubject, setSearchSubject] = useState<Type[] | null>(null);
  const [SearchFUAssignedTo, setSearchFUAssignedTo] = useState<Type[] | null>(
    null
  );
  const [SearchEnteredBy, setSearchEnteredBy] = useState<Type[] | null>(null);
  // const [SelectPostedDate, setSelectPostedDate] = useState<{ StartDate: Date; EndDate: Date } | undefined>(undefined);
  const [SelectPostedDate, setSelectPostedDate] = useState<
    | { label: string; value: { ID: number; StartDate: Date; EndDate: Date } }
    | undefined
  >(undefined);
  const [postedFromDate, setPostedFromDate] = useState<Date | undefined>(
    undefined
  );
  const [postedToDate, setPostedToDate] = useState<Date | undefined>(undefined);
  const [SelectFollowUpDate, setSelectFollowUpDate] = useState<
    | { label: string; value: { ID: number; StartDate: Date; EndDate: Date } }
    | undefined
  >(undefined);
  const [followUpFromDate, setFollowUpFromDate] = useState<Date | undefined>(
    undefined
  );
  const [followUpToDate, setFollowUpToDate] = useState<Date | undefined>(
    undefined
  );

  const { subjectList, employeeList, notesmanagerdata } = useSelector(
    (state: RootState) => state.notesManagerReducer
  );
  const dispatch = useDispatch<AppDispatch>();

  const resetFilterHandler = () => {
    setSearchEnteredBy(null);
    setSearchType(null);
    setSearchSubject(null);
  };

  const isApplyFilterEnabled =
    (SearchEnteredBy?.length || 0) > 0 ||
    (SearchFUAssignedTo?.length || 0) > 0 ||
    (SearchType?.length || 0) > 0 ||
    (SearchSubject?.length || 0) > 0 ||
    postedFromDate !== undefined ||
    postedToDate !== undefined ||
    followUpFromDate !== undefined ||
    followUpToDate !== undefined;
  const isResetEnabled = isApplyFilterEnabled;

  useEffect(() => {
    dispatch(getSubjectListData());
    dispatch(getEmployeeList());
    dispatch(getNotesManagerPatientInvoicesID());
    dispatch(getPatientBillingSubject());
    dispatch(getCprUser());
  }, [dispatch]);

  const handlePostedDropdownChange = (e: any) => {
    console.log(e, "e");
    console.log(e.value, "e");

    setSelectPostedDate(e.value);

    // You can customize the logic to calculate the "From" and "To" dates based on the selected value
    if (e.value) {
      const startDate = new Date(e.value.StartDate); // Assuming 'StartDate' field exists in the value
      const endDate = new Date(e.value.EndDate); // Assuming 'EndDate' field exists in the value

      // Update state with the new "From" and "To" dates
      setPostedFromDate(startDate);
      setPostedToDate(endDate);
    }
  };

  const handleFollowUpDropdownChange = (e: any) => {
    setSelectFollowUpDate(e.value);

    // You can customize the logic to calculate the "From" and "To" dates based on the selected value
    if (e.value) {
      const startDate = new Date(e.value.StartDate); // Assuming 'StartDate' field exists in the value
      const endDate = new Date(e.value.EndDate); // Assuming 'EndDate' field exists in the value

      // Update state with the new "From" and "To" dates
      setFollowUpFromDate(startDate);
      setFollowUpToDate(endDate);
    }
  };
  const optionsData = {
    types: [{ name: "BILLING" }, { name: "PROGRESS" }],
    postedDate: [
      {
        label: "Today",
        value: {
          ID: 1,
          StartDate: new Date(todayDate),
          EndDate: new Date(todayDate),
        },
      },
      {
        label: "Yesterday",
        value: {
          ID: 2,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate() - 1
          ),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate() - 1
          ),
        },
      },
      {
        label: "This Week",
        value: {
          ID: 3,
          StartDate: getMonday(new Date(todayDate)),
          EndDate: new Date(
            getMonday(new Date(todayDate)).getTime() + 6 * 24 * 60 * 60 * 1000
          ),
        },
      },
      {
        label: "This Week-to-date",
        value: {
          ID: 4,
          StartDate: getMonday(new Date(todayDate)),
          EndDate: todayDate,
        },
      },
      {
        label: "This Month",
        value: {
          ID: 5,
          StartDate: new Date(todayDate.getFullYear(), todayDate.getMonth(), 1),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 1,
            0
          ),
        },
      },
      {
        label: "This Month-to-date",
        value: {
          ID: 6,
          StartDate: new Date(todayDate.getFullYear(), todayDate.getMonth(), 1),
          EndDate: todayDate,
        },
      },
      {
        label: "This Quarter",
        value: {
          ID: 7,
          StartDate: getQuarterStartDate(new Date(todayDate)),
          EndDate: getQuarterEndDate(new Date(todayDate)),
        },
      },
      {
        label: "This Quarter-to-date",
        value: {
          ID: 8,
          StartDate: getQuarterStartDate(new Date(todayDate)),
          EndDate: todayDate,
        },
      },
      {
        label: "This Year",
        value: {
          ID: 9,
          StartDate: new Date(todayDate.getFullYear(), 0, 1),
          EndDate: new Date(todayDate.getFullYear(), 11, 31),
        },
      },
      {
        label: "This Year-to-date",
        value: {
          ID: 10,
          StartDate: new Date(todayDate.getFullYear(), 0, 1),
          EndDate: todayDate,
        },
      },
      {
        label: "Last Week",
        value: {
          ID: 11,
          StartDate: new Date(
            getMonday(new Date(todayDate)).getTime() - 7 * 24 * 60 * 60 * 1000
          ),
          EndDate: new Date(getMonday(new Date(todayDate)).getTime() - 1),
        },
      },
      {
        label: "Last Week-to-date",
        value: {
          ID: 12,
          StartDate: new Date(
            getMonday(new Date(todayDate)).getTime() - 7 * 24 * 60 * 60 * 1000
          ),
          EndDate: todayDate,
        },
      },
      {
        label: "Last Month",
        value: {
          ID: 13,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() - 1,
            1
          ),
          EndDate: new Date(todayDate.getFullYear(), todayDate.getMonth(), 0),
        },
      },
      {
        label: "Last Month-to-date",
        value: {
          ID: 14,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() - 1,
            1
          ),
          EndDate: todayDate,
        },
      },
      {
        label: "Last Quarter",
        value: {
          ID: 15,
          StartDate: getQuarterStartDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() - 3, 1)
          ),
          EndDate: getQuarterEndDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() - 3, 1)
          ),
        },
      },
      {
        label: "Last Quarter-to-date",
        value: {
          ID: 16,
          StartDate: getQuarterStartDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() - 3, 1)
          ),
          EndDate: todayDate,
        },
      },
      {
        label: "Last Year",
        value: {
          ID: 17,
          StartDate: new Date(todayDate.getFullYear() - 1, 0, 1),
          EndDate: new Date(todayDate.getFullYear() - 1, 11, 31),
        },
      },
      {
        label: "Last Year-to-date",
        value: {
          ID: 18,
          StartDate: new Date(todayDate.getFullYear() - 1, 0, 1),
          EndDate: todayDate,
        },
      },
      {
        label: "Tomorrow",
        value: {
          ID: 19,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate() + 1
          ),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate() + 1
          ),
        },
      },
      {
        label: "Next Week",
        value: {
          ID: 20,
          StartDate: new Date(
            getMonday(new Date(todayDate)).getTime() + 7 * 24 * 60 * 60 * 1000
          ),
          EndDate: new Date(
            getMonday(new Date(todayDate)).getTime() + 13 * 24 * 60 * 60 * 1000
          ),
        },
      },
      {
        label: "Next Week-to-date",
        value: {
          ID: 21,
          StartDate: new Date(
            getMonday(new Date(todayDate)).getTime() + 7 * 24 * 60 * 60 * 1000
          ),
          EndDate: new Date(todayDate.getTime() + 7 * 24 * 60 * 60 * 1000),
        },
      },
      {
        label: "Next Month",
        value: {
          ID: 22,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 1,
            1
          ),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 2,
            0
          ),
        },
      },
      {
        label: "Next Month-to-date",
        value: {
          ID: 23,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 1,
            1
          ),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 1,
            todayDate.getDate()
          ),
        },
      },
      {
        label: "Next Quarter",
        value: {
          ID: 24,
          StartDate: getQuarterStartDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() + 3, 1)
          ),
          EndDate: getQuarterEndDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() + 3, 1)
          ),
        },
      },
      {
        label: "Next Quarter-to-date",
        value: {
          ID: 25,
          StartDate: getQuarterStartDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() + 3, 1)
          ),
          EndDate: new Date(
            getQuarterStartDate(new Date(todayDate)).getTime() +
              (todayDate.getTime() -
                new Date(todayDate.getFullYear(), 0, 1).getTime())
          ),
        },
      },
      {
        label: "Next Year",
        value: {
          ID: 26,
          StartDate: new Date(todayDate.getFullYear() + 1, 0, 1),
          EndDate: new Date(todayDate.getFullYear() + 1, 11, 31),
        },
      },
      {
        label: "Next Year-to-date",
        value: {
          ID: 27,
          StartDate: new Date(todayDate.getFullYear() + 1, 0, 1),
          EndDate: new Date(
            new Date(todayDate.getFullYear() + 1, 0, 1).getTime() +
              (todayDate.getTime() -
                new Date(todayDate.getFullYear(), 0, 1).getTime())
          ),
        },
      },
    ].map((range) => ({
      label: range.label,
      value: {
        ID: range.value.ID,
        StartDate: range.value.StartDate,
        EndDate: range.value.EndDate,
      },
    })),
    followupDate: [
      {
        label: "Today",
        value: { ID: 1, StartDate: todayDate, EndDate: todayDate },
      },
      {
        label: "Yesterday",
        value: {
          ID: 2,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate() - 1
          ),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate() - 1
          ),
        },
      },
      {
        label: "This Week",
        value: {
          ID: 3,
          StartDate: getMonday(new Date(todayDate)),
          EndDate: new Date(
            getMonday(new Date(todayDate)).getTime() + 6 * 24 * 60 * 60 * 1000
          ),
        },
      },
      {
        label: "This Week-to-date",
        value: {
          ID: 4,
          StartDate: getMonday(new Date(todayDate)),
          EndDate: todayDate,
        },
      },
      {
        label: "This Month",
        value: {
          ID: 5,
          StartDate: new Date(todayDate.getFullYear(), todayDate.getMonth(), 1),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 1,
            0
          ),
        },
      },
      {
        label: "This Month-to-date",
        value: {
          ID: 6,
          StartDate: new Date(todayDate.getFullYear(), todayDate.getMonth(), 1),
          EndDate: todayDate,
        },
      },
      {
        label: "This Quarter",
        value: {
          ID: 7,
          StartDate: getQuarterStartDate(new Date(todayDate)),
          EndDate: getQuarterEndDate(new Date(todayDate)),
        },
      },
      {
        label: "This Quarter-to-date",
        value: {
          ID: 8,
          StartDate: getQuarterStartDate(new Date(todayDate)),
          EndDate: todayDate,
        },
      },
      {
        label: "This Year",
        value: {
          ID: 9,
          StartDate: new Date(todayDate.getFullYear(), 0, 1),
          EndDate: new Date(todayDate.getFullYear(), 11, 31),
        },
      },
      {
        label: "This Year-to-date",
        value: {
          ID: 10,
          StartDate: new Date(todayDate.getFullYear(), 0, 1),
          EndDate: todayDate,
        },
      },
      {
        label: "Last Week",
        value: {
          ID: 11,
          StartDate: new Date(
            getMonday(new Date(todayDate)).getTime() - 7 * 24 * 60 * 60 * 1000
          ),
          EndDate: new Date(getMonday(new Date(todayDate)).getTime() - 1),
        },
      },
      {
        label: "Last Week-to-date",
        value: {
          ID: 12,
          StartDate: new Date(
            getMonday(new Date(todayDate)).getTime() - 7 * 24 * 60 * 60 * 1000
          ),
          EndDate: todayDate,
        },
      },
      {
        label: "Last Month",
        value: {
          ID: 13,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() - 1,
            1
          ),
          EndDate: new Date(todayDate.getFullYear(), todayDate.getMonth(), 0),
        },
      },
      {
        label: "Last Month-to-date",
        value: {
          ID: 14,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() - 1,
            1
          ),
          EndDate: todayDate,
        },
      },
      {
        label: "Last Quarter",
        value: {
          ID: 15,
          StartDate: getQuarterStartDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() - 3, 1)
          ),
          EndDate: getQuarterEndDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() - 3, 1)
          ),
        },
      },
      {
        label: "Last Quarter-to-date",
        value: {
          ID: 16,
          StartDate: getQuarterStartDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() - 3, 1)
          ),
          EndDate: todayDate,
        },
      },
      {
        label: "Last Year",
        value: {
          ID: 17,
          StartDate: new Date(todayDate.getFullYear() - 1, 0, 1),
          EndDate: new Date(todayDate.getFullYear() - 1, 11, 31),
        },
      },
      {
        label: "Last Year-to-date",
        value: {
          ID: 18,
          StartDate: new Date(todayDate.getFullYear() - 1, 0, 1),
          EndDate: todayDate,
        },
      },
      {
        label: "Tomorrow",
        value: {
          ID: 19,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate() + 1
          ),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate() + 1
          ),
        },
      },
      {
        label: "Next Week",
        value: {
          ID: 20,
          StartDate: new Date(
            getMonday(new Date(todayDate)).getTime() + 7 * 24 * 60 * 60 * 1000
          ),
          EndDate: new Date(
            getMonday(new Date(todayDate)).getTime() + 13 * 24 * 60 * 60 * 1000
          ),
        },
      },
      {
        label: "Next Week-to-date",
        value: {
          ID: 21,
          StartDate: new Date(
            getMonday(new Date(todayDate)).getTime() + 7 * 24 * 60 * 60 * 1000
          ),
          EndDate: new Date(todayDate.getTime() + 7 * 24 * 60 * 60 * 1000),
        },
      },
      {
        label: "Next Month",
        value: {
          ID: 22,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 1,
            1
          ),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 2,
            0
          ),
        },
      },
      {
        label: "Next Month-to-date",
        value: {
          ID: 23,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 1,
            1
          ),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 1,
            todayDate.getDate()
          ),
        },
      },
      {
        label: "Next Quarter",
        value: {
          ID: 24,
          StartDate: getQuarterStartDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() + 3, 1)
          ),
          EndDate: getQuarterEndDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() + 3, 1)
          ),
        },
      },
      {
        label: "Next Quarter-to-date",
        value: {
          ID: 25,
          StartDate: getQuarterStartDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() + 3, 1)
          ),
          EndDate: new Date(
            getQuarterStartDate(new Date(todayDate)).getTime() +
              (todayDate.getTime() -
                new Date(todayDate.getFullYear(), 0, 1).getTime())
          ),
        },
      },
      {
        label: "Next Year",
        value: {
          ID: 26,
          StartDate: new Date(todayDate.getFullYear() + 1, 0, 1),
          EndDate: new Date(todayDate.getFullYear() + 1, 11, 31),
        },
      },
      {
        label: "Next Year-to-date",
        value: {
          ID: 27,
          StartDate: new Date(todayDate.getFullYear() + 1, 0, 1),
          EndDate: new Date(
            new Date(todayDate.getFullYear() + 1, 0, 1).getTime() +
              (todayDate.getTime() -
                new Date(todayDate.getFullYear(), 0, 1).getTime())
          ),
        },
      },
    ].map((range) => ({
      label: range.label,
      value: {
        ID: range.value.ID,
        StartDate: range.value.StartDate.toLocaleDateString(),
        EndDate: range.value.EndDate.toLocaleDateString(),
      },
    })),
  };

  const createOptions = (data: any[]) =>
    data.map((item: { name: any }) => ({ label: item.name, value: item.name }));
  const handleClearFilter = () => {
    setSearchType(null);
    setSearchSubject(null);
    setSearchFUAssignedTo(null);
    setSearchEnteredBy(null);
    setSelectPostedDate(undefined);
    setPostedFromDate(undefined);
    setPostedToDate(undefined);
    setSelectFollowUpDate(undefined);
    setFollowUpFromDate(undefined);
    setFollowUpToDate(undefined);
    setNoData(true);
  };

  const handleApplyFilter = () => {
    const requestBody = {
      subject: SearchSubject ? SearchSubject.join(",") : "",
      from_date: postedFromDate ? FormatDate(postedFromDate) : "",
      to_date: postedToDate ? FormatDate(postedToDate) : "",
      fp_from_date: followUpFromDate ? FormatDate(followUpFromDate) : "",
      fp_to_date: followUpToDate ? FormatDate(followUpToDate) : "",
      user_name: SearchFUAssignedTo ? SearchFUAssignedTo.join(",") : "",
      fp_user_name: SearchEnteredBy ? SearchEnteredBy.join(",") : "",
      invno: "", // If you have a search field for invoice number, you can map it here
      notes_type: SearchType
        ? SearchType.map((type) => type.label).join(",")
        : "",
    };
    console.log(requestBody, "requestBody");
    // setFilterUpdate(requestBody);
    dispatch(getNotesManagerData({ data: requestBody })).then(() => {
      if (notesmanagerdata.length === 0) {
        setNoData(true);
        // setFilterUpdate(requestBody.fp_from_date)
      }
    });
  };

  return (
    <>
      <div className="filters">
        <div className="flex justify-content-between align-items-center pr-3">
          <div className="grid align-items-center w-9 mx-0">
            <div className="col-3">
              <InputField placeholder="Search with Inv#" id="patientSearch" />
            </div>
            <div className="col-2">
              <MultiSelectFilter
                value={SearchType}
                id="searchTypes"
                onChange={(e: any) => setSearchType(e.value)}
                label="label"
                data={createOptions(optionsData.types)}
                placeholder="Search Type"
                filterValue=""
                virtualScrollerOptions={{ itemSize: 50 }}
              />
            </div>
            <div className="col-2">
              <MultiSelectFilter
                value={SearchSubject}
                id="searchSubject"
                onChange={(e: any) => setSearchSubject(e.value)}
                label="description"
                data={subjectList}
                placeholder="Search Subject"
                filterValue="description"
                virtualScrollerOptions={{ itemSize: 50 }}
              />
            </div>
            <div className="col-2">
              <MultiSelectFilter
                value={SearchFUAssignedTo}
                id="searchFUAssignedTo"
                onChange={(e: any) => setSearchFUAssignedTo(e.value)}
                label="emp_name"
                data={employeeList}
                placeholder="Search FUAssignedTo"
                filterValue="emp_name"
                virtualScrollerOptions={{ itemSize: 50 }}
              />
            </div>
            <div className="col-2">
              <MultiSelectFilter
                value={SearchEnteredBy}
                id="searchEnteredBy"
                onChange={(e: any) => setSearchEnteredBy(e.value)}
                label="emp_name"
                data={employeeList}
                placeholder="Search Entered By"
                filterValue="emp_name"
                virtualScrollerOptions={{ itemSize: 50 }}
              />
            </div>
          </div>
          <div className="icons flex align-items-center">
            <Button
              outlined
              className="p-px-3 p-py-2 p-mx-2"
              icon={
                <img
                  src={AddNotes}
                  alt="Add Notes"
                  width={22}
                  height={22}
                  className="mr-2"
                />
              }
              onClick={() => setAddBillingNotesVisible(true)}
              label="Add Notes"
            />
          </div>
        </div>

        <div className="grid align-items-center w-9 mx-0">
          <div className="col-4 p-0">
            <InputDropdown
              type="text"
              value={SelectPostedDate}
              id="selectPostedDate"
              labelclassName="col-4"
              dropdownclassName="col-8"
              handleChange={handlePostedDropdownChange}
              optionLabel="label"
              // optionValue="value.ID"
              options={optionsData.postedDate}
              placeholder="Select Posted Date"
              label="Posted Date:"
            />
          </div>
          <div className="col-2 p-0">
            <InputDate
              id="postedFromDate"
              label="From:"
              labelclassName="col-3"
              inputclassName="col-9"
              value={postedFromDate}
            />
          </div>

          <div className="col-2 p-0">
            <InputDate
              id="postedToDate"
              label="To:"
              labelclassName="col-3"
              inputclassName="col-9"
              value={postedToDate}
            />
          </div>
        </div>

        <div className="grid align-items-center w-9 mx-0">
          <div className="col-4 p-0">
            <InputDropdown
              type="text"
              value={SelectFollowUpDate}
              id="selectFollowUpDate"
              labelclassName="col-4"
              dropdownclassName="col-8"
              handleChange={handleFollowUpDropdownChange}
              optionLabel="label"
              options={optionsData.followupDate}
              placeholder="Select Follow-Up Date"
              label="Follow-Up Date:"
            />
          </div>

          <div className="col-2 p-0">
            <InputDate
              id="followUpFromDate"
              label="From:"
              labelclassName="col-3"
              inputclassName="col-9"
              value={followUpFromDate} // You can set the same value if needed or use a separate state
            />
          </div>

          <div className="col-2 p-0">
            <InputDate
              id="followUpToDate"
              label="To:"
              labelclassName="col-3"
              inputclassName="col-9"
              value={followUpToDate} // Similar to above, you can adjust this according to the requirements
            />
          </div>

          <div className="col-2 gap-2 p-0 flex align-items-center">
            <Button
              label="Reset"
              outlined
              onClick={() => {
                handleClearFilter();
                resetFilterHandler();
              }}
              disabled={!isResetEnabled}
            />
            <Button
              label="Apply Filter"
              onClick={handleApplyFilter}
              disabled={!isApplyFilterEnabled}
            />
          </div>
        </div>
      </div>
      <AddBillingNotesDialogBox
        userEmail={userEmail}
        //selectedCpkInvoices={selectedCpkInvoices}
        visible={AddBillingNotesVisible}
        setVisible={setAddBillingNotesVisible} //selectedCpkInvoices={[]} filterUpdate={""}     // onStatusUpdateLoading={onStatusUpdateLoading}
        // onStatusUpdated={handleStatusUpdate}
        //  filterUpdate={filterUpdate}
      />
    </>
  );
};

export default NotesManagerFilter;
