import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useState } from "react";
import { Edit, SortDecre, SortIncre } from "../../assets/images/Icon";
import AddNewInsuranceDialogBox from "../Dialog/InsuranceDialogBox/AddNewInsuranceDialogBox";
import { InsuranceDataType } from "../../types/IHealthAppTypes";
import { useDispatch } from "react-redux";
import {
  getContractState,
  getOldQuestionAnswer,
} from "../../redux/store/InsuranceReducer";
import { AppDispatch } from "../../redux/store";
import { Link } from "react-router-dom";

const IsuranceDataTable = ({
  userEmail,
  data,
  filters,
  setAction,
  action,
}: {
  userEmail: string | undefined;
  data: InsuranceDataType[];
  filters: DataTableFilterMeta;
  setAction: any;
  action: string;
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<InsuranceDataType>();
  const dispatch = useDispatch<AppDispatch>();

  const OperationBodyTemplate = (rowData: InsuranceDataType) => {
    const handleEditClick = () => {
      if (rowData.company_id) {
        setVisible(true);
        setSelectedData(rowData);
        dispatch(getOldQuestionAnswer({ company_id: rowData.company_id }));
        dispatch(getContractState({ company_id: rowData.company_id }));
        setAction("edit");
      } else {
        console.error("Company ID is missing");
      }
    };

    return (
      <>
        <img
          src={Edit}
          alt="Edit"
          onClick={handleEditClick}
          style={{ cursor: "pointer" }}
        />
      </>
    );
  };

  const payorResourcesTemplate = (rowData: InsuranceDataType) => {
    const contractLinks = rowData?.contract_link?.split(",") || [];

    return (
      <div>
        {contractLinks.map((link, index) => (
          <div className="mb-1" key={index}>
            <Link to={link.trim()}>{link.trim()}</Link>
          </div>
        ))}
      </div>
    );
  };

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  const columns = [
    {
      field: "organization",
      header: "Payor Name",
      sortable: true,
    },
    {
      field: "external_Payor_name",
      header: "Payor Name – external",
      sortable: true,
    },
    {
      field: "contract_status",
      header: "Payor Status",
      sortable: true,
    },
    {
      field: "payor_type",
      header: "Payor Type",
      sortable: true,
    },
    {
      field: "identifier",
      header: "Payor Identifier",
      sortable: true,
    },
    {
      field: "invoice_type",
      header: "Payor Invoice Type",
      sortable: true,
    },
    {
      field: "bin",
      header: "Bin#",
      sortable: true,
    },
    {
      field: "pcn",
      header: "PCN",
      sortable: true,
    },
    {
      field: "pricing",
      header: "Payor Pricing",
      sortable: true,
    },
    // {
    //   field: "site_name",
    //   header: "Site",
    //   sortable: true,
    // },
    {
      field: "states_contract",
      className: "col-max-content max-w-18rem",
      header: "State",
      sortable: true,
    },
    {
      field: "contract_link",
      header: "Payor Resources",
      sortable: true,
      body: payorResourcesTemplate,
      className: "col-max-content max-w-18rem",
    },
    {
      field: "optional_org_name",
      header: "Operation",
      sortable: false,
      body: OperationBodyTemplate,
    },
  ];

  return (
    <>
      <DataTable
        value={data}
        paginator
        rows={15}
        scrollHeight="710px"
        sortIcon={sortIcon}
        rowsPerPageOptions={[5, 10, 15, 25, 75, 100]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        emptyMessage="No Data Found"
        removableSort
        globalFilterFields={columns.map((column) => column.field)}
        filters={filters}
        scrollable
      >
        {columns.map((column, index) => (
          <Column
            className={column.className}
            key={index}
            field={column.field}
            header={column.header}
            body={column.body}
            sortable={column.sortable}
          ></Column>
        ))}
      </DataTable>
      <AddNewInsuranceDialogBox
        userEmail={userEmail}
        data={selectedData}
        visible={visible}
        setVisible={setVisible}
        action={action}
        setAction={setAction}
      />
    </>
  );
};

export default IsuranceDataTable;
