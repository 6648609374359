import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { displayToast } from "../../Toast/ToastMessage";
import { updateNotes } from "../../../redux/store/ARManagerReducer";
import { AppDispatch } from "../../../redux/store";
import { SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { PrintPdfDataType } from "../../../types/IHealthAppTypes";

const AddNotesToPatientDialog = ({
    data,
    visible,
    setVisible,
    handlePrintPdf,
  }: {
    data: PrintPdfDataType | null;
    visible: boolean;
    setVisible: React.Dispatch<SetStateAction<boolean>>;
    handlePrintPdf: (data: PrintPdfDataType | null) => void;
  }) => {
    const [comments, setComments] = useState("");
    const dispatch = useDispatch<AppDispatch>();
  
    const handleClick = async () => {
      try {
        if (!data) {
          throw new Error("Error:");
        }
        setVisible(false);
        handlePrintPdf({ ...data, claim_notes: comments });
        setComments("");
        dispatch(
          updateNotes({
            updateData: {
              claim_id: data.cpk_Claims,
              note: comments,
              loginEmail: data.userEmail,
            },
            callback: (res: any) => {
              if (res.data.success) {
                displayToast({
                  msg: "The notes for the selected claims has been updated successfully.",
                  type: "success",
                });
              } else {
                displayToast({
                  msg: res?.data?.message || "Something went wrong",
                  type: "error",
                });
              }
            },
          })
        );
      } catch (error: any) {
        console.error(error.message, "Error Downloading Black and White Form");
      }
    };
  
    const Footer = (
      <div className="buttons flex gap-2 justify-content-end">
        <Button
          label="Cancel"
          outlined
          onClick={() => {
            setVisible(false);
          }}
        />
        <Button label="Print" onClick={handleClick} />
      </div>
    );
    return (
      <Dialog
        style={{ width: "700px" }}
        draggable
        header="Notes To Patient: "
        footer={Footer}
        resizable
        modal={false}
        visible={visible}
        onHide={() => {
          setVisible(false);
        }}
      >
        <div className="m-0">
          <label className="text-lg font-bold mb-2">Reason for bill: </label>
          <div className="">
            <InputTextarea
              autoResize
              rows={20}
              value={comments}
              id="comments"
              className="p-2"
              onChange={(e) => setComments(e.target.value)}
              placeholder="[textbox where users can enter the note]"
            />
          </div>
        </div>
      </Dialog>
    );
  };

export default AddNotesToPatientDialog;
  