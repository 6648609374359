import { Dialog } from "primereact/dialog";
import "../../assets/css/inventory.css";
import icon from "../../assets/images/add-btn.svg";
import InputField from "../formElement/InputField";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import AddInventoryPlus from "./AddInventoryPlus";
import { useEffect, useRef, useState } from "react";
import AddChangeTransferDialogbox from "./AddChangeTransferDialogbox";
import { FilterMatchMode } from "primereact/api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store"; 
import { inventorydataArray } from "../../Data/InventoryList";

interface AddInventoryDialogBoxProps {
  header: string;
  visible: boolean;
  onHide: () => void;
}

interface InventoryData {
  purchaseDate: string;
  cost: number;
  lotNumber: string;
  expDate: string;
  quantity: number;
  site: string;
}

const AddInventoryDialogBox: React.FC<AddInventoryDialogBoxProps> = ({
  header,
  visible,
  onHide,
}) => {
  const { inventoryDrugArray } = useSelector(
    (state: RootState) => state.inventoryReducer
  );

  // State to control modal visibility
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [headers, setHeader] = useState<string>("");
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [headers2, setHeader2] = useState<string>("");
  const [editData, setEditData] = useState<undefined>(undefined);

  // State to hold the selected rows
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const headerCheckboxRef = useRef<HTMLInputElement>(null);

  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilterValueReorder, setGlobalFilterValueReorder] = useState("");

  //select all cheack box
  useEffect(() => {
    if (headerCheckboxRef.current) {
      const allSelected = selectedRows.length === inventorydataArray.length;
      const isIndeterminate = selectedRows.length > 0 && !allSelected;
      headerCheckboxRef.current.indeterminate = isIndeterminate;
    }
  }, [selectedRows, inventorydataArray.length]);

  //global filter change
  const onGlobalFilterChangeReorder = (e: any) => {
    const value = e.target.value;
    setFilters((prev) => ({ ...prev, global: { ...prev.global, value } }));

    setGlobalFilterValueReorder(value);
  };

  //add edit modal handler
  const openDialog = (headerText: string): void => {
    setHeader(headerText);
    setIsModalVisible(!isModalVisible);
  };

  const onRowSelectdata = (event: any) => {
    setIsModalVisible(!isModalVisible);
    setHeader("edit drug");
    setEditData(event.data);
  };

  //change-Transfer Quantity handler
  const openDialog2 = (headerText: string, e?: any): void => {
    setHeader2(headerText);

    setIsModalVisible2(true);
  };

  
 

  // Template for the checkbox column
  const checkboxTemplate = (rowData: InventoryData) => {
    return (
      <Checkbox
        onChange={(e) => onRowSelect(e, rowData)}
        checked={selectedRows.includes(rowData)}
      />
    );
  };

  // Handle row selection
  const onRowSelect = (e: any, rowData: InventoryData) => {
    if (e.checked) {
      setSelectedRows([...selectedRows, rowData])
      
    } else {
      setSelectedRows(selectedRows.filter((row) => row !== rowData));
     
    }
  };

  // Template for the header checkbox (Select/Deselect All)
  const headerCheckboxTemplate = () => {
    const allSelected = selectedRows.length === inventoryDrugArray.length;

    return (
      <Checkbox
        onChange={onHeaderCheckboxToggle}
        checked={allSelected}
        inputRef={headerCheckboxRef}
      />
    );
  };

  //  (Select/Deselect All)
  const onHeaderCheckboxToggle = (e: CheckboxChangeEvent) => {
    if (e.checked) {
      setSelectedRows(inventoryDrugArray); // Select all rows
    } else {
      setSelectedRows([]);
    }
  };


  const  handleSave=()=>{
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
 });
 setSelectedRows([])
 setGlobalFilterValueReorder("")
    onHide()

  }

  return (
    <>
      <Dialog
        className="Inventry-dialog"
        header={header}
        visible={visible}
        onHide={()=>{
          onHide()
          handleSave() 
        }}
        style={{ width: "60vw", minHeight: "60vh" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        draggable={false}
      >
        <div className="paddings" style={{ padding: "10px 50px" }}>
          <div className="Inventry-chip">
            <div>
              <strong>AWP/Pack:</strong> $2.39
            </div>
            <div>
              <strong>AWP/Each:</strong> $0.10
            </div>
            <div>
              <strong>Cost/Pack:</strong> $1.74
            </div>
            <div>
              <strong>Cost/Each:</strong> $0.07
            </div>
          </div>

          <div className="inventory-input-container filter ">
            <div className="inputbox input-container">
              <InputField
                className=" search-text"
                placeholder="Search"
                id="search"
                globalFilterValue={globalFilterValueReorder}
                onGlobalFilterChange={onGlobalFilterChangeReorder}
              />
            </div>
            <div className="Add-inventory-btn">
              <Button
                className="Add-inventory-btn"
                icon={
                  <img
                    src={icon}
                    alt="My Icon"
                    style={{ width: "20px", height: "20px" }}
                  />
                }
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => {
                  openDialog("Add Inventory");
                  //setAction("add");
                }}
              />
            </div>
            <AddInventoryPlus
              header={headers}
              visibleInventory={isModalVisible}
              onHide={() => setIsModalVisible(false)}
              editData={editData}
            />
          </div>

          <div className="main-datatable">
            <DataTable
              className=" custom-table Add-inventory-datatable"
              value={inventoryDrugArray}
              globalFilter={globalFilter}
              filters={filters}
              globalFilterFields={["lotNumber"]}
              onRowClick={onRowSelectdata}
              
            >
              <Column
                header={headerCheckboxTemplate}
                body={checkboxTemplate}
              ></Column>
              <Column field="purchaseDate" header="Purchase date"></Column>
              <Column field="cost" header="Cost"></Column>
              <Column field="lotNumber" header="Lot#"></Column>
              <Column field="expDate" header="Exp.Date"></Column>
              <Column field="quantity" header="Quantity"></Column>
              <Column field="site" header="Site"></Column>
            </DataTable>
          </div>
        </div>

        <div className="footer-button">
          <Button
            className="change-Quntity-button"
            label="Change Quantity"
            disabled={selectedRows.length===0}
            onClick={() => {
              openDialog2("Change Quantity");
            }}
          />
          <Button
            className="inventory-foter-button"
            label="Transfer"
           disabled={selectedRows.length===0}
            onClick={() =>
              openDialog2("Transfer Inventory To A Different Site")
            }
          />
          <Button
            className="inventory-foter-button"
            label="Save"
            onClick={() =>
             handleSave()
            }
          />

          <AddChangeTransferDialogbox
            header2={headers2}
            visible2={isModalVisible2}
            onHide={() => setIsModalVisible2(false)}
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddInventoryDialogBox;