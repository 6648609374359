import { toast } from 'react-toastify';
 
export const displayToast = ({ msg, type }: { msg: string, type: string }) => {
  // @ts-ignore
    toast[type](msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        className: "custom-toast"
    });
};