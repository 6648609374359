import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewInsuranceCompanyRequest,
  updateAnswersInBillingInsurance,
  updateGeneralBillingInsuranceData,
} from "../../../redux/store/InsuranceReducer";
import {
  InsuranceDataType,
  QuestionDataType,
} from "../../../types/IHealthAppTypes";
import PatientQuestionsTabView from "./TabView/PatientQuestionsTabView";
import GeneralBillingTabView from "./TabView/GeneralBillingTabView";
import PreferedProductTabView from "./TabView/PreferedProductTabView";
import { displayToast } from "../../Toast/ToastMessage";

interface ContractLinkType {
  rank: number;
  contract_link: string;
  state_name: string[];
  state_id: string[];
}

const AddNewInsuranceDialogBox = ({
  userEmail,
  data,
  visible,
  setVisible,
  action,
  setAction,
}: {
  userEmail: string | undefined;
  data?: InsuranceDataType;
  visible: boolean;
  setVisible: any;
  action: string;
  setAction: any;
}): React.ReactElement => {
  const { questionAnswerValueList, oldQuestionAnswer, contractState } =
    useSelector((state: RootState) => state.insuranceReducer);
  const dispatch = useDispatch<AppDispatch>();

  const [generalBillData, setGeneralBillData] = useState<InsuranceDataType>({
    organization: "",
    contract_status: "",
    contract_status_id: null,
    commercial_name: "",
    site_name: [],
    payor_type: "",
    identifier: "",
    billing_organization: "",
    sbcontact: "",
    provider_number: "",
    qualifier: "",
    Payor_Pricing: "",
    populate_box_24J: 0,
    use_grt_npi_for_box_24J: 0,
    webmd_medical: "",
    naic_payor_id: "",
    hpid: "",
    invoice_type: "",
    ocna: "",
  });
  const [questionData, setQuestionData] = useState<QuestionDataType>({});
  const [contractLink, setContractLink] = useState<ContractLinkType[]>([]);
  const ranges = ["1-10", "11-20", "21-30", "31-40", "41-50", "51-60", "61-70"];
  const [number, setNumber] = useState<number>(0);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<any>({
    organization: "",
    contract_status: "",
    payor_type: "",
  });

  const requiredFields = [
    { field: "organization", message: "Organization is required!" },
    { field: "contract_status", message: "Payor status is required!" },
    { field: "payor_type", message: "Payor Type is required!" },
  ];

  const initialQuestionData: QuestionDataType = {
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",
    question7: "",
    question8: "",
    question9: "",
    question10: "",
    question11: "",
    question12: "",
    question13: "",
    question14: "",
    question15: "",
    question16: "",
    question17: "",
    question18: "",
    question19: "",
    question20: "",
    question21: "",
    question22: "",
    question23: "",
    question24: "",
    question25: "",
    question26: "",
    question27: "",
    question28: "",
    question29: "",
    question30: "false",
    question31: "false",
    question32: "false",
    question33: "false",
    question34: "false",
    question35: "false",
    question36: "false",
    question37: "false",
    question38: "false",
    question39: "false",
    question40: "",
    question41: "false",
    question42: "false",
    question43: "false",
    question44: "false",
    question45: "",
    question46: "",
    question47: "false",
    question48: "false",
    question49: "",
    question50: "",
    question51: "false",
    question52: "false",
    question53: "false",
    question54: "false",
    question55: "false",
    question56: "",
    question57: "false",
    question58: "",
    question59: "",
    question60: "",
    question61: "false",
    question62: "false",
  };

  const initializeData = () => {
    setQuestionData(initialQuestionData);
    setGeneralBillData({
      organization: "",
      contract_status: "",
      commercial_name: "",
      site_name: [],
      contract_status_id: null,
      payor_type: "",
      identifier: "",
      billing_organization: "",
      sbcontact: "",
      provider_number: "",
      Payor_Pricing: "",
      qualifier: "",
      populate_box_24J: 0,
      use_grt_npi_for_box_24J: 0,
      webmd_medical: "",
      naic_payor_id: "",
      hpid: "",
      invoice_type: "",
      ocna: "",
    });
    setContractLink([]);
  };

  useEffect(() => {
    if (action === "add") {
      initializeData();
      setContractLink([]);
    }
    if (action === "edit") {
      if (data) {
        setGeneralBillData((prevData) => ({
          ...prevData,
          organization: data.organization || "",
          contract_status: data.contract_status || "",
          commercial_name: data.commercial_name || "",
          site_name: JSON.parse(data?.multi_select_site_name) || [],
          contract_status_id: Number(data.contract_status_id) || null,
          payor_type: data.payor_type || "",
          Payor_Pricing: data.pricing || "",
          identifier: data.identifier || "",
          billing_organization: data.billing_organization || "",
          sbcontact: data.sbcontact || "",
          provider_number: data.provide_number || "",
          qualifier: data.qualifier || "",
          populate_box_24J: data.populate_box_24J || 0,
          use_grt_npi_for_box_24J:
            data.use_grt_npi_for_box_24J === "True" ? 1 : 0,
          webmd_medical: data.webmd_medical || "",
          naic_payor_id: data.naic_payor_id || "",
          hpid: data.hpid || "",
          invoice_type: data.invoice_type?.trim() || "",
          ocna: data.ocna || "",
        }));
      }
      const link = contractState.map((link: any) => ({
        ...link,
        state_id: link.state_id?.split(",").map(Number),
      }));
      setContractLink(link || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, action, contractState]);

  useEffect(() => {
    if (action === "edit") {
      const answerMap = oldQuestionAnswer.reduce(
        (
          acc: any,
          { question_id, answer }: { question_id: any; answer: any }
        ) => {
          acc[question_id] = answer;
          return acc;
        },
        {} as Record<number, string | number>
      );
      const updatedQuestionData: QuestionDataType = { ...initialQuestionData };
      questionAnswerValueList.forEach(({ question_label, question_id }) => {
        const answer = answerMap[question_id];
        updatedQuestionData[question_label as keyof QuestionDataType] = answer;
      });
      setQuestionData(updatedQuestionData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldQuestionAnswer, questionAnswerValueList, action]);

  const validateFields = (
    data: any,
    requiredFields: { field: string; message: string }[]
  ) => {
    let errors: any = {};

    requiredFields.forEach(({ field, message }) => {
      if (!data[field] || data[field] === "") {
        errors[field] = message;
      } else {
        errors[field] = "";
      }
    });

    return errors;
  };

  const handleContinue = () => {
    const validationErrors = validateFields(generalBillData, requiredFields);

    const filteredErrors = Object.fromEntries(
      Object.entries(validationErrors).filter(([key, value]) => value !== "")
    );

    if (Object.keys(filteredErrors).length > 0) {
      setErrorMessage(filteredErrors);
      return;
    }

    if (number === 0) {
      setErrorMessage({});
      setNumber((prevNumber) => prevNumber + 1);
    } else if (number === 1 && activeIndex === ranges.length - 1) {
      return;
    } else {
      setActiveIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleSubmit = () => {
    const transformQuestionFields = (data: QuestionDataType) => ({
      ...data,
      question29: data.question29 || "0",
      question40: data.question40 || "0",
      question45: data.question45 || "0",
    });

    const link = contractLink.map((link) => ({
      ...link,
      state_id: link.state_id?.join(","),
    }));

    const payload = {
      billingDTO: {
        no: "0",
        ...generalBillData,
        ...transformQuestionFields(questionData),
        site_name: generalBillData.site_name.map((site: any) => site.site_name),
      },
      loginemail: userEmail,
      contractDTO: link,
    };
    if (action === "add") {
      dispatch(
        addNewInsuranceCompanyRequest({
          data: payload,
          callback: (res: any) => {
            if (res.data.success) {
              displayToast({
                msg: "New Insurance Added",
                type: "success",
              });
            } else {
              displayToast({
                msg: res?.data?.message || "Something went wrong",
                type: "error",
              });
            }
          },
        })
      );
      initializeData();
    } else if (action === "edit") {
      if (number === 0) {
        const validationErrors = validateFields(
          generalBillData,
          requiredFields
        );

        const filteredErrors = Object.fromEntries(
          Object.entries(validationErrors).filter(
            ([key, value]) => value !== ""
          )
        );

        if (Object.keys(filteredErrors).length > 0) {
          setErrorMessage(filteredErrors);
          return;
        }
        const updateGeneralBillData = {
          billingjson: {
            no: data?.company_no,
            ...generalBillData,
            site_name: generalBillData.site_name.map((site: any) => ({
              company_site_id: site.company_site_id || 0,
              site_id: site.site_id,
              site_name: site.site_name,
            })),
          },
          loginemail: userEmail,
          contractDTO: link,
        };
        dispatch(
          updateGeneralBillingInsuranceData({
            data: updateGeneralBillData,
            callback: (res: any) => {
              if (res.data.success) {
                displayToast({
                  msg: "Updated General bill/Info",
                  type: "success",
                });
              } else {
                displayToast({
                  msg: res?.data?.message || "Something went wrong",
                  type: "error",
                });
              }
            },
          })
        );
      }
      if (number === 1) {
        const updateQuestionData = {
          billingInsurance: {
            no: data?.company_no,
            ...transformQuestionFields(questionData),
          },
          loginemail: userEmail,
          contractDTO: link,
        };
        dispatch(
          updateAnswersInBillingInsurance({
            data: updateQuestionData,
            callback: (res: any) => {
              if (res.data.success) {
                displayToast({
                  msg: "Updated setup option",
                  type: "success",
                });
              } else {
                displayToast({
                  msg: res?.data?.message || "Something went wrong",
                  type: "error",
                });
              }
            },
          })
        );
      }
    }
    setVisible(false);
  };

  const Header = (
    <div className="buttons flex gap-2">
      <Button
        outlined={number !== 0}
        label="General/Billing Info"
        onClick={() => setNumber(0)}
      />
      <Button
        outlined={number !== 1}
        label="Setup Options"
        onClick={() => setNumber(1)}
      />
      <Button
        outlined={number !== 2}
        label="Preferred Product"
        onClick={() => setNumber(2)}
      />
    </div>
  );

  const Footer = (
    <div className="buttons flex gap-2 justify-content-end">
      <Button
        label="Cancel"
        outlined
        onClick={() => {
          setVisible(false);
          setNumber(0);
          setActiveIndex(0);
          initializeData();
          setErrorMessage({});
        }}
      />
      {(activeIndex < 6 || number === 0) && action === "add" ? (
        <Button label="Continue" onClick={handleContinue} />
      ) : (
        <Button label="Save" onClick={handleSubmit} />
      )}
    </div>
  );

  return (
    <Dialog
      draggable={false}
      header={Header}
      footer={Footer}
      visible={visible}
      style={{ width: "60vw", height: "80vh" }}
      className="insurance-dialog"
      onHide={() => {
        if (!visible) return;
        setVisible(false);
        setNumber(0);
        setActiveIndex(0);
        initializeData();
        setAction("add");
        setErrorMessage({});
      }}
    >
      {number === 0 && (
        <GeneralBillingTabView
          generalBillData={generalBillData}
          setGeneralBillData={setGeneralBillData}
          contractLink={contractLink}
          setContractLink={setContractLink}
          errorMessage={errorMessage}
        />
      )}
      {number === 1 && (
        <>
          <div className="flex mb-2 gap-4">
            {ranges.map((label, index) => (
              <Button
                key={index}
                onClick={() => setActiveIndex(index)}
                className="py-2 px-4"
                outlined={activeIndex !== index}
                label={label}
              />
            ))}
          </div>
          <PatientQuestionsTabView
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            questionData={questionData}
            setQuestionData={setQuestionData}
            questionAnswerValueList={questionAnswerValueList}
          />
        </>
      )}
      {number === 2 && <PreferedProductTabView />}
    </Dialog>
  );
};

export default AddNewInsuranceDialogBox;
