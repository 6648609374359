import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { stringify } from "querystring";
import { json } from "stream/consumers";

const baseUrl = process.env.REACT_APP_BASEURL;



// GET Method ForShipment data
export const getShipmentData = createAsyncThunk(
    "shipment/getShipmentData",
    async () => {
      try {
        const endpoint = `${baseUrl}/api/FedexDeliveryTicket/GetFedexDeliveryTicketList`;
        const response = await axios.get(endpoint);
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.data;
        return data;
      } catch (error) {
        console.error("Error fetching user details", error);
      }
    }
  );

//Get History Data

export const GetHistoryofShipment = createAsyncThunk(
  "shipment/GetHistoryofShipment",
  async (GetFedxNum: any, apiThunk) => {
    try {
      // const test = stringify({GetFedxNum})
      // console.log(test);
      
      const endpoint = `${baseUrl}/api/FedexDeliveryTicket/GetFedExDeliveryTicketInformation?trackingnumber=${GetFedxNum}`;
      const response = await axios.post(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const responseData = await response.data;
      return responseData;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);



interface Shipmentstate {
    loading: boolean;
    item: any;
    error: string | null;
    userEmail:any;
    HistoryDataFromAPIPASS:any;
    historyLoading: boolean;

  }

// Initial state for the fax slice
const initialState: Shipmentstate = {
    error: null,
    item: [],
    loading: false,
    historyLoading: false,
    userEmail: '',
    HistoryDataFromAPIPASS:[]
}


// Insurance Slice
const shipmentSlice = createSlice({
    name: 'insurance',
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.userEmail = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getShipmentData.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getShipmentData.fulfilled, (state, action) => {
                state.item = action.payload?.data
                // console.log(action.payload.data, "getShipmentData")
                state.loading = false
            })
            .addCase(getShipmentData.rejected, (state, action) => {
                state.error = action.error.message || ""
                state.loading = false
            })
            .addCase(GetHistoryofShipment.pending, (state, action) => {
                
              state.historyLoading = true
          })
          .addCase(GetHistoryofShipment.fulfilled, (state, action) => {
              state.HistoryDataFromAPIPASS = action.payload?.data
              // console.log(action.payload.data, "getShipmentData")
              state.historyLoading = false
          })
          .addCase(GetHistoryofShipment.rejected, (state, action) => {
              state.error = action.error.message || ""
              state.historyLoading = false
          })
        }
    });

    export const { setEmail } = shipmentSlice.actions
export default shipmentSlice.reducer