import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/css/index.css';
import './assets/css/global.css';
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import '/node_modules/primeflex/primeflex.css';
import 'bootstrap/dist/css/bootstrap.css';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './authconfig';
import { registerLicense } from '@syncfusion/ej2-base';

const LICENSE_KEY = 'Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH9eeHRXRGBYVEd2X0E=';
registerLicense(LICENSE_KEY);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <PrimeReactProvider>
        <App />
      </PrimeReactProvider>
    </MsalProvider>
  </React.StrictMode>
);