import React from 'react';
import { InputText } from 'primereact/inputtext';

interface GlobalFaxFilterProps {
    globalFilterValue: string;
    onGlobalFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const GlobalFaxFilter: React.FC<GlobalFaxFilterProps> = ({ globalFilterValue, onGlobalFilterChange }) => {
    return (
        <div className="flex justify-content-end position-relative">
            <i className="pi pi-search search-icon position-absolute top-50 translate-middle" />
            <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                className="form-control suggestion-filter w-100 firstname-input mrn-input"
                placeholder="Search Fax Line, Fax Number, Caller"
            />
        </div>
    );
}

export default GlobalFaxFilter;