import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Loader from "../../components/loader/Loader";
import FilterMessage from "../../components/FilterMessage/FilterMessage";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CensusFilter from "../../components/Filter/CensusFilter/CensusFilter"; 
import CensusDataTable from "../../components/DataTable/CensusDataTable";

const Census = ({
  name,
  userEmail,
}: {
  name: string | undefined;
  userEmail: string | undefined;
}) => {
  const { censusData, loading: censusLoading } = useSelector(
    (state: RootState) => state.CensusReducer
  );

  const location = window.location.pathname;
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const [visible, setVisible] = useState(false);
  const [filterUpdate, setFilterUpdate] = useState("");

  const handleStatusUpdateLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  };

  return (
    <div className="census">
      {/* <ToastContainer /> */}
      <CensusFilter
        setNoData={setNoData}
        setVisible={setVisible}
        userEmail={userEmail}
        setFilterUpdate={setFilterUpdate}
      />
      {censusLoading || loading ? (
        <Loader location={location} path="/census" label="Census" />
      ) : censusData.length > 0 ? (
        <CensusDataTable
          filterUpdate={filterUpdate}
          name={name}
          userEmail={userEmail}
          visible={visible}
          setVisible={setVisible}
          data={censusData}
          onStatusUpdateLoading={handleStatusUpdateLoading}
        />
      ) : (
        <FilterMessage noData={noData}/>
      )}
    </div>
  );
};

export default Census;
