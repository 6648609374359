import { Calendar } from "primereact/calendar";
import React from "react";

const DateRangeFilter = ({
    setEndDate,
    startDate,
    setStartDate,
    endDate
}: {
    setEndDate: React.Dispatch<React.SetStateAction<Date | undefined>>,
    startDate?: Date,
    setStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>,
    endDate?: Date
}) => {
    return (
        <div className="flex row position-relative">
            <div className="col-md-6 pe-1">
                <Calendar
                    placeholder="Start Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.value || undefined)}
                    className="form-control custom-date overflow-hidden pe-0 mb-0"
                    showIcon
                    maxDate={endDate}
                    panelClassName="date-range-filter-panel"
                />
            </div>
            <div className="col-md-6 dateinput pe-1">
                <Calendar
                    placeholder="End Date"
                    value={endDate}
                    onChange={(e) => {
                        if (e.value) {
                            const newDate = new Date(e.value)
                            newDate.setHours(23);
                            newDate.setMinutes(59);
                            newDate.setSeconds(59);
                            setEndDate(newDate);
                        } else {
                            setEndDate(undefined);
                        }
                    }}
                    className="form-control custom-date overflow-hidden pe-0 mb-0"
                    showIcon
                    minDate={startDate}
                    panelClassName="date-range-filter-panel"
                />
            </div>
        </div>
    );
};

export default DateRangeFilter;