import { useEffect, useState } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import {
  DataTable,
  DataTableExpandedRows,
  DataTableValueArray,
} from "primereact/datatable";
import { Column } from "primereact/column";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { FormatDate } from "../../utils/formatDate";
import { formatCurrency } from "../../utils/formatCurrency";

interface LineItemParent {
  inv_no: string;
  start_date: string;
  stop_date: string;
  description: string;
  hcpc: string;
  modifier: string;
  quantity: number;
  billed: number;
  expected: number;
  total_paid: number;
  total_adjusted: number;
  balance: number;
  pk_lineitems: number; // Assuming this is present
}

interface LineItemChild {
  claim_no: string;
  payor: string;
  date_posted: string;
  type: string;
  amount: number;
  code_check_no: string;
  trans_to_claim_no: string;
  pk_lineitems: number; // Assuming this is present
}

const columns = [
  {
    field: "inv_no",
    header: "Inv.#",
  },
  {
    field: "start_date",
    header: "Start Date",
    body: (rowData: any) => <span>{FormatDate(rowData.start_date)}</span>,
  },
  {
    field: "stop_date",
    header: "Stop Date",
    body: (rowData: any) => <span>{FormatDate(rowData.stop_date)}</span>,
  },
  { field: "description", header: "Drug" },
  { field: "hcpc", header: "HCPC" },
  {
    field: "modifier",
    header: "Modifier",
  },
  { field: "quantity", header: "Quantity" },
  { field: "billed", header: "Billed", body: (rowData: any) => <span>{formatCurrency(rowData.billed)}</span> },
  { field: "expected", header: "Expected", body: (rowData: any) => <span>{formatCurrency(rowData.expected)}</span> },
  { field: "total_paid", header: "Total Paid", body: (rowData: any) => <span>{formatCurrency(rowData.total_paid)}</span> },
  { field: "total_adjusted", header: "Total Adj", body: (rowData: any) => <span>{formatCurrency(rowData.total_adjusted)}</span> },
  { field: "balance", header: "Balance", body: (rowData: any) => <span>{formatCurrency(rowData.balance)}</span> },
];

const ClaimLineItems = ({ op }: { op: any }) => {
  const { lineItemParent, lineItemChild, claimLineLoading } = useSelector(
    (state: RootState) => state.arManagerReducer
  ) as {
    lineItemParent: LineItemParent[];
    lineItemChild: LineItemChild[];
    claimLineLoading: boolean;
  };

  // const dispatch = useDispatch<AppDispatch>();
  const [expandedRows, setExpandedRows] = useState<
    DataTableExpandedRows | DataTableValueArray | undefined
  >(undefined);
  const [rowCollapse, setRowCollapse] = useState<boolean>(true);

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="Claim-line-Child px-3">
        <DataTable
          header="Postings"
          value={data}
          scrollable
          loading={claimLineLoading}
        >
          <Column field="claim_no" header="Claim No"></Column>
          <Column field="payor" header="Payor"></Column>
          <Column field="date_posted" header="Date Posted"></Column>
          <Column field="type" header="Type"></Column>
          <Column field="amount" header="Amount" body={(rowData: any) => <span>{formatCurrency(rowData.amount)}</span>}></Column>
          <Column field="code_check_no" header="Code No"></Column>
          <Column field="trans_to_claim_no" header="Trans Claim No"></Column>
        </DataTable>
      </div>
    );
  };

  const allowExpansion = () => {
    return lineItemParent.length > 0;
  };

  useEffect(() => {
    if (lineItemChild.length > 0) {
      setExpandedRows(lineItemParent);
    }
  }, [lineItemParent, lineItemChild]);

  const getFilteredChildRecords = (pk_lineitems: number) => {
    return lineItemChild.filter((child) => child.pk_lineitems === pk_lineitems);
  };

  const expandAll = () => {
    setExpandedRows(lineItemParent);
    setRowCollapse(true);
  };
  
  const collapseAll = () => {
    setExpandedRows([]);
    setRowCollapse(false);
  };

  useEffect(() => {
    console.log(expandedRows, 'expandedRows');
  }, [expandedRows]);

  const headerTemplate = () => (
    <i
      className={`pi ${rowCollapse ? 'pi-angle-down' : 'pi-angle-right'}`}
      onClick={() => (rowCollapse ? collapseAll() : expandAll())}
      style={{ cursor: 'pointer' }}
    ></i>
  );

  return (
    <>
      <OverlayPanel ref={op}>
        <DataTable
          value={lineItemParent}
          rows={15}
          emptyMessage="No Data Found"
          scrollable
          loading={claimLineLoading}
          scrollHeight="400px"
          rowExpansionTemplate={(data: LineItemParent) => {
              const filteredChildRecords = getFilteredChildRecords(
                data.pk_lineitems
              );
              return rowExpansionTemplate(filteredChildRecords);
          }}
          expandedRows={lineItemChild.length > 0 ? expandedRows : undefined}
          onRowToggle={(e) => setExpandedRows(e.data)}
        >
          {lineItemChild.length > 0 && (
            <Column header={headerTemplate} expander={allowExpansion} style={{ width: "5rem" }} />
          )}
          {columns.map((column, index) => (
            <Column
              key={index}
              field={column.field}
              header={column.header}
              body={column.body}
            />
          ))}
        </DataTable>
      </OverlayPanel>
    </>
  );
};

export default ClaimLineItems;
