import { Text, View, Image, Page, Font } from "@react-pdf/renderer";
import { BlackAndWhiteFormData } from "../types/IHealthAppTypes";
import font from '../../src/fonts/Aptos.ttf'

Font.register({
  family: "Aptos",
  src: font, // Provide the correct path to your font file
});

export function ReportPage({ children }: { children: any }) {
  return (
    <Page
      size="A4"
      wrap
      style={{ fontSize: 10, padding: 10, paddingBottom: 25, fontFamily: "Aptos" }}
    >
      {children}
    </Page>
  );
}

export function ReportSectionHeader({ label }: { label: string }) {
  return (
    <View
      style={{
        backgroundColor: "#f3f8fe",
        border: "1pt solid #ccc",
        margin: "8pt 0pt",
      }}
    >
      <Text style={{ padding: "4pt", fontWeight: "bold" }}>{label}</Text>
    </View>
  );
}

export function ReportHeader({ data }: { data: BlackAndWhiteFormData }) {
  return (
    <View
      fixed
      style={{
        fontSize: 11,
        paddingLeft: "20px",
        paddingRight: "20px",
        marginBottom: "10px",
      }}
    >
      <ReportGrid>
        <ReportGridRow>
          <ReportGridColumn label width="60%" noPadding>
            InfuCare Rx
          </ReportGridColumn>
          <ReportGridColumn label width="15%" noPadding>
            Invoice #
          </ReportGridColumn>
          <ReportGridColumn label width="25%" noPadding>
            {data.invNumber}
          </ReportGridColumn>
        </ReportGridRow>
        <ReportGridRow>
          <ReportGridColumn label width="100%">
            {data.infuLocationAdressLine1}
          </ReportGridColumn>
        </ReportGridRow>
        <ReportGridRow>
          <ReportGridColumn label width="100%">
            {data.infuLocationAdressLine2}
          </ReportGridColumn>
        </ReportGridRow>
        <ReportGridRow>
          <ReportGridColumn label width="100%">
            {data.infuLocationPhoneNumber}
          </ReportGridColumn>
        </ReportGridRow>
        <ReportGridRow>
          <ReportGridColumn label width="15%">Provider#</ReportGridColumn>
          <ReportGridColumn label width="25%%"></ReportGridColumn>
        </ReportGridRow>
        <ReportGridRow>
          <ReportGridColumn label width="15%">Tax ID#</ReportGridColumn>
          <ReportGridColumn label width="25%%">
            {data.infuLocationTaxID}
          </ReportGridColumn>
        </ReportGridRow>
      </ReportGrid>
    </View>
  );
}

export function ReportFooter() {
  return (
    <View
      fixed
      style={{
        position: "absolute",
        bottom: 4,
        left: 8,
        right: 8,
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <ReportGridRow>
        <ReportGridColumn label width="20%">Terms</ReportGridColumn>
        <ReportGridColumn width="25%"></ReportGridColumn>
        <ReportGridColumn width="55%"></ReportGridColumn>
      </ReportGridRow>
      <ReportGridRow>
        <ReportGridColumn label width="20%">Make Check Payable To</ReportGridColumn>
        <ReportGridColumn width="25%">Infucare Rx</ReportGridColumn>
        <ReportGridColumn label width="55%">
          To pay online, please visit www.infucarerx.com/paymybill
        </ReportGridColumn>
      </ReportGridRow>
      <ReportGridRow>
        <ReportGridColumn width="20%"></ReportGridColumn>
        <ReportGridColumn width="25%">PO Box 2578</ReportGridColumn>
        <ReportGridColumn width="55%"></ReportGridColumn>
      </ReportGridRow>{" "}
      <ReportGridRow>
        <ReportGridColumn width="20%"></ReportGridColumn>
        <ReportGridColumn width="25%">Secaucus NJ 07096-2578</ReportGridColumn>
        <ReportGridColumn width="55%"></ReportGridColumn>
      </ReportGridRow>
      <ReportGridRow>
        <ReportGridColumn label width="20%">Phone</ReportGridColumn>
        <ReportGridColumn width="25%">877-202-6017 option 3</ReportGridColumn>
        <ReportGridColumn width="55%"></ReportGridColumn>
      </ReportGridRow>
    </View>
  );
}

export function ReportGridRow({
  children,
  bordered,
}: {
  children: any;
  bordered?: any;
}) {
  return (
    <View
      style={{
        flexDirection: "row",
        borderBottom: "1pt solid #000",
        borderColor: bordered ? "#000" : "#fff",
      }}
    >
      {children}
    </View>
  );
}

export function ReportGridColumn({
  children,
  bordered,
  width,
  label = false,
  noColon = false,
  noPadding = false,
}: {
  children?: any;
  bordered?: any;
  width?: any;
  label?: boolean;
  noColon?: boolean;
  noPadding?: boolean;
}) {
  return (
    <View
      style={{
        flex: 1,
        borderRight: "1pt solid #fff",
        borderBottom: "1pt solid #fff",
        borderColor: bordered ? "#ccc" : "#fff",
        ...(width
          ? {
              maxWidth: width,
              minWidth: width,
            }
          : {}),
      }}
    >
      <Text
        style={{
          padding: noPadding ? "0pt" : "2pt",
          color: label ? "#000" : "#444",
          fontWeight: "ultrabold",
        }}
      >
        {children}
      </Text>
    </View>
  );
}

export function ReportGrid({
  children,
  bordered,
  fixed,
}: {
  children?: any;
  bordered?: any;
  fixed?: any;
}) {
  return (
    <View
      wrap={false}
      style={{
        borderColor: bordered ? "#ccc" : "#fff",
        margin: "4pt 0pt",
      }}
      fixed={fixed}
    >
      {children}
    </View>
  );
}
