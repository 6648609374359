import React, { useState } from "react";
// import { Document, pdfjs } from "react-pdf";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const DemoPDF = ({ pdf, setNumPages }) => {

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    return (
        <div>
            <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} />
        </div>
    );
};

export default DemoPDF;
