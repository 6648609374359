import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import NotesManagerFilter from "../../components/Filter/NotesManagerFilter/NotesManagerFilter";
import Loader from "../../components/loader/Loader";
import FilterMessage from "../../components/FilterMessage/FilterMessage";
import NotesManagerDataTable from "../../components/DataTable/NotesManagerDataTable";
import { ToastContainer } from "react-toastify";
import { Sidebar } from "primereact/sidebar";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/notesmanager.css";
import { Button } from "primereact/button";
import NotesManagerPDF from "../../components/NotesManagerForm/NotesManagerPdfConverter";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { getPatientDemographicDetails } from "../../redux/store/ARManagerReducer";

interface NotesManagerProps {
  name?: string;
  userEmail?: string;
}

const NotesManager: React.FC<NotesManagerProps> = ({ name, userEmail }) => {
  const { notesmanagerdata, loading: notesManagerLoading } = useSelector(
    (state: RootState) => state.notesManagerReducer
  );
  const dispatch = useDispatch<AppDispatch>();
  const location = window.location.pathname;
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const [filterUpdate, setFilterUpdate] = useState("");
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const handleStatusUpdateLoading = (isLoading: boolean) => setLoading(isLoading);

  const handleRowClick = (event: any,rowData: any) => {

    if (
      event.originalEvent.target.closest("td span.mrn")
    ) {
      return;
    }
    // console.log("Row clicked:", rowData);
    setSelectedRecord(rowData);
    setSidebarVisible(true);
  };

  const handleActionClick = async (action: string, rowData: any) => {
    console.log(`${action} clicked for:`, rowData);
    if (action === "edit") {
      setSelectedRecord(rowData);
      setSidebarVisible(true);
    } else if (action === "info") {
      try {
        setLoading(true);
        const response = await dispatch(
          getPatientDemographicDetails({ mrn: rowData.mrn })
        ).unwrap();
        const blob = await pdf(
          <NotesManagerPDF res={response.data[0]} rowData={rowData} />
        ).toBlob();
        saveAs(blob, `${rowData.patient_name}_Details.pdf`);
      } catch (error) {
        console.error("Error generating PDF:", error);
        alert("Failed to generate PDF. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="notes-manager">
      <ToastContainer />
      <NotesManagerFilter
        setNoData={setNoData}
        userEmail={userEmail}
        setVisible={undefined}
      />
      {notesManagerLoading || loading ? (
        <Loader
          location={location}
          path="/notes-manager"
          label="Notes Manager"
        />
      ) : notesmanagerdata.length > 0 ? (
        <NotesManagerDataTable
          data={notesmanagerdata}
          onStatusUpdateLoading={handleStatusUpdateLoading}
          filters={{}}
          search_string={filterUpdate}
          onRowClick={(e) => handleRowClick(e, e.data)}
          onActionClick={handleActionClick}
        />
      ) : (
        <FilterMessage noData={noData} />
      )}

      <Sidebar
        visible={isSidebarVisible}
        onHide={() => setSidebarVisible(false)}
        position="right"
        style={{ width: "500px" }}
        className="details-sidebar"
      >
        {selectedRecord ? (
          <div>
            <h3>Details for {selectedRecord.patient_name}</h3>
            <p>
              <strong>MRN:</strong> {selectedRecord.mrn}
            </p>
            <p>
              <strong>Invoice #:</strong> {selectedRecord.invoice_number}
            </p>
            <p>
              <strong>Type:</strong> {selectedRecord.notes_type}
            </p>
            <p>
              <strong>Subject:</strong> {selectedRecord.subject}
            </p>
            <p>
              <strong>Entered By:</strong> {selectedRecord.entered_by}
            </p>
            <p>
              <strong>Follow-Up Date:</strong> {selectedRecord.fp_date}
            </p>
            <Button
              className="close-button"
              label="Close"
              onClick={() => setSidebarVisible(false)}
            />
          </div>
        ) : (
          <p>No record selected</p>
        )}
      </Sidebar>
    </div>
  );
};

export default NotesManager;
