import React, { useState } from "react";
import { FilterMatchMode } from "primereact/api";
// import { useSelector } from "react-redux";

// import "react-circular-progressbar/dist/styles.css";
import FaxDirectoryDataTable from "../../components/DataTable/FaxDirectoryDataTable";
// import { Col, Row } from "reactstrap";



import FilterDirectorydata from "../../components/Filter/FaxDirectoryfilter/Filterdata";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Loader from "../../components/loader/Loader";
// import { FaxDirectory } from "../../assets/images/Icon";

const FaxDirectory = ({ name, userEmail }: { name: string | undefined, userEmail: string | undefined }) => {

        const { FaxDirectoryData, loading: FaxDirectoryloading } = useSelector((state: RootState) => state.FaxDirectoryReducer);
        const location = window.location.pathname;
        const [loading, setLoading] = useState(false);

        const [FaxDirectoryFilters, setFaxDirectoryFilters] = useState({
          search_string: "",
        });
        
        const [filterUpdate, setFilterUpdate] = useState('')
        // console.log(FaxDirectoryData, "FaxDirectoryDataddd");
      
        const handleStatusUpdateLoading = (isLoading: boolean) => {
          setLoading(isLoading);
        // console.log(isLoading, "isLoading");
      
        };

    return (
        <div className="faxdirectory">
      <FilterDirectorydata FaxDirectoryFilters={FaxDirectoryFilters} setFaxDirectoryFilters={setFaxDirectoryFilters} setFilterUpdate={setFilterUpdate}/>
      {FaxDirectoryloading || loading ? (<Loader location={location} path="/faxdirectory" label="Fax Details" />) : FaxDirectoryData?.length > 0 ? (<FaxDirectoryDataTable filterUpdate={filterUpdate} name={name} userEmail={userEmail}  data={FaxDirectoryData} onStatusUpdateLoading={handleStatusUpdateLoading} search_string={FaxDirectoryFilters.search_string} filters={undefined}/>) : (<div className='filter-message'><span className='text-2xl font-bold'>Apply the Filter !!</span><span>To access and view the data, please apply the filter</span></div>)}
    </div>
    )
};

export default FaxDirectory;
