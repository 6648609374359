import * as React from 'react'
import { Calendar } from 'primereact/calendar';
import { Calender } from '../../assets/images/Icon';

const InputDate = ({ value, label, id, labelclassName, inputclassName, handleChange,placeholder, invalid,requiredRight }: { value?: Date, label?: string, id: string, labelclassName?: string, inputclassName?: string, handleChange?: any , placeholder?:string, invalid?: boolean; requiredRight?:boolean}): React.ReactElement => {
    
    return (
        <>
            <div className="grid m-0 align-items-center">
                <div className={`${labelclassName ? `${labelclassName}` : 'col-6'}`}>
                    <label className='font-medium text-sm' htmlFor={id}>{label} {requiredRight ? <span className="text-danger">*</span> : null}</label>
                </div>
                <div className={`${inputclassName ? `${inputclassName}` : 'col-6'}`}>
                    <Calendar value={value} id={id}  className={`w-full ${invalid ? 'invalid-field' : ''}`} showIcon icon={() => <img src={Calender} alt='Calender' />} onChange={handleChange} placeholder={placeholder}/>
                </div>
            </div>
        </>
    )
}

export default InputDate