// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "../../utils/api/index";

const baseUrl = process.env.REACT_APP_BASEURL;

// GET Method For Assign Role By User
// export const getAssignRoleByUser = createAsyncThunk(
//     "arManager/getAssignRoleByUser",
//     async (email: string) => {
//         try {
//             const endpoint = `${baseUrl}/api/ArManager/GetAssignRoleByUser`;
//             const response = await fetch(endpoint, {
//                 method: "GET",
//                 headers: {
//                     Accept: "application/json;odata=verbose",
//                     "Content-Type": "application/json;odata=verbose",
//                 },
//                 credentials: "same-origin",
//             });

//             // Check if the response is ok
//             if (!response.ok) {
//                 throw new Error(`Network response was not ok: ${response.statusText}`);
//             }

//             const data = await response.json();

//             // Ensure data is defined before returning
//             if (!data) {
//                 throw new Error("No data received from the API");
//             }

//             return data;
//         } catch (error) {
//             console.error("Error fetching user details", error);
//             throw error;
//         }
//     }
// );

export const getAssignRoleByUser = createAsyncThunk(
  "arManager/getAssignRoleByUser",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/ArManager/getAssignRoleByUser`;
      const response = await axios.get(endpoint);
      if (response.status !== 200) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      if (!data?.data) {
        throw new Error("No data received from the API");
      }
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
      throw new Error("Error fetching user details");
    }
  }
);

export const routePermissionSlice = createSlice({
  name: 'routePermission',
  initialState: {
    assignRoleByUser: [],
    errorMessage: "",
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssignRoleByUser.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getAssignRoleByUser.fulfilled, (state, action) => {
        state.assignRoleByUser = action.payload?.data || [];
        state.loading = false
      })
      .addCase(getAssignRoleByUser.rejected, (state, action) => {
        state.errorMessage = action.error.message || ""
        state.loading = false
      })
  }
})

export default routePermissionSlice.reducer