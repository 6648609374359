export const FormatDate = (date) => {
    if (!date) return "";
    const dateObject = new Date(date);
    const day = String(dateObject.getDate()).padStart(2, '0');
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = dateObject.getFullYear();

    return `${month}/${day}/${year}`;
};

export const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric'
}).format(new Date());

export const formattedTime = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
}).format(new Date());

export const convertDateTime = (inputDateTime) => {
    const date = new Date(inputDateTime);
    const formattedDate = date.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    //   hour: 'numeric',
    //   minute: '2-digit',
    //   second: '2-digit',
    //   hour12: true // This will convert to AM/PM format
    });
  
    return formattedDate;
  }