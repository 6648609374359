import * as React from "react";
import { Dropdown } from "primereact/dropdown";
import "./FormElement.css";
import { VirtualScrollerProps } from "primereact/virtualscroller";

const InputDropdown = ({
  value,
  label,
  type,
  id,
  labelclassName,
  dropdownclassName,
  placeholder,
  options,
  optionLabel,
  optionValue,
  handleChange,
  virtualScrollerOptions,
  invalid,
  filter,
  panelClassName,
  required,
  requiredRight,
  disabled,
  handleFocus,
}: {
  value?: string | number | { label: string; value: { ID: number; StartDate: Date; EndDate: Date; }; } | undefined;
  label: string;
  type: string;
  id: string;
  labelclassName?: string;
  dropdownclassName?: string;
  placeholder?: string;
  options: any[];
  optionLabel?: string;
  optionValue?: string;
  invalid?: boolean | string;
  handleChange?: (e: any) => void;
  filter?: boolean;
  virtualScrollerOptions?: VirtualScrollerProps;
  panelClassName?: string;
  required?: boolean;
  requiredRight?: boolean;
  disabled?: boolean;
  handleFocus?: any;
}): React.ReactElement => {
  
  
  return (
    <>
      <div className="grid m-0 align-items-center">
        <div className={`${labelclassName ? `${labelclassName}` : "col-6"}`}>
          <label className="font-medium text-sm" htmlFor={id}>
            {required && <span className="text-red-500">*</span>} {label}{" "}
            {requiredRight && <span className="text-red-500">*</span>}
          </label>
        </div>
        <div
          className={`${dropdownclassName ? `${dropdownclassName}` : "col-6"}`}
        >
          <Dropdown
            className={`w-full ${invalid ? "invalid-field" : ""}`}
            id={id}
            type={type}
            value={value}
            onChange={handleChange}
            options={options}
            optionLabel={optionLabel}
            optionValue={optionValue}
            placeholder={placeholder}
            virtualScrollerOptions={virtualScrollerOptions}
            filter={filter}
            panelClassName={panelClassName}
            disabled={disabled}
            onFocus={handleFocus}
          />
          <div>
            <span className="text-red-500 text-xs">{invalid}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default InputDropdown;
