import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  Inventorydata,
  inventorydataArray,
  RentalData,
} from "../../Data/InventoryList";

export type Inventorytype = {
  Item: string;
  Qty: number;
  Inventory: number;
  Manufacturer: string;
  Supplier: string;
  TPN: string;
  NDC: string;
  HCPC: string;
  Site: string;
  Location: string;
};
export type InventoryDataArray = {
  purchaseDate: string;
  cost: string;
  lotNumber: string;
  expDate: string;
  quantity: string;
  site: string;
  serialNumber?: string;
  supplier?: string;
};

export type RentalDataType = {
  serialNumber: string;
  or: string;
  status: string;
  inUseBy: string;
  expiryDate: string;
  dispens: string;
  lastPM: string;
  nextPM: string;
  value: string;
  placedInSvc: string;
  outOfSvc: string;
  site: string;
  barcode?: string;
  residualValue?: string;
  dispensedTo?: string;
  dispensedOn?: string;
  notes?: string;
};

interface InventoryState {
  inventoryDrugArray: InventoryDataArray[];
  rentalDrug: RentalDataType[];
  errorMessage: string;
  loading: boolean;
}

const initialState: InventoryState = {
  inventoryDrugArray: inventorydataArray,
  rentalDrug: RentalData,
  errorMessage: "",
  loading: false,
};

export const addTask = createAsyncThunk(
  "Invetorylist/addTask",
  async ({ data }: { data: InventoryDataArray }) => {
    return data;
  }
);

export const updateTask = createAsyncThunk(
  "Invetorylist/updateTask",
  async ({ data }: { data: InventoryDataArray }) => {
    return data;
  }
);
export const deleteTask = createAsyncThunk(
  "Invetorylist/deleteTask",
  async ({ data }: { data: InventoryDataArray }) => {
    return data;
  }
);
export const addRental = createAsyncThunk(
  "Invetorylist/addRental",
  async ({ data }: { data: RentalDataType }) => {
    return data;
  }
);

export const updateRental = createAsyncThunk(
  "Invetorylist/updateRental",
  async ({ data }: { data: RentalDataType }) => {
    return data;
  }
);

export const deleteRental = createAsyncThunk(
  "Invetorylist/deleteRental",
  async ({ data }: { data: RentalDataType }) => {
    return data;
  }
);

export const inventorySlice = createSlice({
  name: "Invetorylist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        addTask.fulfilled,
        (state, action: PayloadAction<InventoryDataArray>) => {
          console.log("action.payload add case", action.payload);
          const newData = [...state.inventoryDrugArray];
          newData.push(action.payload);
          state.inventoryDrugArray = newData;
        }
      )
      .addCase(
        updateTask.fulfilled,
        (state, action: PayloadAction<InventoryDataArray>) => {
          console.log("action.payload update", action.payload);
          const oldData = [...state.inventoryDrugArray];
          const getNewData = oldData.map((item) => {
            if (item.lotNumber === action.payload.lotNumber) {
              return {
                ...action.payload,
              };
            } else {
              return item;
            }
          });
          state.inventoryDrugArray = getNewData;
        }
      )

      .addCase(
        deleteTask.fulfilled,
        (state, action: PayloadAction<InventoryDataArray>) => {
          console.log("action.delete", action.payload);
          const oldData = [...state.inventoryDrugArray];
          const getNewData = oldData.filter((item) => {
            return item.lotNumber !== action.payload.lotNumber;
          });
          state.inventoryDrugArray = getNewData;
        }
      )

      .addCase(addTask.rejected, (state, action) => {
        state.errorMessage = action.error.message || "An error occurred";
      })

      .addCase(
        addRental.fulfilled,
        (state, action: PayloadAction<RentalDataType>) => {
          // console.log("action.payload add case", action.payload);
          const newData = [...state.rentalDrug];
          newData.push(action.payload);
          state.rentalDrug = newData;
        }
      )
      .addCase(
        updateRental.fulfilled,
        (state, action: PayloadAction<RentalDataType>) => {
          console.log("action.payload", action.payload);
          const oldData = [...state.rentalDrug];
          const getNewData = oldData.map((item) => {
            if (item.serialNumber === action.payload.serialNumber) {
              return {
                ...action.payload,
              };
            } else {
              return item;
            }
          });
          state.rentalDrug = getNewData;
        }
      )

      .addCase(
        deleteRental.fulfilled,
        (state, action: PayloadAction<RentalDataType>) => {
          console.log("action.delete", action.payload);
          const oldData = [...state.rentalDrug];
          const getNewData = oldData.filter((item) => {
            return item.serialNumber !== action.payload.serialNumber;
          });
          state.rentalDrug = getNewData;
        }
      )

      .addCase(addRental.rejected, (state, action) => {
        state.errorMessage = action.error.message || "An error occurred";
      });
  },
});
export default inventorySlice.reducer;