import React, { useEffect, useRef, useState } from "react";
import InputField from "../../components/formElement/InputField";
import { Button } from "primereact/button";
import "../../assets/css/tasklist.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import MultiSelectFilter from "../../components/formElement/MultiSelect";
import resetbutton from "../../assets/images/ResetButton.svg";
import whiteIcon from "../../assets/images/white-filter.svg";
import starbutton from "../../assets/images/FavButton (1).svg";
import blueIcon from "../../assets/images/blue-filter.svg";
import fileIcon from "../../assets/images/fileIcon.svg";
import edit from "../../assets/images/edit.svg";
import icon from "../../assets/images/add-btn.svg";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Close, Delete, SortDecre, SortIncre } from "../../assets/images/Icon";
import { OverlayPanel } from "primereact/overlaypanel";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import AddNewTask from "../../components/Dialog/AddNewTask";
import {
  GetassignBy,
  GetassignTo,
  GetCategory,
  getPatientToDoList,
  getSiteListTask,
  getStatusOptionList,
  Getteams,
  searchPatients,
  Task,
  tasklistSlice,
} from "../../redux/store/TasklistReducer";
import { AutoComplete } from "primereact/autocomplete";
import axios from "axios";
import InputDate from "../../components/formElement/InputDate";
import Loader from "../../components/loader/Loader";
import { CustomFilterList } from "../../types/IHealthAppTypes";
import {
  AddNewCustomFilter,
  GetCustomFilterList,
} from "../../redux/store/ARManagerReducer";
import { useMsal } from "@azure/msal-react";
import { json } from "stream/consumers";
import { ToastContainer } from "react-toastify";

interface TaskItem {
  TaskID: number;
  MRN: string;
  PatientName: string;
  Site: string;
  Team: string;
  Therapy: string;
  AssignedTo: string;
  AssignedBy: string;
  Category: string;
  DueDate: string;
  Status: string;
  TaskDetails: string;
  Comments: string;
}
type ButtonState = "assigntome" | "assignbyme" | "AllTask" | null;

const formatDate = (dateString: any) => {
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);

  return `${year}/${month}/${day}`;
};

const TaskList = ({ userEmail }: { userEmail: string | undefined }) => {
  const op = useRef<OverlayPanel>(null);
  const location = window.location.pathname;
  const { tasklist, favouriteFilters } = useSelector(
    (state: RootState) => state.tasklistReducer
  );
  const { accounts } = useMsal();
  const { TaskFilterList, filterloading } = useSelector(
    (state: RootState) => state.tasklistReducer
  );
  const [filteredData, setFilteredData] = useState<TaskItem[]>(tasklist);
  const dispatch = useDispatch<AppDispatch>();
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [filterQuery, setFilterQuery] = useState<string>("");
  const { sitesOptTask } = useSelector(
    (state: RootState) => state.tasklistReducer
  );
  const { teams } = useSelector((state: RootState) => state.tasklistReducer);
  const { assingTo } = useSelector((state: RootState) => state.tasklistReducer);
  const { assingBy } = useSelector((state: RootState) => state.tasklistReducer);
  const { statusList } = useSelector(
    (state: RootState) => state.tasklistReducer
  );
  const { Category } = useSelector((state: RootState) => state.tasklistReducer);
  const { loading } = useSelector((state: RootState) => state.tasklistReducer);
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [showSaveFilter, setShowSaveFilter] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>("");
  const [favouriteFilter, setFavouriteFilter] = useState(favouriteFilters);
  const [visible, setVisible] = useState<boolean>(false);
  const [action, setAction] = useState<string>("");
  const [editData, setEditData] = useState<Task | undefined>(undefined);
  const [error, setError] = useState("");
  const [customFilterJson, setCustomFilterJson] = useState("");
  const [TaskListFilters, setTaskListFilters] = useState({
    patientSearch: "",  
    site: [],
    status: [],
    category: [],
    team: [],
    due_date: [] as Date[],
    assign_to: [],
    assign_by: [],
  });
  const [selected, setSelected] = useState<ButtonState>("assigntome");
  const [disableassingby, setDisableassingby] = useState<boolean>(false);
  const [disablemytask, setDisablemytask] = useState<boolean>(true);

  const allTaskQuery =
    '{"due_date":"","patientSearch":"","site":[],"status":["Assigned","Completed","Pending"],"assign_to":[],"assign_by":[],"category":[],"team":[]}';

  const handleApplyFilter = () => {
    const result: Record<string, any> = {};

    for (const [field, value] of Object.entries(TaskListFilters)) {
      if (field === "due_date") {
        if (Array.isArray(value)) {
          result[field] = value
            //@ts-ignore
            .filter((date: any) => date instanceof Date && !isNaN(date))
            .map((date: Date) => {
              const year = date.getFullYear();
              const month = (date.getMonth() + 1).toString().padStart(2, "0");
              const day = date.getDate().toString().padStart(2, "0");
              return `${year}-${month}-${day}`;
            })
            .join(",");
          //@ts-ignore
        } else if (value instanceof Date || !isNaN(Date.parse(value))) {
          const date = new Date(value);
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const day = date.getDate().toString().padStart(2, "0");
          result[field] = `${year}-${month}-${day}`;
        } else {
          result[field] = value;
        }
      } else if (
        field === "status" ||
        field === "team" ||
        field === "category" ||
        field === "site" ||
        field === "assign_to" ||
        field === "assign_by"
      ) {
        if (!Array.isArray(value)) {
          result[field] = [];
        } else {
          result[field] = value;
        }
      } else {
        result[field] = value ? value.toString() : "";
      }
    }

    if (selected) {
      if (selected === "assigntome") {
        const assignByName = accounts[0]?.name;

        result["assign_to"] = [assignByName];
      }
      if (selected === "assignbyme") {
        const assignByName = accounts[0]?.name;

        result["assign_by"] = [assignByName];
      }
    }

    const data = JSON.stringify(result);
    const newData = `\"${data.replace(/"/g, '\\"')}\"`;

    setFilterQuery(newData);

    dispatch(getPatientToDoList({ SearchString: newData }));

    setCustomFilterJson(data);

    op.current?.hide();
    if (!isEveryFilterEmpty()) {
      setShowSaveFilter(true);
    } else {
      setShowSaveFilter(false);
    }
  };

  const toggleImage = () => {
    setIsFilterActive(!isFilterActive);
  };

  const isEveryFilterEmpty = () => {
    return (
      TaskListFilters.category.length === 0 &&
      TaskListFilters.team.length === 0 &&
      !TaskListFilters.patientSearch &&
      TaskListFilters.assign_to.length === 0 &&
      TaskListFilters.assign_by.length === 0 &&
      TaskListFilters.status.length === 0 &&
      TaskListFilters.site.length === 0 &&
      TaskListFilters.due_date.length === 0 &&
      globalFilterValue === ""
    );
  };

  const resetFilters = () => {
    setTaskListFilters({
      due_date: [],
      patientSearch:"",  
      site: [],
      status: [],
      assign_to: [],
      assign_by: [],
      category: [],
      team: [],
    });

    setFilteredData(tasklist);
    setGlobalFilter("");
    setGlobalFilterValue("");
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = "";
    setFilters(_filters);
    setShowSaveFilter(false);
    setFilteredData([]);
    setSelected(null);
  };

  const onGlobalFilterChange = (e: any) => {
    const { id, value } = e.target;

    setTaskListFilters((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  
  
  const handleSaveFilter = () => {
    const filterExists = TaskFilterList.some(
      (filter: CustomFilterList) => filter.name === filterValue
    );

    if (filterExists) {
      setError("Filter already exists");
      return;
    }
    dispatch(
      AddNewCustomFilter({
        preference_name: "task_list_page_filter",
        filter_name: filterValue,
        preference_json_value: customFilterJson,
        usage: "USER",
        type: 1,
        custom_preference_id: 0,
        email_address: `${userEmail}`,
      })
    ).then(() => {
      setShowSaveFilter(false);
      refreshCustomFilterList();
    });
  };

  const refreshCustomFilterList = () => {
    dispatch(
      GetCustomFilterList({
        updateData: JSON.stringify({
          email_address: `${userEmail}`,
          preference_name: "task_list_page_filter",
          usage: "USER",
        }),
      })
    );
  };

  const handleCustomFilter = (e: any, op: any) => {
    op.current?.toggle(e);
    dispatch(
      GetCustomFilterList({
        updateData: JSON.stringify({
          email_address: `${userEmail}`,
          preference_name: "task_list_page_filter",
          usage: "USER",
        }),
      })
    );
  };

  const handleFilterAction = async (e: any, rowData: any) => {
    const { innerText } = e.target;
    if (innerText === "Apply Filter") {
      const data = JSON.parse(rowData.json_value);
      dispatch(
        getPatientToDoList({ SearchString: JSON.stringify(rowData.json_value) })
      ).then(() => {
        setShowSaveFilter(false);
      });

      setTaskListFilters({
        patientSearch: data.patientSearch,
        site: data.site,
        status: data.status,
        assign_to: data.assign_to,
        assign_by: data.assign_by,
        category: data.category,
        team: data.team,
        due_date: data.due_date,
      });
    } else {
      dispatch(
        AddNewCustomFilter({
          preference_name: "task_list_page_filter",
          filter_name: rowData?.name,
          preference_json_value: JSON.stringify(rowData.json_value),
          usage: "USER",
          type: 3,
          custom_preference_id: rowData?.custom_preference_id,
          email_address: `${userEmail}`,
        })
      ).then(() => {
        refreshCustomFilterList();
      });
    }
  };

  const handleClick = (button: ButtonState): void => {
    if (selected === button) {
      setSelected(null);
      resetFilters();
      if (button === "assignbyme") {
        setDisableassingby((prev) => !prev);
      } else {
        setDisablemytask((prev) => !prev);
      }
    } else {
      if (button === "assignbyme") {
        handleAssigneToMe();
        setDisableassingby((prev) => !prev);
      } else if (button === "AllTask") {
        setFilterQuery(JSON.stringify(allTaskQuery));
        dispatch(
          getPatientToDoList({ SearchString: JSON.stringify(allTaskQuery) })
        );
      } else {
        handleAssigneByMe();
        setDisablemytask((prev) => !prev);
      }

      setSelected(button);
    }
  };

  const FilterActionBody = (rowData: any) => {
    return (
      <>
        <Button
          outlined
          className="p-1 text-sm"
          label="Apply Filter"
          onClick={(e) => handleFilterAction(e, rowData)}
        />
        <Button
          icon={() => <img src={Delete} alt="Filter" />}
          style={{ background: "transparent", border: "none" }}
          onClick={(e) => handleFilterAction(e, rowData)}
        />
      </>
    );
  };

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      // Ascending
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      // Descending
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  useEffect(() => {
    setFilteredData(tasklist);
  }, [tasklist]);

  useEffect(() => {
    handleAssigneByMe();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getSiteListTask());
      await dispatch(getStatusOptionList());
      await dispatch(GetCategory());
      await dispatch(Getteams());
      await dispatch(GetassignTo());
      await dispatch(GetassignBy());
    };

    fetchData();
  }, [dispatch]);

  const handleChange = (e: any) => {
    const { id, value } = e.target;

    setTaskListFilters((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleInputChange = async (e: any) => {
   // const { id, value } = e.target;

    setTaskListFilters((prevState) => ({
      ...prevState,
      patientSearch: e.target.value,
    }));
  };
  
  

  

  const func = (date: string) => {
    return date.split(" ")[0];
  };

  const handleAssigneByMe = () => {
    const result: Record<string, any> = {};

    for (const [field, value] of Object.entries(TaskListFilters)) {
      result[field] = value;
    }
    const assignByName = accounts[0]?.name;
    if (assignByName) {
      result["assign_to"] = [assignByName];
    }

    const data = JSON.stringify(result);
    const newData = `\"${data.replace(/"/g, '\\"')}\"`;
    setFilterQuery(newData);
    dispatch(getPatientToDoList({ SearchString: newData }));
  };
  const handleAssigneToMe = () => {
    const result: Record<string, any> = {};

    for (const [field, value] of Object.entries(TaskListFilters)) {
      result[field] = value;
    }
    const assignByName = accounts[0]?.name;
    if (assignByName) {
      result["assign_by"] = [assignByName];
    }

    const data = JSON.stringify(result);
    const newData = `\"${data.replace(/"/g, '\\"')}\"`;
    setFilterQuery(newData);
    dispatch(getPatientToDoList({ SearchString: newData }));
  };

  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
  }

  return (
    <div>
      <ToastContainer />
      <div className="flex col-12">
      <div className="tab-button2  ml-6 col-7">
        <Button
          className={`toggle-btn ${
            selected === "assigntome" ? "selected" : ""
          }`}
          onClick={() => handleClick("assigntome")}
        >
          My Tasks
        </Button>

        <Button
          className={`toggle-btn ${
            selected === "assignbyme" ? "selected" : ""
          }`}
          onClick={() => handleClick("assignbyme")}
        >
          Assigned Tasks
        </Button>

        <Button
          className={`toggle-btn ${selected === "AllTask" ? "selected" : ""}`}
          label="All Task"
          onClick={() => handleClick("AllTask")}
        />


          
      </div>

      <div className=" tab-button2 col-5  pr-8">
            <Button
              className="w-3"
              label="+ Add Task"
              onClick={() => {
                setVisible(true);
                setAction("add");
              }}
              style={{ display: "flex", alignItems: "center" }}
            />
          </div>
      </div>
      <div className="tasklist-head">
        <div className="filter-container">
          <div>
            <InputField
              placeholder="Search with MRN, F&L Name"
              id="patientSearch"
              //@ts-ignore
              value={TaskListFilters.patientSearch}
              globalFilterValue={TaskListFilters.patientSearch}
              className="search-input text-left"
              onGlobalFilterChange={handleInputChange}
            />
          </div>
          <div className="multi-container">
            <MultiSelectFilter
              filterValue="site"
              // className="multiselectors"
              id="site"
              data={sitesOptTask || []}
              value={TaskListFilters.site}
              label="site"
              virtualScrollerOptions={{ itemSize: 50 }}
              onChange={handleChange}
              placeholder="Search Sites"
            />
          </div>
          <div className="multi-container">
            <MultiSelectFilter
              filterValue="status_description"
              data={statusList || []}
              value={TaskListFilters.status}
              onChange={handleChange}
              // className="multiselectors"
              id="status"
              label="status_description"
              placeholder="Select Status"
            />
          </div>
        </div>

        <div className="filter-btns">
          <div>
            <Button
              className="reaload-btn"
              icon={<img src={resetbutton} alt="My Icon" />}
              onClick={resetFilters}
              tooltip={"Reset"}
              tooltipOptions={{ position: "bottom" }}
              style={{ display: "flex", alignItems: "center" }}
            />
          </div>
          <div>
            <Button
              className={`toggle-filter-btn ${
                isFilterActive && "active-filter"
              }`}
              icon={
                <img
                  src={isFilterActive ? whiteIcon : blueIcon}
                  alt="My Icon"
                />
              }
              tooltip={"Extra Filters"}
              tooltipOptions={{ position: "bottom" }}
              onClick={toggleImage}
              style={{
                backgroundColor: isFilterActive ? "#0a3e71" : "transparent",
              }}
            />
          </div>
          <div>
            <Button
              className="fav-btn"
              icon={<img src={starbutton} alt="My Icon" />}
              onClick={(e) => {
                op.current?.toggle(e);
                handleCustomFilter(e, op);
              }}
              tooltip={"Favourite Filter"}
              tooltipOptions={{ position: "bottom" }}
            />
            <div className="favourite-filter">
              <OverlayPanel ref={op}>
                <div className="favourite-filers">
                  <div className="filter-title flex align-items-center justify-content-between mb-3">
                    <div>
                      {showSaveFilter ? (
                        <>
                          <div className="text-sm font-bold">Save Filter</div>
                          <p className="text-xs m-0">
                            Mark filter as favourite
                          </p>
                        </>
                      ) : (
                        <>
                          <div className="text-sm font-bold">Apply filter</div>
                          <p className="text-xs m-0">
                            Apply filter from your favourite
                          </p>
                        </>
                      )}
                    </div>
                    <div>
                      <img
                        src={Close}
                        alt="Close"
                        onClick={() => op.current?.hide()}
                      />
                    </div>
                  </div>

                  {showSaveFilter ? (
                    <div className="filter-name">
                      <label
                        className="text-sm font-semibold"
                        htmlFor="filter-name"
                      >
                        Filter Name
                      </label>
                      <InputText
                        className="p-2 mb-3 mt-1"
                        id="filter-name"
                        aria-describedby="username-help"
                        placeholder="Write filter name"
                        onChange={(e) => setFilterValue(e.target.value)}
                      />
                      <Button
                        label="Save Filter"
                        outlined
                        onClick={handleSaveFilter}
                      />
                    </div>
                  ) : (
                    <div className="filter-table">
                      <DataTable value={TaskFilterList} loading={filterloading}>
                        <Column header="Filter Name" field="name" />
                        <Column
                          header="Actions"
                          headerTooltip="This is the tooltip for the header"
                          body={(rowData) => FilterActionBody(rowData)}
                        />
                      </DataTable>
                    </div>
                  )}
                </div>
              </OverlayPanel>
            </div>
          </div>
          <div className="applyfilter-btn">
            <Button
              className={`apply-filter-btn ${
                isEveryFilterEmpty() ? "not-allowed-btn" : "pointer-btn"
              }`}
              label="Apply Filters"
              onClick={
                isEveryFilterEmpty()
                  ? () => {
                      return;
                    }
                  : handleApplyFilter
              }
              style={{
                cursor: isEveryFilterEmpty() ? "not-allowed" : "pointer",
                opacity: isEveryFilterEmpty() ? "0.6" : "1",
              }}
            />
          </div>
          
        </div>
      </div>

      {isFilterActive && (
        <div className="drop-downs">
          <div className="multi-container">
            <MultiSelectFilter
              filterValue="description"
              data={Category || []}
              value={TaskListFilters.category}
              onChange={handleChange}
              id="category"
              label="description"
              placeholder="Select Category"
            />
          </div>
          <div className="multi-container">
            <MultiSelectFilter
              filterValue="team_name"
              data={teams || []}
              value={TaskListFilters.team}
              onChange={handleChange}
              id="team"
              label="team_name"
              placeholder="Select Team"
            />
          </div>
          <div className="multi-container">
            <MultiSelectFilter
              filterValue="full_name"
              value={TaskListFilters.assign_to}
              data={assingTo || []}
              onChange={handleChange}
              id="assign_to"
              label="full_name"
              placeholder="Select Assigned-To"
            />
          </div>
          <div className="multi-container">
            <MultiSelectFilter
              filterValue="user_name"
              data={assingBy || []}
              value={TaskListFilters.assign_by}
              onChange={handleChange}
              id="assign_by"
              label="user_name"
              placeholder="Select Assigned-By"
            />
          </div>

          <div className="multi-container">
            <InputDate
              id="due_date"
              value={TaskListFilters.due_date[0]}
              handleChange={handleChange}
              placeholder="Select Due-Date"
              labelclassName="Duedate "
              inputclassName=" w-full TaskListCalender"
            />
          </div>
        </div>
      )}

      {loading ? (
        <Loader location={location} path="/tasklist" label="Data" />
      ) : filteredData.length > 0 ? (
        <div className="datatable">
          <DataTable
            globalFilter={globalFilter}
            filters={filters}
            value={filteredData}
            globalFilterFields={["MRN", "PatientName"]}
            scrollHeight="720px"
            paginator
            currentPageReportTemplate="Showing {first}-{last} of {totalRecords} Results"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rows={25}
            rowsPerPageOptions={[5, 10, 20, 50]}
            paginatorClassName="fax-table-paginator"
            className="tasklist-datatable"
            sortIcon={sortIcon}
            removableSort
          >
            <Column field="patient_todo_id" header="Task ID" sortable></Column>
            <Column field="mrn" header="MRN" sortable></Column>
            <Column
              field="patient_first_name"
              header="Patient Name"
              sortable
            ></Column>
            <Column field="site_name" header="Site" sortable></Column>
            <Column field="teams" header="Team" sortable></Column>
            <Column field="therapy_type" header="Therapy" sortable></Column>
            <Column
              field="assigned_to_name"
              header="Assigned To"
              sortable
            ></Column>
            <Column
              field="assigned_by_name"
              header="Assigned By"
              headerStyle={{ height: "50px" }}
              sortable
            ></Column>
            <Column field="category" header="Category" sortable></Column>
            <Column
              field="due_date"
              header="Due Date"
              body={(data) => {
                return <div>{func(data.due_date)}</div>;
              }}
              sortable
            ></Column>
            <Column
              field="status_description"
              header="Status"
              sortable
            ></Column>
            <Column field="task_detail" header="Task Details" sortable></Column>
            <Column field="comment" header="Comments" sortable></Column>
            <Column
              field="created_date"
              header="Created"
              body={(data) => formatDate(data.created_date)}
              sortable
            ></Column>
            <Column
              field="Actions"
              header="Actions"
              body={(data) => (
                <div
                  onClick={() => {
                    setVisible(true);
                    setAction("edit");
                    setEditData(data);
                  }}
                >
                  <Button
                    className="icon-button ml-2 border-none p-0"
                    icon={() => (
                      <img src={edit} alt="Edit Icon" width={20} height={20} />
                    )}
                    tooltip={"Update"}
                    tooltipOptions={{ position: "bottom" }}
                  />
                </div>
              )}
            ></Column>
          </DataTable>
        </div>
      ) : (
        <div className="apply-filter">
          <div className="h1-container">
            <h3>Apply the filter !!</h3>
          </div>
          <div className="">
            <p>Once search criteria is entered, click on "Apply filter"</p>
          </div>
        </div>
      )}
      <div>
        <AddNewTask
          visible={visible}
          setVisible={setVisible}
          action={action}
          editData={editData}
          userEmail={userEmail}
          filterQuery={filterQuery}
        />
      </div>
    </div>
  );
};

export default TaskList;
