// ** Redux Imports
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "../../utils/api/index";

const baseUrl = process.env.REACT_APP_BASEURL;

interface Status {
  status_id: number;
  status_description: string;
}

interface SearchPatientPayload {
  query: string;
  mail: string | undefined;
}
// Define a type for a single fax detail
interface PatientResponse {
  // Example properties, adjust based on the API response
  id: number;
  name: string;
  // Add other properties as needed
}

// GET Method For Prescriber
export const getPrescriber = createAsyncThunk(
  "Census/getPrescriber",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Census/getPrescriber`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Sales Representative
export const getSalesPerson = createAsyncThunk(
  "Census/getSalesPerson",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Census/getSalesPerson`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Intake Report
export const getIntakeReport = createAsyncThunk(
  "Census/getIntakeReport",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Census/getIntakeReport`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Case Manager
export const getCaseManager = createAsyncThunk(
  "Census/getCaseManager",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Census/getCaseManager`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Patient Rx Invoice
export const getPatientRxInvoice = createAsyncThunk(
  "Census/getPatientRxInvoice",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Census/getPatientRxInvoice`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Status
export const getStatusOptionList = createAsyncThunk(
  "tasklist/getStatusOptionList",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/ToDo/GetStatusDescription`;

      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data.data;
      return data;
    } catch (error) {
      console.error("Error fetching line item parent", error);
      throw error;
    }
  }
);

// GET Method For Search Rx/Invoice
export const getPatinetRxInvoice = createAsyncThunk(
  "Census/getPatinetRxInvoice",
  async ({ searchData }: { searchData: any }) => {
    try {
      const endpoint = `${baseUrl}/api/Census/getPatinetRxInvoice`;

      const response = await axios.get(endpoint, searchData);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data.data;
      return data;
    } catch (error) {
      console.error("Error fetching line item parent", error);
      throw error;
    }
  }
);

// GET Method For Census Filtered Data
export const getSearchPatientList = createAsyncThunk<
  PatientResponse[], // Return type
  SearchPatientPayload // Input payload type
>(
  "patients/getSearchPatientList",
  async ({ query, mail }, { rejectWithValue }) => {
    try {
      // const searchQuery = `{"patient_status":["Active","Inactive","Cancelled","On Hold","Pending"],"patientSearch":"${query}"}`;
      const response = await axios.get(
        `${baseUrl}/api/FaxModule/getSearchPatienList?json=${query}&mail=${mail}`
      );
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data?.data;

      return data;
    } catch (error: unknown) {
      // Explicitly type the error as unknown
      if (error instanceof Error) {
        // Check if error is an instance of Error
        return rejectWithValue(error.message); // Use the message from the Error object
      }
      return rejectWithValue("An unknown error occurred"); // Fallback message for unknown errors
    }
  }
);

export const GetCustomFilterList = createAsyncThunk(
  "arManager/GetCustomFilterList",
  async ({ updateData }: { updateData: any }, apiThunk) => {
    try {
      const endpoint = `${baseUrl}/api/ArManager/GetCustomFilterList`;
      const response = await axios.post(endpoint, updateData, {
        headers: {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
        },
      });

      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      return response.data;

      // return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const AddNewCustomFilter = createAsyncThunk(
  "arManager/AddNewCustomFilter",
  async (updateData: any, apiThunk) => {
    console.log(updateData, "updateData");

    try {
      const endpoint = `${baseUrl}/api/ArManager/AddNewCustomFilter`;
      const response = await axios.post(endpoint, updateData);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// ArManager Slice
export const censusSlice = createSlice({
  name: "census",
  initialState: {
    prescriberList: [],
    salesPersonList: [],
    intakeReport: [],
    patientRxInvoice: [],
    caseManagerList: [],
    statusList: [],
    censusData: [],
    customFilterList: [],
    errorMessage: "",
    loading: false,
    filterloading: false,
  },
  reducers: {
    resetFilter: (state) => {
      state.censusData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrescriber.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPrescriber.fulfilled, (state, action) => {
        state.prescriberList = action.payload?.data;
        state.loading = false;
      })
      .addCase(getPrescriber.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getSalesPerson.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSalesPerson.fulfilled, (state, action) => {
        state.salesPersonList = action.payload?.data;
        state.loading = false;
      })
      .addCase(getSalesPerson.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getIntakeReport.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getIntakeReport.fulfilled, (state, action) => {
        state.intakeReport = action.payload?.data;
        state.loading = false;
      })
      .addCase(getIntakeReport.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getPatinetRxInvoice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPatinetRxInvoice.fulfilled, (state, action) => {
        state.patientRxInvoice = action.payload?.data;
        state.loading = false;
      })
      .addCase(getPatinetRxInvoice.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getSearchPatientList.pending, (state) => {
        state.errorMessage = ""; // Reset previous error
        state.loading = true;
      })
      .addCase(
        getSearchPatientList.fulfilled,
        (state, action: PayloadAction<PatientResponse[]>) => {
          state.loading = false;
          // @ts-ignore

          state.censusData = action.payload;
        }
      )
      .addCase(getSearchPatientList.rejected, (state, action) => {
        state.loading = false; // Set loading to false if fetching failed
        state.errorMessage =
          (action.payload as string) || "Failed to fetch patients"; // Store the error message
      })
      .addCase(GetCustomFilterList.pending, (state, action) => {
        state.filterloading = true;
      })
      .addCase(GetCustomFilterList.fulfilled, (state, action) => {
        state.customFilterList = action.payload?.data;
        state.filterloading = false;
      })
      .addCase(GetCustomFilterList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.filterloading = false;
      })
      .addCase(getCaseManager.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCaseManager.fulfilled, (state, action) => {
        state.caseManagerList = action.payload?.data;
        state.loading = false;
      })
      .addCase(getCaseManager.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getStatusOptionList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getStatusOptionList.fulfilled, (state, action) => {
        const filteredStatuses = action.payload.filter((status: Status) =>
          [
            "Active" /*2*/,
            "Cancelled" /*4*/,
            "Inactive" /*9*/,
            "On Hold" /*10*/,
            "Pending" /*11*/,
          ].includes(status.status_description)
        );

        state.statusList = filteredStatuses;
        state.loading = false;
      })
      .addCase(getStatusOptionList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      });
  },
});

export const { resetFilter } = censusSlice.actions;
export default censusSlice.reducer;
