import React, { useEffect, useMemo, useRef, useState } from "react";
import InputField from "../../formElement/InputField";
import InputRange from "../../formElement/InputRange";
import {
  AddFilter,
  Calender,
  Close,
  Delete,
  Excel,
  Filter,
} from "../../../assets/images/Icon";
import MultiSelectFilter from "../../formElement/MultiSelect";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  AddNewCustomFilter,
  GetCustomFilterList,
  getDrugCategoryList,
} from "../../../redux/store/ARManagerReducer";
import InputNum from "../../formElement/InputNumber";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CustomFilterList } from "../../../types/IHealthAppTypes";
import InputDate from "../../formElement/InputDate";
import { Calendar } from "primereact/calendar";
import { getSiteList } from "../../../redux/store/InsuranceReducer";
import { Getteams } from "../../../redux/store/TasklistReducer";
import {
  getCaseManager,
  getIntakeReport,
  getPrescriber,
  getSalesPerson,
  getSearchPatientList,
  getStatusOptionList,
  resetFilter,
} from "../../../redux/store/CensusReducer";
import SearchInvoiceDialogBox from "../../Dialog/CensusDialogBox/SearchInvoiceDialogBox";

const CensusFilter = ({
  setVisible,
  userEmail,
  setFilterUpdate,
  setNoData,
}: {
  setVisible: any;
  userEmail: string | undefined;
  setFilterUpdate: any;
  setNoData: any;
}): React.ReactElement => {

  const { drugCategoryList,customFilterList, filterloading } =
    useSelector((state: RootState) => state.arManagerReducer);
  const { siteList } = useSelector(
    (state: RootState) => state.insuranceReducer
  );
  const { teams } = useSelector((state: RootState) => state.tasklistReducer);
  const {
    caseManagerList,
    prescriberList,
    salesPersonList,
    intakeReport,
    statusList,
    censusData,
  } = useSelector((state: RootState) => state.CensusReducer);

  const dispatch = useDispatch<AppDispatch>();
  const [customFilterJson, setCustomFilterJson] = useState("");
  const [showSaveFilter, setShowSaveFilter] = useState<boolean>(false);
  const [searchInvoiceVisible, setSearchInvoiceVisible] = useState(false);
  const [filterValue, setFilterValue] = useState<string>("");
  const [error, setError] = useState("");
  const [CensusFilters, setCensusFilters] = useState({
    patientSearch: "",
    site_no: [],
    patient_status: [],
    patient_dob: undefined,
    drug_category_id: [],
    prescriber_no: [],
    sales_rep_employee_id: [],
    intake_employee_id: [],
    primary_rn_employee_id: [],
    team_id: [],
  });
  const op = useRef<OverlayPanel>(null);

  // Dispatch method to render the filter data
  useEffect(() => {
    dispatch(getDrugCategoryList());
    dispatch(getStatusOptionList());
    dispatch(getCaseManager());
    dispatch(getIntakeReport());
    dispatch(getSalesPerson());
    dispatch(getPrescriber());
    dispatch(getSiteList());
    dispatch(Getteams());
  }, [dispatch]);

  // Function to Check Filter Empty
  const isFiltersEmpty = () => {
    return Object.values(CensusFilters).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return value === "" || value === null;
    });
  };

  // Change the filter data
  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setCensusFilters((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      // dispatch(
      //   getArManagerData({
      //     updateData: `"{\\\"patientSearch\\\":\\\"${CensusFilters.patientSearch}\\\"}"`,
      //   })
      // );
      setFilterUpdate(
        `"{\\\"patientSearch\\\":\\\"${CensusFilters.patientSearch}\\\"}"`
      );
    }
  };

  const handleCustomFilter = (e: any, op: any) => {
    op.current?.toggle(e);
    dispatch(
      GetCustomFilterList({
        updateData: JSON.stringify({
          email_address: `${userEmail}`,
          preference_name: "Census_page_filter",
          usage: "USER",
        }),
      })
    );
    // if (customFilterList.length > 0) { setShowSaveFilter(false) };
  };

  const handleSaveFilter = () => {
    const filterExists = customFilterList.some(
      (filter: CustomFilterList) => filter.name === filterValue
    );

    if (filterExists) {
      setError("Filter already exists");
      return;
    }
    dispatch(
      AddNewCustomFilter({
        preference_name: "Census_page_filter",
        filter_name: filterValue,
        preference_json_value: customFilterJson,
        usage: "USER",
        type: 1,
        custom_preference_id: 0,
        email_address: `${userEmail}`,
      })
    ).then(() => {
      setShowSaveFilter(false); // Show table again after saving
      refreshCustomFilterList(); // Refresh the filter table after saving
    });
  };

  // Filter Action From Favourite Filter
  const handleFilterAction = (e: any, rowData: any) => {
    const { innerText } = e.target;

    if (innerText === "Apply Filter") {
      const data = JSON.parse(rowData.json_value);
      dispatch(
        getSearchPatientList({ query: rowData.json_value, mail: userEmail })
      ).then(() => {
        setShowSaveFilter(false);
      });

      setCensusFilters({
        patientSearch: data.patientSearch,
        site_no: data.site_no,
        patient_status: data.patient_status,
        patient_dob: data.patient_dob,
        drug_category_id: data.drug_category_id,
        prescriber_no: data.prescriber_no,
        sales_rep_employee_id: data.sales_rep_employee_id,
        intake_employee_id: data.intake_employee_id,
        primary_rn_employee_id: data.primary_rn_employee_id,
        team_id: data.team_id,
      });
    } else {
      dispatch(
        AddNewCustomFilter({
          preference_name: "AR_page_filter",
          filter_name: rowData?.name,
          preference_json_value: JSON.stringify(rowData.json_value),
          usage: "USER",
          type: 3,
          custom_preference_id: rowData?.custom_preference_id,
          email_address: `${userEmail}`,
        })
      ).then(() => {
        refreshCustomFilterList(); // Refresh table after deleting
      });
    }
  };

  const refreshCustomFilterList = () => {
    // dispatch(
    //   GetCustomFilterList({
    //     updateData: JSON.stringify({
    //       email_address: `${userEmail}`,
    //       preference_name: "AR_page_filter",
    //       usage: "USER",
    //     }),
    //   })
    // );
  };

  // Applying the filters
  const handleApplyFilter = () => {
    const result: Record<string, any> = {};
    setShowSaveFilter(true);

    // const fieldMapping: Record<string, (item: any) => any> = {
    //   site_no: (item: { employee_id: number }) => item.employee_id,
    //   patient_status: (item: { delivery_status_id: number }) =>
    //     item.delivery_status_id,
    //   drug_category_id: (item: { id: number }) => item.id,
    //   prescriber_no: (item: { company_id: number }) => item.company_id,
    //   sales_rep_employee_id: (item: { company_id: number }) => item.company_id,
    //   intake_employee_id: (item: { demographic_value: string }) =>
    //     item.demographic_value,
    //   primary_rn_employee_id: (item: { therapy_type_id: number }) =>
    //     item.therapy_type_id,
    //   team_id: (item: { description: string }) => item.description,
    // };

    for (const [field, value] of Object.entries(CensusFilters)) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          // if (fieldMapping[field]) {
          // result[field] = value.map(fieldMapping[field]);
          // } else {
          result[field] = value;
          // }
        }
      } else if (value !== "" && value !== null) {
        result[field] = value;
      }
    }

    const data = JSON.stringify(result);
    
    setFilterUpdate(data);
    
    dispatch(getSearchPatientList({ query: data, mail: userEmail})).then(() => {
      if (censusData.length === 0) {
        setNoData(true);
      }
    });

    setCustomFilterJson(data);
  };

  // Clear All Filters
  const handleClearFilter = () => {
    setNoData(false);
    setCensusFilters({
      patientSearch: "",
      site_no: [],
      patient_status: [],
      patient_dob: undefined,
      drug_category_id: [],
      prescriber_no: [],
      sales_rep_employee_id: [],
      intake_employee_id: [],
      primary_rn_employee_id: [],
      team_id: [],
    });
    dispatch(resetFilter());
    setShowSaveFilter(false);
  };

  //   const newBillerList = useMemo(() => {
  //     return billerList.map((bill: billerList) => {
  //         return {
  //             value: bill.employee_id,
  //             first_name: bill.first_name,
  //         }
  //     })
  //   }, [billerList]);

  const FilterActionBody = (rowData: any) => {
    return (
      <>
        <Button
          outlined
          className="p-1 text-sm"
          label="Apply Filter"
          onClick={(e) => handleFilterAction(e, rowData)}
        />
        <Button
          icon={() => <img src={Delete} alt="Filter" />}
          style={{ background: "transparent", border: "none" }}
          onClick={(e) => handleFilterAction(e, rowData)}
        />
      </>
    );
  };

  return (
    <>
      <div className="filters">
        <div className="flex align-items-center justify-content-between pr-3">
          <div className="flex align-items-center mx-0">
            <div className="col-5">
              <InputField
                placeholder="Search with MRN, F&L Name, Phone"
                globalFilterValue={CensusFilters.patientSearch}
                onGlobalFilterChange={handleChange}
                handleKeyDown={handleKeyDown}
                id="patientSearch"
              />
            </div>
            <div className="col-2 custom-padding">
              <MultiSelectFilter
                filterValue="id"
                id="site_no"
                value={CensusFilters.site_no}
                onChange={handleChange}
                label="site"
                data={siteList}
                placeholder="All Site"
              />
            </div>
            <div className="col-2 custom-padding">
              <MultiSelectFilter
                filterValue="status_description"
                id="patient_status"
                value={CensusFilters.patient_status}
                onChange={handleChange}
                label="status_description"
                data={statusList}
                placeholder="All"
              />
            </div>
            <div className="col-3 custom-padding">
              <Calendar
                value={CensusFilters.patient_dob}
                id="dob"
                className="w-full"
                showIcon
                icon={() => <img src={Calender} alt="Calender" />}
                onChange={handleChange}
                placeholder="Select DOB"
              />
            </div>
            <div className="col-3 gap-2 flex">
              <Button label="Reset" outlined onClick={handleClearFilter} />
              <Button
                label="Apply Filter"
                onClick={handleApplyFilter}
                disabled={isFiltersEmpty()}
              />
            </div>
          </div>
          <div className="icons flex align-items-center">
            <div className="favourite-filter">
              <Button
                className="p-0"
                icon={() => (
                  <img
                    src={
                      censusData.length > 0 && showSaveFilter
                        ? AddFilter
                        : Filter
                    }
                    alt="Filter"
                    title={`${
                      censusData.length > 0 && showSaveFilter
                        ? `Save Filter`
                        : `Apply Filter`
                    }`}
                  />
                )}
                onClick={(e) => handleCustomFilter(e, op)}
                style={{ background: "transparent", border: "none" }}
              />
              <OverlayPanel ref={op}>
                <div className="favourite-filers">
                  <div className="filter-title flex align-items-center justify-content-between mb-3">
                    <div>
                      {showSaveFilter ? (
                        <>
                          <div className="text-sm font-bold">Save Filter</div>
                          <p className="text-xs m-0">
                            Mark filter as favourite
                          </p>
                        </>
                      ) : (
                        <>
                          <div className="text-sm font-bold">Apply filter</div>
                          <p className="text-xs m-0">
                            Apply filter from your favourite
                          </p>
                        </>
                      )}
                    </div>
                    <div>
                      <img
                        src={Close}
                        alt="Close"
                        onClick={() => op.current?.hide()}
                      />
                    </div>
                  </div>

                  {showSaveFilter ? (
                    <div className="filter-name">
                      <label
                        className="text-sm font-semibold"
                        htmlFor="filter-name"
                      >
                        Filter Name
                      </label>
                      <InputText
                        className="p-2 mb-1 mt-1"
                        id="filter-name"
                        aria-describedby="username-help"
                        placeholder="Write filter name"
                        onChange={(e) => setFilterValue(e.target.value)}
                      />
                      <p className="text-red-500 text-xs mb-1">{error}</p>
                      <Button
                        label="Save Filter"
                        outlined
                        onClick={handleSaveFilter}
                      />
                    </div>
                  ) : (
                    <div className="filter-table">
                      <DataTable
                        value={customFilterList}
                        loading={filterloading}
                      >
                        <Column header="Filter Name" field="name" />
                        <Column
                          header="Actions"
                          body={(rowData) => FilterActionBody(rowData)}
                        />
                      </DataTable>
                    </div>
                  )}
                </div>
              </OverlayPanel>
            </div>
            <div className="search-invoice">
              <Button
                style={{ background: "transparent", border: "none" }}
                className="p-0"
                icon={() => (
                  <img src={Excel} alt="Excel" title="Excel Export" />
                )}
                onClick={() => setSearchInvoiceVisible(true)}
              />
            </div>
            <div className="patient-diagnosis">
              <Button
                style={{ background: "transparent", border: "none" }}
                className="p-0"
                icon={() => (
                  <img src={Excel} alt="Excel" title="Excel Export" />
                )}
                onClick={() => setVisible(true)}
              />
            </div>
            <div className="new-patient mr-2">
              <Button
                label="New Patient"
                icon="pi pi-plus"
                outlined
                onClick={handleClearFilter}
              />
            </div>
            <div className="map-view">
              <Button
                label="Map View"
                icon="pi pi-map"
                outlined
                onClick={handleClearFilter}
              />
            </div>
          </div>
        </div>
        <div className="grid align-items-center mx-0">
          <div className="multiselect-filter col-2">
            <MultiSelectFilter
              filterValue="drug_category_id"
              id="drug_category_id"
              value={CensusFilters.drug_category_id}
              onChange={handleChange}
              label="description"
              data={drugCategoryList}
              placeholder="Search Category"
            />
          </div>
          <div className="multiselect-filter col-2">
            <MultiSelectFilter
              filterValue="prescriber_no"
              id="prescriber_no"
              value={CensusFilters.prescriber_no}
              onChange={handleChange}
              label="full_name"
              data={prescriberList}
              placeholder="Search Prescriber"
              virtualScrollerOptions={{ itemSize: 50 }}
            />
          </div>
          <div className="multiselect-filter col-2">
            <MultiSelectFilter
              filterValue="employee_id"
              id="sales_rep_employee_id"
              value={CensusFilters.sales_rep_employee_id}
              onChange={handleChange}
              label="full_name"
              data={salesPersonList}
              placeholder="Search Sales Representative"
              virtualScrollerOptions={{ itemSize: 50 }}
            />
          </div>
          <div className="multiselect-filter col-2">
            <MultiSelectFilter
              filterValue="employee_id"
              id="intake_employee_id"
              value={CensusFilters.intake_employee_id}
              onChange={handleChange}
              label="full_name"
              data={intakeReport}
              placeholder="Search Intake"
            />
          </div>
          <div className="multiselect-filter col-2">
            <MultiSelectFilter
              filterValue="employee_id"
              id="primary_rn_employee_id"
              value={CensusFilters.primary_rn_employee_id}
              onChange={handleChange}
              label="full_name"
              data={caseManagerList}
              placeholder="Search Case Manager"
            />
          </div>
          <div className="multiselect-filter col-2">
            <MultiSelectFilter
              filterValue="team_id"
              id="team_id"
              value={CensusFilters.team_id}
              onChange={handleChange}
              label="team_name"
              data={teams}
              placeholder="Search Team"
              virtualScrollerOptions={{ itemSize: 50 }}
            />
          </div>
        </div>
      </div>
      <SearchInvoiceDialogBox
      visible={searchInvoiceVisible}
      setVisible={setSearchInvoiceVisible}
      />
    </>
  );
};

export default CensusFilter;
