import React from 'react';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SortDecre, SortIncre, Warning } from '../../assets/images/Icon';
import { Tooltip } from 'primereact/tooltip';
import { FaxItem } from "../../pages/Fax/FaxScreen";
import Loader from '../loader/Loader';
import { useNavigate } from 'react-router-dom';

interface FaxDataTableProps {
    data: FaxItem[];
    filters: DataTableFilterMeta; // Ensure this matches the type expected
    loading: boolean;
    undoBody: any
}

const FaxDataTable: React.FC<FaxDataTableProps> = ({ data, filters, loading, undoBody }) => {
    const sortIcon = (order: any) => {
        if (order.sortOrder === 1) {
            return <img className='ml-1' src={SortIncre} alt='Sort Ascending' />;
        } else if (order.sortOrder === -1) {
            return <img className='ml-1' src={SortDecre} alt='Sort Descending' />;
        }
        return <img className='ml-1' style={{ opacity: "0" }} src={SortDecre} alt='Sort Descending' />;
    };

    const navigate = useNavigate();

    const pageOptions = [5, 10, 15, 25, 75, 100];

    const location = window.location.pathname;
    if (loading) {
        return (
            <div>
                <Loader label='fax' location={location} path='/fax' />
            </div>
        );
    }

    if (data?.length === 0) {
        return <div>No data available.</div>;
    }

    // Row select handler to navigate to a specific document
    const onRowSelect = (rowData: FaxItem) => {
        if (rowData?.fax_folder_name && rowData?.file_name) {
            navigate(`/fax/user-document/${rowData.fax_detail_id}`);
        }
    };

    function formatDate(dateString: string): string {
        const date = new Date(dateString);
        const month = (date.getMonth() + 1);
        const day = date.getDate();
        const year = date.getFullYear();

        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;

        return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
    }


    return (
        <DataTable
            scrollable
            removableSort
        
            scrollHeight="750px"
            value={data}
            paginator
           
            sortIcon={sortIcon}
            selectionMode="single"
            rowsPerPageOptions={pageOptions}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rows={25}
            currentPageReportTemplate="Showing {first}-{last} of {totalRecords} Results"
            filters={filters}
            globalFilterFields={["fax_line", "owner_name", "recipient_fax_number", "caller", "assignment_status", "caller"]} // Add fields that you want to apply the global filter to
            emptyMessage="No Records found."
            onRowSelect={(e) => onRowSelect(e.data)} // Extract the data for the selected row
            paginatorClassName='fax-table-paginator'
            rowClassName={(rowData) => `fax-row-${rowData.fax_detail_id}`}
        >
            <Column field="recipient_fax_number" sortable header="Fax Number" className="custom-width" body={(data) => (
                <div>
                    {data.recipient_fax_number}
                    &nbsp;&nbsp;
                    {(!data?.fax_folder_name || !data?.file_name) && (
                        <>
                            <img
                                height={15}
                                width={15}
                                src={Warning}
                                className={`no-file-warning-${data.fax_detail_id}`}
                            />
                            <Tooltip
                                target={`.no-file-warning-${data.fax_detail_id}`}
                                content="Folder or File not found"
                                mouseTrackTop={10}
                                position="bottom"
                                className="custom-tooltip"
                            />
                        </>
                    )}
                    <Tooltip
                        target={`.fax-row-${data.fax_detail_id}`}
                        content={data.file_name}
                        mouseTrack
                        mouseTrackTop={10}
                        position="bottom"
                        className="custom-tooltip"
                        showDelay={500}
                    />
                </div>
            )} />
            <Column field='owner_name' sortable header="Fax Owner Name" />
            <Column field="date_and_time" sortable header="Date" body={(data) => formatDate(data.date_and_time)} />
            <Column field="fax_line" sortable header="Fax Line" />
            <Column field='caller' sortable header="InfucareRx Number" className="custom-width" />
            {/* <Column field="caller" sortable header="Caller" /> */}
            <Column field="pages" sortable header="Page(s)" />
            <Column field="assignment_status" sortable header="Assignment Status" />
            <Column field="edit_owner" body={(data) => undoBody(data)} />
        </DataTable>
    );
};

export default FaxDataTable;