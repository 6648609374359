import { IconField } from 'primereact/iconfield'
import React from 'react'
import { InputIcon } from 'primereact/inputicon'
import { InputText } from 'primereact/inputtext'
import { Search } from '../../assets/images/Icon'
// import "../../assets/css/inventory.css"

const InputField = ({ placeholder, globalFilterValue, onGlobalFilterChange, id, value, onChange, className, disabled, handleKeyDown }: { disabled?: boolean; placeholder: string, globalFilterValue?: string, value?: string; className?: string; onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; onGlobalFilterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void, id: string, handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void; }) => {
    return (
        <div className="globalfilter mr-3">
            <IconField iconPosition="left" className='search'>
                <InputIcon>
                    <img src={Search} alt="Search" />
                </InputIcon>
                <InputText id={id} onKeyDown={handleKeyDown} value={globalFilterValue} className={className} type='string' onChange={onGlobalFilterChange} placeholder={placeholder} disabled={disabled} />
            </IconField>
        </div>
    )
}

export default InputField