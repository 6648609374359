import React, { useState, useEffect, useRef } from "react";

import InputField from "../../formElement/InputField";
import { Button } from "primereact/button";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  getFaxDirectoryData,
  resetFilter,
} from "../../../redux/store/FaxDirectoryReducer";

import { useDispatch, useSelector } from "react-redux";

const FilterDirectorydata = ({
  setFilterUpdate,
  FaxDirectoryFilters,
  setFaxDirectoryFilters,
}: {
  setFilterUpdate: any;
  FaxDirectoryFilters: { search_string: string };
  setFaxDirectoryFilters: React.Dispatch<
    React.SetStateAction<{ search_string: string }>
  >;
}): React.ReactElement => {
  const { FaxDirectoryData, filterloading } = useSelector(
    (state: RootState) => state.FaxDirectoryReducer
  );
  const [customFilterJson, setCustomFilterJson] = useState("");

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    // console.log(FaxDirectoryData, "FaxDirectoryData");
  }, [FaxDirectoryData]);

  // Function to Check Filter Empty
  const isFiltersEmpty = () => {
    return Object.values(FaxDirectoryFilters).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return value === "" || value === null;
    });
  };

  // Change the filter data
  const handleChangeFaxDirectory = (e: any) => {
    const { id, value } = e.target;
    setFaxDirectoryFilters((prevState) => ({
      ...prevState,
      search_string: value,
    }));
  };

  const handleResetChangeFaxDirectory = (e: any) => {
    const { value } = e.target;

    // Reset the value (e.g., to an empty string)
    setFaxDirectoryFilters((prevState) => ({
      ...prevState,
      search_string: "", // Resetting to an empty string or another default value
    }));
    dispatch(resetFilter());
    // console.log(FaxDirectoryData, "FaxDirectoryData");
  };

  const handleApplyFilterFaxDirectory = () => {
    const data = JSON.stringify(FaxDirectoryFilters);
    // const newData = `\"${data.replace(/"/g, '\\"')}\"`;
    setFilterUpdate(FaxDirectoryFilters.search_string);

    dispatch(
      getFaxDirectoryData({ updateData: FaxDirectoryFilters.search_string })
    );
    setCustomFilterJson(data);
  };

  return (
    <>
      <div className="grid align-items-center mx-0">
        <div className="col-3">
          <InputField
            placeholder="Search Fax Owner name, Fax Owner ID, Fax number"
            globalFilterValue={FaxDirectoryFilters.search_string}
            onGlobalFilterChange={handleChangeFaxDirectory}
            id="SearchFaxDirectory"
          />
        </div>
        <div className="col-2 gap-2 flex">
          <Button
            label="Reset"
            outlined
            onClick={handleResetChangeFaxDirectory}
            id="SearchFaxDirectory"
          />
          <Button
            label="Apply Filter"
            onClick={handleApplyFilterFaxDirectory}
            disabled={isFiltersEmpty()}
          />
        </div>
      </div>
    </>
  );
};

export default FilterDirectorydata;
