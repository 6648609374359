import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button';
import Input from '../../formElement/Input';
import { PickList } from 'primereact/picklist';
import * as XLSX from 'xlsx';
import { formattedDate, formattedTime } from '../../../utils/formatDate';

const EXCEL_EXTENSION = '.xlsx';

const ExportToExcelDialogBox = ({ name, data, visible, setVisible, columns }: { name: string | undefined, data?: any, visible: boolean, setVisible: any, columns: any }): React.ReactElement => {

    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);
    const [printable, setPrintable] = useState('');
    const [filename, setFilename] = useState('');

    // Export Excel Function
    const handleExcelExport = () => {
        if (!printable) return;

        const jsonData = typeof printable === 'string' ? JSON.parse(printable) : printable;

        const jsonArray = Object.values(jsonData);

        const selectedColumns = target.map((col: any) => col.id);

        const filteredData = jsonArray.map((item: any) => {
            const filteredItem: any = {};
            selectedColumns.forEach((col: string) => {
                filteredItem[col] = item[col];
            });
            return filteredItem;
        });

        // Convert to Excel sheet
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        XLSX.writeFile(workbook, filename);
    };

    useEffect(() => {
        const columnsHeaders = columns.map((col: any) => ({ id: col.field, header: col.header }))
        setSource(columnsHeaders);
    }, [columns]);

    useEffect(() => {
        setPrintable(data || '');
        setFilename(`AR_Manager_Exported_By_${name}_${formattedDate}_${formattedTime}` + EXCEL_EXTENSION);
    }, [data, formattedDate, formattedTime, name]);

    // Footer of the Dialog
    const Footer = (
        <div className="buttons flex gap-2 justify-content-end">
            <Button label='Cancel' outlined onClick={() => setVisible(false)} />
            <Button label='Export to Excel' onClick={handleExcelExport} />
        </div>
    );

    // Export Excel Row List Template
    const itemTemplate = (item: any) => {
        return (
            <div className="flex flex-wrap align-items-center gap-3">
                {item.header}
            </div>
        );
    };

    const onChange = (e: any) => {
        setSource(e.source);
        setTarget(e.target);
    };


    return (
        <Dialog draggable={false} header="Export data into Excel" footer={Footer} visible={visible} style={{ width: '60vw' }} onHide={() => { if (!visible) return; setVisible(false); }}>
            <div className="input">
                <Input value={filename} label='Excel File Name' type='text' id='export-excel' labelclassName='col-2' inputclassName='col-5' />
                <div className="grid m-0">
                    <div className="col-2 picklist-title text-sm font-medium">
                        Select Column(s)
                    </div>
                    <div className="col-10">
                        <PickList dataKey="id" source={source} target={target} onChange={onChange} itemTemplate={itemTemplate} breakpoint="1280px"
                            sourceHeader="Available" targetHeader="Selected" />
                    </div>
                </div>
                <p className='m-0 p-2 text-sm mt-3'>Note: In the Export File, columns will only appear if they are in the right-side box, and they will display in the order you select them from that box.</p>
            </div>
        </Dialog>
    )
}

export default ExportToExcelDialogBox