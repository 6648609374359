import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Input from "../formElement/Input";
import InputDropdown from "../formElement/InputDropdown";
import { useSelector,useDispatch } from "react-redux";
import { addTask, deleteTask, InventoryDataArray, updateTask } from "../../redux/store/InventoryReducer";
import {AppDispatch, RootState } from "../../redux/store";
import InputDate from "../formElement/InputDate";

interface AddInventoryDialogBoxProps {
  header: string;
  visibleInventory: boolean;
  onHide: () => void;
  editData?: InventoryDataArray;
}

const AddInventoryPlus: React.FC<AddInventoryDialogBoxProps> = ({
  header,
  visibleInventory,
  onHide,
  editData
}) => {

  // Form state
  const [formData, setFormData] = useState<InventoryDataArray>(

    {
      purchaseDate: '',
      cost: '',
      lotNumber: "",
      expDate: "",
      quantity: '',
      site: "",
      serialNumber: '',
      supplier: "",

    }
  );



  const dispatch = useDispatch<AppDispatch>();
  const { inventoryDrugArray } = useSelector(
    (state: RootState) => state.inventoryReducer
  );


  useEffect(() => {  
    if (visibleInventory) {
    
      if (header === "edit drug" && editData) {
        setFormData(editData);
      } else {
       
        resetForm();
      }
    }
  }, [visibleInventory, editData]);
  

// sits-suppiler options
  const sites = [
    { label: "Site A", value: "Site A" },
    { label: "Site B", value: "Site B" },
  ];

  const suppliers = [
    { label: "Supplier 1", value: "Supplier 1" },
    { label: "Supplier 2", value: "Supplier 2" },
  ];


  // Reset Form
  const resetForm = () => {
    setFormData({
      purchaseDate: "",
      cost: '',
      lotNumber: "",
      expDate: "",
      quantity: '',
      site: "",
      serialNumber: '',
      supplier: "",
    });
  };



  // Handle Save Button
  const handleSave = async () => {

    
    if (header === "edit drug") {
     
     
      const data = {
          ...formData,
           
  };

  await dispatch(updateTask({ data: data }));



 
    }else{

    
     
      const data = {
          ...formData,
           
  };

  await dispatch(addTask({ data: data }));
    }
      


  onHide();
 

}

const deleteHandle=async() =>{
 const data={...formData}

 await dispatch(deleteTask({data:data}));
 onHide();
}
  


  return (
    <Dialog
      className="Add-inventory-dialog"
      header={header}
      visible={visibleInventory}
      onHide={onHide}
      style={{ width: "30vw" }}
      draggable={false} 
    >
     
      <div className="p-fluid inventory-form-control">
       

      
        <div className="p-field">
         

<InputDate
           id="purchaseDate"
            label="Purchase date:"
            // type="date"
            labelclassName="Add-inventoryplus-lable col-6"
            //  value={formData.expDate}
            inputclassName="w-80"
            
           
            handleChange={(e:any) =>
              setFormData({ ...formData, purchaseDate: e.target.value })
            }
            />
        </div>

      
        <div className="p-field">
          <Input
            id="cost"
            label="Cost:"
            type="text"
            labelclassName="Add-inventoryplus-lable col-6"
            value={formData.cost}
           handleChange={(e) =>
           setFormData({ ...formData, cost: e.target.value })
             }
              inputclassName="w-80"
          />
        </div>

       
        <div className="p-field">
          <Input
            id="lotNumber"
            label="Lot#:"
            type="text"
            labelclassName="Add-inventoryplus-lable col-6"
            value={formData.lotNumber}
            handleChange={(e) =>
              setFormData({ ...formData, lotNumber: e.target.value })
            }
             inputclassName="w-80"
             />
        </div>

       
        <div className="p-field">
             <InputDate
            id=" expDate"
            label=" Exp.date:"
            // type="date"
            labelclassName="Add-inventoryplus-lable col-6"
            //  value={formData.expDate}
            inputclassName="w-80"
            
           
            handleChange={(e:any) =>
              setFormData({ ...formData, expDate: e.target.value })
            }
            />
        </div>

       
        <div className="p-field">
          <div className="p-field">
            <Input
              id="quantity"
              label="Quantity:"
              type="text"
              labelclassName="Add-inventoryplus-lable col-6"
              value={formData.quantity}
             handleChange={(e) =>
             setFormData({ ...formData, quantity: e.target.value })
             }
              inputclassName="w-80"
            />
          </div>
        </div>

       
        <div className="p-field">
          <InputDropdown
            id=" site"
            label=" Site:"
            type="dropdown"
            labelclassName="Add-inventoryplus-lable col-6"
             dropdownclassName="w-80"
            options={sites}
            value={formData.site}
            handleChange={(e) =>
              setFormData({ ...formData, site: e.target.value })
            }
          />
        </div>

      
        <div className="p-field">
          <Input
            id="serialNumber"
            label="Serial number:"
            type="text"
            labelclassName="Add-inventoryplus-lable col-6"
            value={formData.serialNumber}
          handleChange={(e) =>
           setFormData({ ...formData, serialNumber: e.target.value })
            }
             inputclassName="w-80"
          />
        </div>

       
        <div className="p-field">
          <InputDropdown
            id=" supplier"
            label=" Supplier:"
            type="dropdown"
            labelclassName="Add-inventoryplus-lable col-6"
            options={suppliers}
            value={formData.supplier}
            dropdownclassName="w-80"
            handleChange={(e) =>
              setFormData({ ...formData, supplier: e.target.value })
            }
          />
        </div>

      
        <div className= {` ${header === "edit drug" ?"inventory-grid":"inventory-grid2"} m-0 align-items-center`} >
          <Button
            label="Clear"
            className={` inventory-button-secondary    col-4  ${header === "edit drug" ? "edit-active" : "inactive"
              } `}
            onClick={resetForm}
          />

          <Button
            label="Save"
            className={`inventory-foter-button     col-4  ${header === "edit drug" ? "edit-active" : "inactive"
              } `}
            onClick={handleSave}
          />
          {header === "edit drug" && (
            <Button
              label="Delete"
              className={`inventory-foter-button    col-4 ${header === "edit drug" ? "edit-active" : "inactive"
                } `}
              onClick={deleteHandle}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default AddInventoryPlus;