import {
  BrowserCacheLocation,
  EventMessage,
  EventType,
  LogLevel,
  PublicClientApplication,
  Configuration,
  AccountInfo,
  AuthenticationResult,
  InteractionRequiredAuthError,
  BrowserAuthError
} from "@azure/msal-browser";

const clientId = process.env.REACT_APP_CLIENTID ?? '';
const authorityTenantId = process.env.REACT_APP_TENANTID ?? '';
const authority = process.env.REACT_APP_AUTHORITY ?? '';
const redirectUri = process.env.REACT_APP_REDIRECTURI ?? '';

if (!clientId || !authorityTenantId || !authority || !redirectUri) {
  throw new Error('One or more required environment variables are missing.');
}

const authorityUrl = `${authority}/${authorityTenantId}`;

export const msalConfig: Configuration = {
  auth: {
    clientId: clientId, // Your client (application) ID goes here
    authority: authorityUrl, // Constructed authority URL with tenant ID
    redirectUri: redirectUri, // Must be the same in Azure AD portal
    postLogoutRedirectUri: "/", // Redirect URL after logout
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage, // Configures cache location
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Warning,
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
            break;
        }
      },
    },
  },
};

// Define login request type
export const loginRequest = {
  scopes: ["api://e62dad74-1f21-4dd3-a762-15e00c45319b/access_as_user"],
};

// Initialize the PublicClientApplication
export const msalInstance = new PublicClientApplication(msalConfig);

// Set the active account if none is set and there are accounts available
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Event handling for login and logout events
msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const account = (event.payload as AuthenticationResult).account as AccountInfo;
    msalInstance.setActiveAccount(account);
  }

  if (event.eventType === EventType.LOGOUT_SUCCESS) {
    if (msalInstance.getAllAccounts().length > 0) {
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }
  }
});

// Define app roles
export const appRoles = {
  Admin: "",
};


export async function acquireToken() {
  try {
      const response = await msalInstance.acquireTokenSilent(loginRequest);
      return response.accessToken;
  } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
          const response = await msalInstance.acquireTokenPopup(loginRequest);
          return response.accessToken;
      } else if(error instanceof BrowserAuthError) {
          return null;
      } else {
          console.error(error);
      }
  }
}