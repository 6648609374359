import React, { useEffect, useMemo, useState } from "react";
import InputField from "../../components/formElement/InputField";
import { Button } from "primereact/button";
import "../../assets/css/pricing.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { insuranceData } from "../../Data/InsuranceList";
import { drugData } from "../../Data/DrugList";
import { Insurance, DataTableRowClickEvent } from "../../types/IHealthAppTypes";
import SortIcon from "../../assets/images/sort-iconSecound.svg";
import BackArrow from "../../assets/images/back-arrow.svg";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { FilterMatchMode } from "primereact/api";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import {
  getInsuranceCompanyList,
  getInventoryList,
  getSpecialPriceListWithInventoryAndInsurance,
  getSpecialPriceListWithInventoryAndInsuranceSecound,
  updateSpecialPrice,
} from "../../redux/store/PricingReducer";
import Loader from "../../components/loader/Loader";
import reset from "../../assets/images/refreshiconSecound.svg";
import pricing from "../../assets/images/pricing copy.svg";
import { log } from "console";
import { InputSwitch } from "primereact/inputswitch";
import edit from "../../assets/images/edit.svg";
import InputDateBlue from "../../components/formElement/InputDateBlue";
import { string } from "yup";
import InputDate from "../../components/formElement/InputDate";

const Pricing = () => {
  const [showTable, setShowTable] = useState<boolean>(true);
  const [pagination, setPagination] = useState<boolean>(false);
  const [selectedInsurance, setSelectedInsurance] = useState<string[]>([]);
  const [showInsuranceTable, setShowInsuranceTable] = useState<boolean>(true);
  const [firstTableRecords, setFirstTableRecords] = useState<
    { id: number; name: string }[]
  >([]);
  const [secondTableRecords, setSecondTableRecords] = useState<
    { id: number; name: string }[]
  >([]);
  const [toggleNumber, setToggleNumber] = useState<number>(1);
  const [isSeondTable, setIsSeondTable] = useState<boolean>(false);
  const [selectedNames, setSelectedNames] = useState<{
    first: any;
    second: any;
  }>({ first: undefined, second: undefined });
  const [clickedId, setClickedId] = useState(0);

  const [drugFilters, setDrugFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    inventory_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [insuranceFilters, setInsuranceFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    organization: { value: null, matchMode: FilterMatchMode.CONTAINS },
    insurance_company_name: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
  });
  const [drugFilterValue, setDrugFilterValue] = useState("");
  const [insuranceFilterValue, setInsuranceFilterValue] = useState("");
  const [insuranceId, setInsuranceId] = useState(0);
  const [inventoryId, setInventoryId] = useState(0);
  const [secondClickedId, setSecondClickedId] = useState(0);
  const [isClickDisabled, setIsClickDisabled] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
  const [checked, setChecked] = useState(false);
  const [invalidStartDateFields, setInvalidStartDateFields] = useState(false);
  const [invalidEndDateFields, setInvalidEndDateFields] = useState(false);

  const handleRowClick = (event: any) => {
    setIsSeondTable(true);
    setSecondTableRecords(toggleNumber === 1 ? inventory : companies);
    setSelectedNames({
      first: event.data,
      second: undefined,
    });
  };

  const onDrugFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...drugFilters };

    _filters["inventory_name"].value = value;
    _filters["global"].value = value;

    setDrugFilters(_filters);
    setDrugFilterValue(value);
  };

  const onInsuranceFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...insuranceFilters };

    toggleNumber === 1
      ? (_filters["organization"].value = value)
      : (_filters["insurance_company_name"].value = value);
    _filters["global"].value = value;
    setInsuranceFilters(_filters);
    setInsuranceFilterValue(value);
  };

  const handleSecondRowClick = (event: any) => {
    setSelectedNames((prev) => ({
      ...prev,
      second: event.data,
    }));
  };

  const resetButton = () => {
    setDrugFilterValue("");
    setInsuranceFilterValue("");

    setDrugFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      inventory_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    setInsuranceFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      organization: { value: null, matchMode: FilterMatchMode.CONTAINS },
      insurance_company_name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
    });
    setInventoryId(0);
    setInsuranceId(0);

    setIsSeondTable(false);

    setSelectedNames({ first: undefined, second: undefined });

    setToggleNumber(1);
    setShowTable(true);
    setClickedId(0);
    setIsClickDisabled(false);
    setSecondClickedId(0);
    setChecked(false);
    setFilteredCompanies([]);
    setInvalidStartDateFields(false);
    setInvalidEndDateFields(false);
  };

  const handleCloseDrugTable = () => {
    setInsuranceId(0);
    setInventoryId(0);
    setIsClickDisabled(false);
    setSecondTableRecords([]); // Reset second table records
    setSelectedNames({ first: undefined, second: undefined });
    setIsSeondTable(false);
    setSecondClickedId(0); // Reset the clicked ID
    // Reset IDs if needed
    toggleNumber === 1
      ? dispatch(
          getSpecialPriceListWithInventoryAndInsurance({
            insuranceId: insuranceId,
            inventoryId: 0,
          })
        )
      : dispatch(
          getSpecialPriceListWithInventoryAndInsurance({
            insuranceId: 0,
            inventoryId: inventoryId,
          })
        );

    toggleNumber === 1 ? setInventoryId(0) : setInsuranceId(0);

    // Enable clicking on the first table again
    setIsClickDisabled(false);

    setDrugFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      inventory_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    setInsuranceFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      organization: { value: null, matchMode: FilterMatchMode.CONTAINS },
      insurance_company_name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
    });
    setDrugFilterValue("");
    setInsuranceFilterValue("");
    setInvalidStartDateFields(false);
    setInvalidEndDateFields(false);
  };

  const handleToggleTable = () => {
    setShowInsuranceTable(!showInsuranceTable);
  };

  const {
    companies,
    loading: InsuranceLoading,
    error,
  } = useSelector((state: RootState) => state.pricingReducer);
  const { inventory, loader: DrugLoading } = useSelector(
    (state: RootState) => state.pricingReducer
  );
  const { specialPricesWithInsuranceAndInventory, loadingForSpecialPrices } =
    useSelector((state: RootState) => state.pricingReducer);
  const { specialPricesWithInsuranceAndInventorySecound } = useSelector(
    (state: RootState) => state.pricingReducer
  );
  const { specialPriceData } = useSelector(
    (state: RootState) => state.pricingReducer
  );

  const [isEditMode, setIsEditMode] = useState(false);
  const [startDate, setStartDate] = useState<string>(
    specialPricesWithInsuranceAndInventorySecound[0]?.start_date
  );
  const [endDate, setEndDate] = useState<string>(
    specialPricesWithInsuranceAndInventorySecound[0]?.end_date || 0
  );

  useEffect(() => {
    setStartDate(specialPricesWithInsuranceAndInventorySecound[0]?.start_date);
    setEndDate(specialPricesWithInsuranceAndInventorySecound[0]?.end_date);
  }, [specialPricesWithInsuranceAndInventorySecound]);

  useEffect(() => {
    if (toggleNumber === 1) {
      setFirstTableRecords(companies);
      setSecondTableRecords(inventory);
      setSelectedNames({
        first: selectedNames.second,
        second: selectedNames.first,
      });
    } else {
      setFirstTableRecords(inventory);
      setSecondTableRecords(companies);
      setSelectedNames({
        first: selectedNames.second,
        second: selectedNames.first,
      });
    }
  }, [toggleNumber]);

  useEffect(() => {
    if (startDate !== "") {
      setInvalidStartDateFields(false);
    }
  }, [startDate]);
  useEffect(() => {
    if (endDate !== "") {
      setInvalidEndDateFields(false);
    }
  }, [endDate]);

  const [first, setFirst] = useState<number>(0);
  const [rows, setRows] = useState<number>(10);

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getInsuranceCompanyList());
      await dispatch(getInventoryList());
    };

    fetchData();
  }, [dispatch]);

  const location = window.location.pathname;
  if (InsuranceLoading && DrugLoading) {
    return (
      <div>
        <Loader label="Pricing" location={location} path="/pricing" />
      </div>
    );
  }

  const handleCellClick = async (data: any) => {
    // if (isClickDisabled) return;  // Prevent action if clicks are disabled

    await dispatch(
      getSpecialPriceListWithInventoryAndInsurance({
        insuranceId: toggleNumber === 1 ? data.company_id : 0,
        inventoryId: toggleNumber === 1 ? 0 : data.inventory_id,
      })
    );
    setIsClickDisabled(true); // Disable further clicks
    toggleNumber === 1
      ? setDrugFilters({
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
          inventory_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        })
      : setInsuranceFilters({
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
          organization: { value: null, matchMode: FilterMatchMode.CONTAINS },
          insurance_company_name: {
            value: null,
            matchMode: FilterMatchMode.CONTAINS,
          },
        });

    toggleNumber === 1
      ? setInsuranceId(data.company_id)
      : setInventoryId(data.inventory_id);
    toggleNumber === 1
      ? setClickedId(data.company_id)
      : setClickedId(data.inventory_id);

    setSecondClickedId(0);
    toggleNumber === 1 ? setDrugFilterValue("") : setInsuranceFilterValue("");
    setStartDate("");
    setEndDate("");
    setInvalidStartDateFields(false);
    setInvalidEndDateFields(false);
  };

  const handleToggleClick = () => {
    const newToggleNumber = toggleNumber === 1 ? 2 : 1;
    setToggleNumber(newToggleNumber);

    if (newToggleNumber === 1) {
      dispatch(getInventoryList());
    } else {
      dispatch(getInsuranceCompanyList());
    }

    setInventoryId(0);
    setInsuranceId(0);

    setIsSeondTable(false);

    setSelectedNames({ first: undefined, second: undefined });
    setClickedId(0);
    setIsClickDisabled(false);

    setDrugFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      inventory_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    setInsuranceFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      organization: { value: null, matchMode: FilterMatchMode.CONTAINS },
      insurance_company_name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
    });
    setDrugFilterValue("");
    setInsuranceFilterValue("");
    setChecked(false);
    setStartDate("");
    setEndDate("");
    setInvalidStartDateFields(false);
    setInvalidEndDateFields(false);
  };

  const handleCellClickSecound = (data: any) => {
    toggleNumber === 1
      ? dispatch(
          getSpecialPriceListWithInventoryAndInsuranceSecound({
            insuranceId: insuranceId,
            inventoryId: data.inventory_id,
          })
        )
      : dispatch(
          getSpecialPriceListWithInventoryAndInsuranceSecound({
            insuranceId: data.insurance_company_id,
            inventoryId: inventoryId,
          })
        );
    toggleNumber === 1
      ? setInventoryId(data.inventory_id)
      : setInsuranceId(data.insurance_company_id);
    toggleNumber === 1
      ? setSecondClickedId(data.inventory_id)
      : setSecondClickedId(data.insurance_company_id);
    setIsEditMode(false);
    setStartDate(startDate);
    setEndDate(endDate);
    setInvalidStartDateFields(false);
    setInvalidEndDateFields(false);
  };

  const handlePricingClick = () => {
    if (filteredCompanies.length > 0) {
      setChecked(false);
      setFilteredCompanies([]);
      setToggleNumber(1);
      setIsSeondTable(false);
      setDrugFilterValue("");
      setDrugFilters({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        inventory_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      });
      setSecondClickedId(0);
      setClickedId(0);
      setIsClickDisabled(false);
      setSelectedNames({ first: undefined, second: undefined });
    } else {
      setChecked(true);
      const contractCompanies = companies.filter(
        (company) => company.special_price_matrix === "Contract"
      );
      setFilteredCompanies(contractCompanies);
      setIsSeondTable(false);
      setDrugFilterValue("");
      setDrugFilters({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        inventory_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      });
      setSecondClickedId(0);
      setClickedId(0);
      setIsClickDisabled(false);
      setSelectedNames({ first: undefined, second: undefined });
      setInvalidStartDateFields(false);
      setInvalidEndDateFields(false);
    }
  };

  const handlePricingSecondClick = () => {
    if (filteredCompanies.length > 0) {
      setChecked(false);
      setFilteredCompanies([]);
      setInsuranceFilters({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        organization: { value: null, matchMode: FilterMatchMode.CONTAINS },
        insurance_company_name: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      });
      setInsuranceFilterValue("");
      setSecondClickedId(0);
      setSelectedNames({ first: selectedNames.first, second: undefined });
      // console.log("selectedNames", selectedNames)
    } else {
      setChecked(true);
      const contractCompanies = specialPricesWithInsuranceAndInventory.filter(
        (special_priceing) => special_priceing.special_price !== 0
      );
      setFilteredCompanies(contractCompanies);
      setSecondClickedId(0);
      setSelectedNames({ first: selectedNames.first, second: undefined });
      // console.log("selectedNames", selectedNames)
    }
    setInvalidStartDateFields(false);
    setInvalidEndDateFields(false);
  };

  const handleEditClick = () => {
    setIsEditMode(true);
    setInvalidStartDateFields(false);
    setInvalidEndDateFields(false);
  };

  const handleSaveClick = async () => {
    // Reset invalid field states
    setInvalidStartDateFields(false);
    setInvalidEndDateFields(false);

    // Check for blank fields and set invalid states accordingly
    let hasError = false;
    if (!startDate || startDate.trim() === "") {
      setInvalidStartDateFields(true);
      hasError = true;
    }
    if (!endDate || endDate.trim() === "") {
      setInvalidEndDateFields(true);
      hasError = true;
    }

    // Prevent API call and keep isEditMode true if there's an error
    if (hasError) {
      setIsEditMode(true);
      return;
    }

    // Proceed with the API call if all fields are valid
    try {
      await dispatch(
        updateSpecialPrice({
          special_price_id:
            specialPricesWithInsuranceAndInventorySecound[0]?.special_price_id,
          start_date: startDate,
          end_date: endDate,
          created_by: "",
        })
      );
      setIsEditMode(false);
    } catch (error) {}
  };

  const handleCancelClick = () => {
    setStartDate(
      specialPricesWithInsuranceAndInventorySecound[0]?.start_date || 0
    );
    setEndDate(specialPricesWithInsuranceAndInventorySecound[0]?.end_date || 0);
    setIsEditMode(false);
    setInvalidStartDateFields(false);
    setInvalidEndDateFields(false);
  };

  const handleStartDateChange = (e: any) => {
    const date = e.value;

    if (!date) {
      setStartDate("");
      setInvalidStartDateFields(true);
      return;
    }

    // Convert the date to UTC format if a valid date is provided
    const utcDate = new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    ).toISOString();

    setStartDate(utcDate);
    setInvalidStartDateFields(false); // Reset invalid state
  };

  const handleEndDateChange = (e: any) => {
    const date = e.value;

    if (!date) {
      setEndDate("");
      setInvalidEndDateFields(true);
      return;
    }

    const utcDate = new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    ).toISOString();

    setEndDate(utcDate);
    setInvalidEndDateFields(false);
  };

  return (
    <div>
      {InsuranceLoading || DrugLoading ? (
        <Loader label="Pricing" location={location} path="/pricing" />
      ) : (
        <div className="body-content">
          <div className="table">
            {showTable ? (
              <div className="first-table">
                <DataTable
                  paginator
                  value={
                    toggleNumber === 1
                      ? filteredCompanies.length > 0
                        ? filteredCompanies
                        : companies
                      : inventory
                  }
                  rows={15}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  onRowClick={handleRowClick}
                  filters={toggleNumber === 1 ? insuranceFilters : drugFilters}
                  className="pricing-first-table"
                >
                  <Column
                    style={{ cursor: isClickDisabled ? "pointer" : "pointer" }}
                    field={`${
                      toggleNumber === 1 ? "organization" : "inventory_name"
                    }`}
                    key={`${
                      toggleNumber === 1 ? "company_id" : "inventory_id"
                    }`}
                    header={() => (
                      <>
                        <div className="first-table-head">
                          <span className="first-table-heading">
                            {toggleNumber === 1 ? "Insurance" : "Drug"}
                          </span>
                          <div className="table_icon">
                            {toggleNumber === 1 ? (
                              <InputSwitch
                                className="toggle"
                                checked={checked}
                                onChange={handlePricingClick}
                                style={{
                                  cursor: "pointer",
                                }}
                                tooltip={
                                  checked
                                    ? "Show all insurance options"
                                    : "Apply filter for special price insurance"
                                }
                                tooltipOptions={{ position: "bottom" }}
                              />
                            ) : (
                              <></>
                            )}
                            {/* <img onClick={resetButton} src={reset} alt="Reset Icon" title='Reset' /> */}
                            <Button
                              className="icon-button border-none p-0"
                              icon={() => (
                                <img
                                  src={reset}
                                  alt="Reset Icon"
                                  width={20}
                                  height={20}
                                />
                              )}
                              onClick={resetButton}
                              tooltip="Reset"
                              tooltipOptions={{ position: "bottom" }}
                            />
                            {/* <img onClick={handleToggleClick} src={SortIcon} alt="Sort Icon" title={toggleNumber === 1 ? 'Toggel to drug table' : 'Toggel to insurance table'} /> */}
                            <Button
                              className="icon-button border-none p-0"
                              icon={() => (
                                <img
                                  src={SortIcon}
                                  alt="Sort Icon"
                                  width={20}
                                  height={20}
                                />
                              )}
                              onClick={handleToggleClick}
                              tooltip={
                                toggleNumber === 1
                                  ? "Switch to the drug table"
                                  : "Switch to the insurance table"
                              }
                              tooltipOptions={{ position: "bottom" }}
                            />
                          </div>
                        </div>
                        {toggleNumber === 1 ? (
                          <InputField
                            placeholder="Search Insurance Name"
                            globalFilterValue={insuranceFilterValue}
                            onGlobalFilterChange={onInsuranceFilterChange}
                            id="search-insurance"
                            className="search-field inside-insurance"
                          />
                        ) : (
                          <InputField
                            placeholder="Search Drug Name"
                            globalFilterValue={drugFilterValue}
                            onGlobalFilterChange={onDrugFilterChange}
                            id="search-drug"
                            className="search-field inside-insurance"
                          />
                        )}
                      </>
                    )}
                    body={(data) => (
                      <div
                        onClick={() => handleCellClick(data)}
                        className={`clickable-cell hover-effect ${
                          isClickDisabled &&
                          clickedId ===
                            (toggleNumber === 1
                              ? data.company_id
                              : data.inventory_id)
                            ? "selected-white"
                            : "unselect-transparent"
                        }`}
                        style={{
                          // pointerEvents: isClickDisabled ? 'none' : 'auto',
                          cursor: isClickDisabled ? "pointer" : "pointer",
                        }}
                      >
                        {toggleNumber === 1
                          ? data.organization
                          : data.inventory_name}
                      </div>
                    )}
                    className="first-column"
                  />
                </DataTable>
              </div>
            ) : (
              <div className="apply-filter-pricing">
                <div className="h1-container">
                  <h1
                    style={{
                      fontSize: "40px",
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Apply the filter !!
                  </h1>
                </div>
                <div className="p-container">
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "20",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Once search criteria is entered, click on "Apply filter"
                  </p>
                </div>
              </div>
            )}

            {isSeondTable && (
              <div className="second-table">
                <DataTable
                  value={
                    toggleNumber === 1
                      ? specialPricesWithInsuranceAndInventory
                      : filteredCompanies.length > 0
                      ? filteredCompanies
                      : specialPricesWithInsuranceAndInventory
                  }
                  filters={toggleNumber === 1 ? drugFilters : insuranceFilters}
                  paginator
                  rows={15}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  onRowClick={handleSecondRowClick}
                  className="pricing-second-table"
                  loading={loadingForSpecialPrices}
                  loadingIcon={
                    <Loader
                      label={toggleNumber === 1 ? "Drug" : "Insurance"}
                      location={location}
                      path="/pricing"
                    />
                  }
                >
                  <Column
                    field={
                      toggleNumber === 1
                        ? "inventory_name"
                        : "insurance_company_name"
                    }
                    header={() => (
                      <>
                        <div className="second-table-head">
                          <div className="secound-table-buttons">
                            <Button
                              className="icon-button border-none p-0"
                              icon={() => (
                                <img
                                  src={BackArrow}
                                  alt="Back"
                                  width={20}
                                  height={20}
                                />
                              )}
                              outlined
                              onClick={handleCloseDrugTable}
                              tooltip="Back"
                              tooltipOptions={{
                                position: "bottom",
                              }}
                            />
                            {toggleNumber === 1 ? (
                              <></>
                            ) : (
                              <InputSwitch
                                className="toggle"
                                checked={checked}
                                onChange={handlePricingSecondClick}
                                style={{
                                  cursor: "pointer",
                                }}
                                tooltip={
                                  checked
                                    ? "Show all insurance options"
                                    : "Apply filter for special price insurance"
                                }
                                tooltipOptions={{ position: "bottom" }}
                              />
                            )}
                            {/* <input type="image" src={BackArrow} onClick={handleCloseDrugTable} width={20} height={20}/> */}
                          </div>
                          <span className="secound-table-heading">
                            {toggleNumber === 1 ? "Drug" : "Insurance"}
                          </span>
                        </div>
                        {toggleNumber === 1 ? (
                          <InputField
                            placeholder="Search Drug Name"
                            globalFilterValue={drugFilterValue}
                            onGlobalFilterChange={onDrugFilterChange}
                            id="search-drug"
                            className="search-field inside-insurance"
                          />
                        ) : (
                          <InputField
                            placeholder="Search Insurance Name"
                            globalFilterValue={insuranceFilterValue}
                            onGlobalFilterChange={onInsuranceFilterChange}
                            id="search-insurance"
                            className="search-field inside-insurance"
                          />
                        )}
                      </>
                    )}
                    body={(data) => {
                      const displayText =
                        toggleNumber === 1
                          ? data.inventory_name
                          : data.insurance_company_name;

                      return (
                        <div
                          onClick={() => handleCellClickSecound(data)}
                          className={`clickable-cell hover-effect ${
                            secondClickedId ===
                            (toggleNumber === 1
                              ? data.inventory_id
                              : data.insurance_company_id)
                              ? "selected-white"
                              : "unselect-transparent"
                          }`}
                        >
                          {toggleNumber === 1
                            ? data.inventory_name
                            : data.insurance_company_name}
                        </div>
                      );
                    }}
                    className="second-column"
                  />
                </DataTable>
              </div>
            )}
          </div>

          {selectedNames.first && selectedNames.second && (
            <div className="pricing-box">
              <div className="pricingHeader">
                <div className="mainHeading">
                  <h1>Pricing</h1>
                  <Button
                    onClick={handleEditClick}
                    className="icon-button border-none p-0"
                    icon={() => (
                      <img src={edit} alt="Edit Icon" width={20} height={20} />
                    )}
                    tooltip="Edit"
                    tooltipOptions={{ position: "bottom" }}
                  />
                </div>
                <div className="subHeading">
                  <div className="firstSubHeading">
                    {toggleNumber === 1 ? <p>Insurance</p> : <p>Drug</p>}
                    <textarea
                      className="title"
                      value={
                        toggleNumber === 1
                          ? selectedNames.first?.organization ||
                            "Insurance not available!"
                          : selectedNames.first?.inventory_name
                      }
                      readOnly
                      disabled
                    />
                  </div>
                  <div className="secoudSubHeading">
                    {toggleNumber !== 1 ? <p>Insurance</p> : <p>Drug</p>}
                    <textarea
                      className="title"
                      value={
                        toggleNumber === 1
                          ? selectedNames.second?.inventory_name ||
                            "Drugs not available!"
                          : selectedNames.second?.insurance_company_name
                      }
                      readOnly
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="pricingBody">
                <div className="PricingRow">
                  <p>Cost</p>
                  <div>
                    <input
                      className="custom-input cost"
                      value={`$ ${
                        specialPricesWithInsuranceAndInventorySecound[0]
                          ?.cost || 0
                      }`}
                      disabled
                    />
                  </div>
                </div>
                <div className="PricingRow">
                  <p>List</p>
                  <div>
                    <input
                      value={`$ ${
                        specialPricesWithInsuranceAndInventorySecound[0]
                          ?.list || 0
                      }`}
                      disabled
                    />
                  </div>
                </div>
                <div className="PricingRow">
                  <p>AWP</p>
                  <div>
                    <input
                      value={`$ ${
                        specialPricesWithInsuranceAndInventorySecound[0]?.awp ||
                        0
                      }`}
                      disabled
                    />
                  </div>
                </div>
                <div className="PricingRow">
                  <p>Special</p>
                  <div>
                    <input
                      value={`$ ${
                        specialPricesWithInsuranceAndInventorySecound[0]
                          ?.special || 0
                      }`}
                      disabled
                    />
                  </div>
                </div>
                <div className="PricingRow">
                  <p>Expected</p>
                  <div>
                    <input
                      value={`$ ${
                        specialPricesWithInsuranceAndInventorySecound[0]
                          ?.expected || 0
                      }`}
                      disabled
                    />
                  </div>
                </div>
                <div className="PricingRow">
                  <p>Billing Code</p>
                  <div>
                    <input
                      value={
                        specialPricesWithInsuranceAndInventorySecound[0]
                          ?.billinG_CODE
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="PricingRow">
                  <p>Start Date</p>
                  <div
                    className="date"
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <InputDate
                      id="StartDate"
                      value={startDate ? new Date(startDate) : undefined}
                      handleChange={handleStartDateChange}
                      placeholder="Select a date"
                      labelclassName="col-4 label-lg"
                      inputclassName={`col-5  ${
                        isEditMode ? "editfieldinactive" : "editfieldactive"
                      } `}
                      invalid={invalidStartDateFields}
                    />
                    {!isEditMode && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "transparent",
                          cursor: "not-allowed",
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="PricingRow">
                  <p>End Date</p>
                  <div
                    className="date"
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <InputDate
                      id="EndDate"
                      value={endDate ? new Date(endDate) : undefined}
                      handleChange={handleEndDateChange}
                      placeholder="Select a date"
                      labelclassName="col-4 label-lg"
                      inputclassName={`col-5  ${
                        isEditMode ? "editfieldinactive" : "editfieldactive"
                      } `}
                      invalid={invalidEndDateFields}
                    />
                    {!isEditMode && (
                      <div
                        className="dateDiv"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "tra",
                          cursor: "not-allowed",
                        }}
                      />
                    )}
                  </div>
                </div>
                {isEditMode && (
                  <div className="buttonGroup">
                    <Button onClick={handleCancelClick}>Cancel</Button>
                    <Button onClick={handleSaveClick}>Save</Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Pricing;
