// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/api/index";

const baseUrl = process.env.REACT_APP_BASEURL;

// GET Method For Biller list
export const getBillerList = createAsyncThunk(
  "arManager/getBillerList",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/ArManager/getBillerList`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Claim no status list
export const getClaimNoStatusList = createAsyncThunk(
  "arManager/getClaimNoStatusList",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/ArManager/getClaimNoStatusList`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Status list
export const getStatusList = createAsyncThunk(
  "arManager/getStatusList",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/ArManager/getStatusList`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Company list
export const getCompanyList = createAsyncThunk(
  "arManager/getCompanyList",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/ArManager/getCompanyList`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Payor list
export const getPayorList = createAsyncThunk(
  "arManager/getPayorList",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/ArManager/getPayorList`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Therapy type list
export const getTherapyTypeList = createAsyncThunk(
  "arManager/getTherapyTypeList",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/ArManager/getTherapyTypeList`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Drug category list
export const getDrugCategoryList = createAsyncThunk(
  "arManager/getDrugCategoryList",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/ArManager/getDrugCategoryList`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Patient Billing Subject
export const getPatientBillingSubject = createAsyncThunk(
  "arManager/GetPatientBillingSubject",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/ArManager/GetPatientBillingSubject`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For CPR User
export const getCprUser = createAsyncThunk("arManager/GetCprUser", async () => {
  try {
    const endpoint = `${baseUrl}/api/ArManager/GetCprUser`;
    const response = await axios.get(endpoint);
    if (!response.statusText) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    const data = await response.data;
    return data;
  } catch (error) {
    console.error("Error fetching user details", error);
  }
});

// GET Method For Patient Invoice Id
export const getPatientInvoicesID = createAsyncThunk(
  "arManager/GetPatientInvoicesID",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/ArManager/GetPatientInvoicesID`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Parent Line Items
export const getLineItemParent = createAsyncThunk(
  "arManager/GetLineItemParent",
  async (
    { Invoices_no, pk_claims }: { Invoices_no: any; pk_claims: any },
    apiThunk
  ) => {
    try {
      // Construct query parameters from the function arguments
      const endpoint = `${baseUrl}/api/ArManager/GetLineItemParent?Invoices_no=${encodeURIComponent(
        Invoices_no
      )}&pk_claims=${encodeURIComponent(pk_claims)}`;

      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching line item parent", error);
      throw error; // Throw the error to be caught by redux-thunk's rejected action handler
    }
  }
);


// GET Method For Child Line Items
export const getLineItemChild = createAsyncThunk(
  "arManager/getLineItemChild",
  async (
    { Invoices_no, pk_claims }: { Invoices_no: any; pk_claims: any },
    apiThunk
  ) => {
    try {
      // Construct query parameters from the function arguments
      const endpoint = `${baseUrl}/api/ArManager/getLineItemChild?Invoices_no=${encodeURIComponent(
        Invoices_no
      )}&pk_claims=${encodeURIComponent(pk_claims)}`;

      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching line item parent", error);
      throw error; // Throw the error to be caught by redux-thunk's rejected action handler
    }
  }
);

// GET Method For Progress Notes
export const getPatientNotes = createAsyncThunk(
  "Patient/getPatientNotes",
  async (
    { mrn }: { mrn: any; },
    apiThunk
  ) => {
    try {
      // Construct query parameters from the function arguments
      const endpoint = `${baseUrl}/api/Patient/GetPatientNotes/${mrn}`;

      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data.data;
      return data;
    } catch (error) {
      console.error("Error fetching line item parent", error);
      throw error; // Throw the error to be caught by redux-thunk's rejected action handler
    }
  }
);

// POST Method For Billing Notes
export const getPatientBillingNotes = createAsyncThunk(
  "ArManager/getPatientBillingNotes",
  async (
    { mrn }: { mrn: any; },
    apiThunk
  ) => {
    try {
      // Construct query parameters from the function arguments
      const endpoint = `${baseUrl}/api/ArManager/GetPatientBillingNotes?mrn=${mrn}`;

      const response = await axios.post(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data.data;
      return data;
    } catch (error) {
      console.error("Error fetching line item parent", error);
      throw error; // Throw the error to be caught by redux-thunk's rejected action handler
    }
  }
);

// POST Method For Transaction History
export const getPatientClaimTransactionHistory = createAsyncThunk(
  "ArManager/GetPatientClaimTransactionHistory",
  async (
    { cpk_claims }: { cpk_claims: any; },
    apiThunk
  ) => {
    try {
      // Construct query parameters from the function arguments
      const endpoint = `${baseUrl}/api/ArManager/GetPatientClaimTransactionHistory?cpk_claims=${cpk_claims}`;

      const response = await axios.post(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data.data;
      return data;
    } catch (error) {
      console.error("Error fetching line item parent", error);
      throw error; // Throw the error to be caught by redux-thunk's rejected action handler
    }
  }
);

// GET Method For Demographic Data
export const getPatientDemographicDetails = createAsyncThunk(
  "PhysicianPortal/GetPatientDemographicDetails",
  async ({ mrn }: { mrn: any }, apiThunk) => {
    try {
      // Construct query parameters from the function arguments
      const endpoint = `${baseUrl}/api/ArManager/GetPatientDemographicDetails/${mrn}`;

      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      //   if (callback) {
      //     callback();
      //   }
      return data;
    } catch (error) {
      console.error("Error fetching line item parent", error);
      throw error; // Throw the error to be caught by redux-thunk's rejected action handler
    }
  }
);

// GET Method For Red Form Data
export const getRedFormDetails = createAsyncThunk(
  "arManager/GetRedFormDetails",
  async (claim_id: any, apiThunk) => {
    try {
      // Construct query parameters from the function arguments
      const endpoint = `${baseUrl}/api/ArManager/GetRedFormDetails/${claim_id}`;

      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching line item parent", error);
      throw error; // Throw the error to be caught by redux-thunk's rejected action handler
    }
  }
);

// GET Method For Black and White Form Data
export const getBlackAndWhiteFormDetails = createAsyncThunk(
  "arManager/GetBlackAndWhiteFormDetails",
  async (claim_id: any, apiThunk) => {
    try {
      // Construct query parameters from the function arguments
      const endpoint = `${baseUrl}/api/ArManager/GetBlackAndWhiteFormDetails/${claim_id}`;

      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching line item parent", error);
      throw error; // Throw the error to be caught by redux-thunk's rejected action handler
    }
  }
);

// POST Method For Drug category list
export const updatePatientClaimsStatus = createAsyncThunk(
  "arManager/updatePatientClaimsStatus",
  async (
    { updateData, callback }: { updateData: any; callback: any },
    apiThunk
  ) => {
    console.log(updateData, "updateData");

    try {
      const endpoint = `${baseUrl}/api/ArManager/UpdatePatientClaimsStatus`;
      const response = await axios.post(endpoint, updateData);
      if (callback && response.data.success) {
        callback(response);
      }
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const UpdateArManagerBiller = createAsyncThunk(
  "arManager/UpdateArManagerBiller",
  async ({ updateData, callback }: { updateData: any; callback: any }, apiThunk) => {

    try {
      const endpoint = `${baseUrl}/api/ArManager/UpdateArManagerBiller`;
      const response = await axios.post(endpoint, updateData);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      if (callback && response.data.success) {
        callback(response);
      }
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const addPatientBillingNote = createAsyncThunk(
  "arManager/AddPatientBillingNote",
  async ({ updateData, callback }: { updateData: any; callback: any }, apiThunk) => {

    try {
      const endpoint = `${baseUrl}/api/ArManager/AddPatientBillingNote`;
      const response = await axios.post(endpoint, updateData);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      if (callback && response.data.success) {
        callback(response);
      }
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// POST Method For get filter ArManager data
export const getArManagerData = createAsyncThunk(
  "arManager/getArManagerData",
  async ({ updateData }: { updateData: any }, apiThunk) => {
    try {
      const endpoint = `${baseUrl}/api/ArManager/getArManagerData`;
      const response = await axios.post(endpoint, updateData, {
        headers: {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
        },
      });

      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      return response.data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const GetCustomFilterList = createAsyncThunk(
  "arManager/GetCustomFilterList",
  async ({ updateData }: { updateData: any }, apiThunk) => {
    try {
      const endpoint = `${baseUrl}/api/ArManager/GetCustomFilterList`;
      const response = await axios.post(endpoint, updateData, {
        headers: {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
        },
      });

      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      return response.data;

      // return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const AddNewCustomFilter = createAsyncThunk(
  "arManager/AddNewCustomFilter",
  async (updateData: any, apiThunk) => {
    console.log(updateData, "updateData");

    try {
      const endpoint = `${baseUrl}/api/ArManager/AddNewCustomFilter`;
      const response = await axios.post(endpoint, updateData);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);


// POST Method For Update Notes
export const updateNotes = createAsyncThunk(
  "arManager/UpdateNotes",
  async (
    {updateData, callback }: { updateData: any; callback: any },
    apiThunk
  ) => {

    try {
      const endpoint = `${baseUrl}/api/ArManager/updateNotes`;
      const response = await axios.post(endpoint, updateData);
      if (callback && response.data.success) {
        callback(response);
      }
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// POST Method For get filter ArManager data
export const getArManagerPatientCount = createAsyncThunk(
  "arManager/getArManagerPatientCount",
  async ({ updateData }: { updateData: any }, apiThunk) => {
    try {
      const endpoint = `${baseUrl}/api/ArManager/getArManagerPatientCount`;
      const response = await axios.post(endpoint, updateData, {
        headers: {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
        },
      });

      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      return response.data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const getArManagerPayorCount = createAsyncThunk(
  "arManager/getArManagerPayorCount",
  async ({ updateData }: { updateData: any }, apiThunk) => {
    try {
      const endpoint = `${baseUrl}/api/ArManager/getArManagerPayorCount`;
      const response = await axios.post(endpoint, updateData, {
        headers: {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
        },
      });

      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      return response.data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const getBillingNoteTemplate = createAsyncThunk(
  "arManager/getBillingNoteTemplate",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/ArManager/GetBillingNoteTemplate`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);


// ArManager Slice
export const arManagerSlice = createSlice({
  name: "arManager",
  initialState: {
    billerList: [],
    claimNoStatusList: [],
    statusList: [],
    companyList: [],
    payorList: [],
    therapyType: [],
    drugCategoryList: [],
    arManagerData: [],
    customFilterList: [],
    cprUser: [],
    patientBillingSubject: [],
    payorCountData: [],
    patientCountData: [],
    patientInvoiceId: [],
    lineItemParent: [],
    lineItemChild: [],
    patientNotes: [],
    billingNotes: [],
    transactionHistory: [],
    patientDemographicDetails: {
      mrn: "",
      first_name: "",
      last_name: "",
      full_name: "",
      company: "",
      pat_phone: "",
      pat_work_phone: "",
      pat_cell_phone: "",
      patient_martial_status: "",
      patient_language: "",
      date_of_birth: "",
      patient_status: "",
      gender: "",
      site: "",
      pat_email: "",
      pat_address: "",
      pat_city: "",
      pat_state: "",
      pat_zip: "",
      primary_rn: "",
      insurance_coordinator: "",
      sales_rep: "",
      patient_team: "",
      start_of_care: "",
    },
    redFormDetails: [],
    blackAndWhiteFormDetails: [],
    billingNoteTemplate: [],
    errorMessage: "",
    loading: false,
    arManagerloading: false,
    filterLoading: false,
    claimLineLoading: false,
    PdfLoading: false,
    filterloading: false,
    progressNoteLoading: false,
    billingNoteLoading: false,
    historyLoading: false,
  },
  reducers: {
    resetFilter: (state) => {
      state.arManagerData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBillerList.pending, (state, action) => {
        state.filterLoading = true;
      })
      .addCase(getBillerList.fulfilled, (state, action) => {
        state.billerList = action.payload?.data;
        state.filterLoading = false;
      })
      .addCase(getBillerList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.filterLoading = false;
      })
      .addCase(getClaimNoStatusList.pending, (state, action) => {
        state.filterLoading = true;
      })
      .addCase(getClaimNoStatusList.fulfilled, (state, action) => {
        state.claimNoStatusList = action.payload?.data;
        state.filterLoading = false;
      })
      .addCase(getClaimNoStatusList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.filterLoading = false;
      })
      .addCase(getArManagerData.pending, (state, action) => {
        state.arManagerloading = true;
      })
      .addCase(getArManagerData.fulfilled, (state, action) => {
        state.arManagerData = action.payload?.data;
        state.arManagerloading = false;
      })
      .addCase(getArManagerData.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.arManagerloading = false;
      })
      .addCase(GetCustomFilterList.pending, (state, action) => {
        state.filterloading = true;
      })
      .addCase(GetCustomFilterList.fulfilled, (state, action) => {
        state.customFilterList = action.payload?.data;
        state.filterloading = false;
      })
      .addCase(GetCustomFilterList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.filterloading = false;
      })
      .addCase(getPatientInvoicesID.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPatientInvoicesID.fulfilled, (state, action) => {
        state.patientInvoiceId = action.payload?.data;
        state.loading = false;
      })
      .addCase(getPatientInvoicesID.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getDrugCategoryList.pending, (state, action) => {
        state.filterLoading = true;
      })
      .addCase(getDrugCategoryList.fulfilled, (state, action) => {
        state.drugCategoryList = action.payload?.data;
        state.filterLoading = false;
      })
      .addCase(getDrugCategoryList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.filterLoading = false;
      })
      .addCase(getPatientBillingSubject.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPatientBillingSubject.fulfilled, (state, action) => {
        state.patientBillingSubject = action.payload?.data;
        state.loading = false;
      })
      .addCase(getPatientBillingSubject.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getCprUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCprUser.fulfilled, (state, action) => {
        state.cprUser = action.payload?.data;
        state.loading = false;
      })
      .addCase(getCprUser.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getTherapyTypeList.pending, (state, action) => {
        state.filterLoading = true;
      })
      .addCase(getTherapyTypeList.fulfilled, (state, action) => {
        state.therapyType = action.payload?.data;
        state.filterLoading = false;
      })
      .addCase(getTherapyTypeList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.filterLoading = false;
      })
      .addCase(getPayorList.pending, (state, action) => {
        state.filterLoading = true;
      })
      .addCase(getPayorList.fulfilled, (state, action) => {
        state.payorList = action.payload?.data;
        state.filterLoading = false;
      })
      .addCase(getPayorList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.filterLoading = false;
      })
      .addCase(getStatusList.pending, (state, action) => {
        state.filterLoading = true;
      })
      .addCase(getStatusList.fulfilled, (state, action) => {
        state.statusList = action.payload?.data;
        state.filterLoading = false;
      })
      .addCase(getStatusList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.filterLoading = false;
      })
      .addCase(getCompanyList.pending, (state, action) => {
        state.filterLoading = true;
      })
      .addCase(getCompanyList.fulfilled, (state, action) => {
        state.companyList = action.payload?.data;
        state.filterLoading = false;
      })
      .addCase(getCompanyList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.filterLoading = false;
      })
      .addCase(getLineItemParent.pending, (state, action) => {
        state.claimLineLoading = true;
      })
      .addCase(getLineItemParent.fulfilled, (state, action) => {
        state.lineItemParent = action.payload?.data;
        state.claimLineLoading = false;
      })
      .addCase(getLineItemParent.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.claimLineLoading = false;
      })
      .addCase(getLineItemChild.pending, (state, action) => {
        state.claimLineLoading = true;
      })
      .addCase(getLineItemChild.fulfilled, (state, action) => {
        state.lineItemChild = action.payload?.data;
        state.claimLineLoading = false;
      })
      .addCase(getLineItemChild.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.claimLineLoading = false;
      })
      .addCase(getPatientNotes.pending, (state, action) => {
        state.progressNoteLoading = true;
      })
      .addCase(getPatientNotes.fulfilled, (state, action) => {
        state.patientNotes = action.payload;
        state.progressNoteLoading = false;
      })
      .addCase(getPatientNotes.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.progressNoteLoading = false;
      })
      .addCase(getPatientBillingNotes.pending, (state, action) => {
        state.billingNoteLoading = true;
      })
      .addCase(getPatientBillingNotes.fulfilled, (state, action) => {
        state.billingNotes = action.payload;
        state.billingNoteLoading = false;
      })
      .addCase(getPatientBillingNotes.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.billingNoteLoading = false;
      })
      .addCase(getPatientClaimTransactionHistory.pending, (state, action) => {
        state.historyLoading = true;
      })
      .addCase(getPatientClaimTransactionHistory.fulfilled, (state, action) => {
        state.transactionHistory = action.payload;
        state.historyLoading = false;
      })
      .addCase(getPatientClaimTransactionHistory.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.historyLoading = false;
      })
      .addCase(getPatientDemographicDetails.pending, (state, action) => {
        state.PdfLoading = true;
      })
      .addCase(getPatientDemographicDetails.fulfilled, (state, action) => {
        state.patientDemographicDetails = action.payload?.data?.[0];
        state.PdfLoading = false;
      })
      .addCase(getPatientDemographicDetails.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.PdfLoading = false;
      })
      .addCase(getRedFormDetails.pending, (state, action) => {
        state.PdfLoading = true;
      })
      .addCase(getRedFormDetails.fulfilled, (state, action) => {
        state.redFormDetails = action.payload?.data;
        state.PdfLoading = false;
      })
      .addCase(getRedFormDetails.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.PdfLoading = false;
      })
      .addCase(getBlackAndWhiteFormDetails.pending, (state, action) => {
        state.PdfLoading = true;
      })
      .addCase(getBlackAndWhiteFormDetails.fulfilled, (state, action) => {
        state.blackAndWhiteFormDetails = action.payload?.data;
        state.PdfLoading = false;
      })
      .addCase(getBlackAndWhiteFormDetails.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.PdfLoading = false;
      })
      .addCase(getArManagerPatientCount.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getArManagerPatientCount.fulfilled, (state, action) => {
        state.patientCountData = action.payload?.data;
        state.loading = false;
      })
      .addCase(getArManagerPatientCount.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getArManagerPayorCount.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getArManagerPayorCount.fulfilled, (state, action) => {
        state.payorCountData = action.payload?.data;
        state.loading = false;
      })
      .addCase(getArManagerPayorCount.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getBillingNoteTemplate.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBillingNoteTemplate.fulfilled, (state, action) => {
        state.billingNoteTemplate = action.payload?.data;
        state.loading = false;
      })
      .addCase(getBillingNoteTemplate.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      });
  },
});

export const { resetFilter } = arManagerSlice.actions;
export default arManagerSlice.reducer;
