import React, { useEffect, useState } from "react";
import ShipmentFilter from "../../components/Filter/ShipmentFilter/ShipmentFilter";
import ShipmentDataTable from "../../components/DataTable/ShipmentDataTable";
import { getShipmentData } from "../../redux/store/ShipmentReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import Loader from "../../components/loader/Loader";
import { FilterMatchMode } from "primereact/api";
import { DataTableFilterMeta } from "primereact/datatable";
import ShipmentDocument from "../document/ShipmentDocumentview";


const Shipment = () => {
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const { item, loading } = useSelector(
    (state: RootState) => state.shipmentReducer
  );

  const dispatch = useDispatch<AppDispatch>();
  const location = window.location.pathname;
  useEffect(() => {
    const actions = [getShipmentData()];

    actions.forEach((action) => dispatch(action));
  }, [dispatch]);


//status dropdown code
  const statusData = Array.from(
    new Set(item.map((shipment: any) => (shipment.status)))
  ).map((status) => ({
    label: status,
    value: status,
  }));

//RXType dropdown code
const RxtypeData = Array.from(
  new Set(item.map((shipment: any) => (shipment.rx_type)))
).map((rx_type) => ({
  label: rx_type,
  value: rx_type,
}));

//ShipMethod dropdown code
const shipmethodData = Array.from(
  new Set(item.map((shipment: any) => (shipment.shipping_method)))
).map((shipping_method) => ({
  label: shipping_method,
  value: shipping_method,
}));




//Team dropdown code
const TeamData = Array.from(
  new Set(item.map((shipment: any) => (shipment.team)))
).map((team) => ({
  label: team,
  value: team,
}));



  return (
    <>
      <div>
        <ShipmentFilter
          filters={filters}
          setFilters={setFilters}
          statusData={statusData}
          rxtype={RxtypeData}
          shipmethod={shipmethodData}
          team={TeamData}
          alldata={item}
        />
      </div>
      <div>
        {loading ? (
          <Loader location={location} path="/shipment" label="Shipment" />
        ) : (
          <div className= "Shipment-table">
          <ShipmentDataTable
            data={item}
            filters={filters}
            name={undefined}
            search_string={""}

          />
          </div>
        )}
      </div>
     
    </>
  );
};

export default Shipment;
