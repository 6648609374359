import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

interface NonInventoryDialogBoxProps {
  noninventoryHeader: string;
  noninventoryVisible: boolean;
  onHide: () => void;
}

const AddNonInvontery: React.FC<NonInventoryDialogBoxProps> = ({
  noninventoryHeader,
  noninventoryVisible,
  onHide,
}) => {
  return (
    <>
      <Dialog
        className=" inventory-form-control Add-noninventory-dialog"
        header={noninventoryHeader}
        visible={noninventoryVisible}
        onHide={onHide}
        style={{width:"40vw"}}
        draggable={false} 
      >
        <p className="nonInventoryText">
          This item is a non-inventory billable item, therefore it has no
          quantities to change
        </p>

        <div className="flex align-items-end justify-content-end">
          <Button
            className="inventory-button-secondary noninventory "
            label="close"
            onClick={onHide}
            style={{"color":"black"}}
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddNonInvontery;