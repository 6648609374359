import React from "react";
import { Outlet, useLocation } from "react-router-dom";

const Main = (): React.ReactElement => {

    const location = useLocation();
    return (
        <>
            <div className={`${location.pathname === '/access-denied' ? 'p-0 h-screen' : ''} content`}>
                <Outlet />
            </div>
        </>
    );
}

export default Main;