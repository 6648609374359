import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface StateListType {
  rank: number;
  contract_link: string;
  state_name: string[];
  state_id: string[];
  isEditing: boolean;
}

const AddContractLinkDialogBox = ({
  setVisible,
  visible,
  contractLink,
  setContractLink,
}: {
  visible: boolean;
  contractLink: any;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setContractLink: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const { stateListInsuranceListing } = useSelector(
    (state: RootState) => state.insuranceReducer
  );

  const [linkData, setLinkData] = useState<StateListType[]>([]);
  const [nextId, setNextId] = useState(1);
  const [originalValues, setOriginalValues] = useState<{
    [key: number]: StateListType;
  }>({});
  const [errorMessage, setErrorMessage] = useState<any>({});

  useEffect(() => {
    if (visible) {
      setLinkData(contractLink);
      const lastObject = contractLink[contractLink.length - 1];
      const rank = lastObject?.rank;
      setNextId(rank + 1 || 1);
    }
  }, [contractLink, visible]);

  const handleLinkChange = (index: number, value: string) => {
    setErrorMessage((prevErrors: any) => {
      const newErrors = { ...prevErrors };
      delete newErrors[`contract_link-${index}`];
      return newErrors;
    });
    setLinkData((prev) =>
      prev.map((row, idx) =>
        idx === index ? { ...row, contract_link: value } : row
      )
    );
  };

  const handleStateChange = (index: number, value: string[]) => {
    setErrorMessage((prevErrors: any) => {
      const newErrors = { ...prevErrors };
      delete newErrors[`state_id-${index}`];
      return newErrors;
    });

    setLinkData((prev) =>
      prev.map((row, idx) =>
        idx === index ? { ...row, state_id: value } : row
      )
    );
  };

  const validateFields = (data: StateListType[]) => {
    let errors: any = {};

    data.forEach((row, index) => {
      if (row.contract_link?.trim() === "") {
        errors[`contract_link-${index}`] = "Contract link cannot be empty!";
      }
      if (row.state_id?.length === 0) {
        errors[`state_id-${index}`] = "At least one state should be selected!";
      }
    });

    return errors;
  };

  const validateRow = (rowData: StateListType, rowIndex: number) => {
    let rowErrors: any = {};

    if (rowData.contract_link?.trim() === "") {
      rowErrors[`contract_link-${rowIndex}`] = "Contract link cannot be empty!";
    }

    if (rowData.state_id?.length === 0) {
      rowErrors[`state_id-${rowIndex}`] =
        "At least one state should be selected!";
    }

    return rowErrors;
  };

  const actionEditor = (
    rowData: StateListType,
    { rowIndex }: { rowIndex: number }
  ) => {
    return (
      <div className={`flex gap-2 ${rowData.isEditing && "h-3rem mb-2"}`}>
        {rowData.isEditing ? (
          <>
            <Button
              onClick={() => saveRow(rowIndex)}
              icon="pi pi-check"
              className="icon-only"
            />
            <Button
              onClick={() => cancelRow(rowIndex)}
              icon="pi pi-times"
              className="icon-only"
            />
          </>
        ) : (
          <Button
            onClick={() => editRow(rowIndex)}
            icon="pi pi-pencil"
            className="icon-only"
          />
        )}
        <Button
          onClick={() => deleteRow(rowData.rank, rowIndex)}
          icon="pi pi-trash"
          className="icon-only"
        />
      </div>
    );
  };

  const stateBody = (
    rowData: StateListType,
    { rowIndex }: { rowIndex: number }
  ) =>
    rowData.isEditing ? (
      <div className="h-3rem">
        <MultiSelect
          value={rowData.state_id}
          maxSelectedLabels={3}
          filter
          options={stateListInsuranceListing.map((option: any) => ({
            label: option.state_code,
            value: option.state_id,
          }))}
          onChange={(e) => handleStateChange(rowIndex, e.value)}
          placeholder="Select State"
          optionLabel="label"
          invalid={!!errorMessage[`state_id-${rowIndex}`]}
        />
        {errorMessage[`state_id-${rowIndex}`] && (
          <div>
            <span className="text-red-500 text-xs">
              {errorMessage[`state_id-${rowIndex}`]}
            </span>
          </div>
        )}
      </div>
    ) : (
      <span>
        {rowData?.state_id
          ?.map((stateId) => {
            const state = stateListInsuranceListing.find(
              (option: any) => option.state_id === stateId
            );
            return state ? state.state_code : "";
          })
          .join(", ")}
      </span>
    );

  const linkBody = (
    rowData: StateListType,
    { rowIndex }: { rowIndex: number }
  ) =>
    rowData.isEditing ? (
      <div className="h-3rem">
        <InputText
          value={rowData.contract_link}
          style={{
            maxWidth: "100%",
            overflow: "auto",
          }}
          className="p-8px"
          invalid={!!errorMessage[`contract_link-${rowIndex}`]}
          onChange={(e) => handleLinkChange(rowIndex, e.target.value)}
        />
        {errorMessage[`contract_link-${rowIndex}`] && (
          <div>
            <span className="text-red-500 text-xs">
              {errorMessage[`contract_link-${rowIndex}`]}
            </span>
          </div>
        )}
      </div>
    ) : (
      <span>{rowData.contract_link}</span>
    );

  const addEmptyRow = () => {
    const newRow: StateListType = {
      rank: nextId,
      contract_link: "",
      state_name: [],
      state_id: [],
      isEditing: true,
    };

    setLinkData((prev) => [...prev, newRow]);
    setNextId(nextId + 1);
  };

  const deleteRow = (id: number, index: number) => {
    setErrorMessage((prevErrors: any) => {
      const newErrors = { ...prevErrors };
      delete newErrors[`contract_link-${index}`];
      return newErrors;
    });
    setErrorMessage((prevErrors: any) => {
      const newErrors = { ...prevErrors };
      delete newErrors[`state_id-${index}`];
      return newErrors;
    });
    setLinkData((prev) => prev.filter((row) => row.rank !== id));
    setOriginalValues((prev) => {
      const { [id]: _, ...rest } = prev;
      return rest;
    });
  };

  const editRow = (index: number) => {
    setOriginalValues((prev) => {
      const rowToEdit = linkData[index];
      return {
        ...prev,
        [rowToEdit.rank]: { ...rowToEdit },
      };
    });

    setLinkData((prev) =>
      prev.map((row, idx) =>
        idx === index ? { ...row, isEditing: true } : row
      )
    );
  };

  const saveRow = (index: number) => {
    const rowData = linkData[index];
    const rowErrors = validateRow(rowData, index);
    if (Object.keys(rowErrors).length > 0) {
      setErrorMessage((prevErrors: any) => ({
        ...prevErrors,
        ...rowErrors,
      }));
      return;
    }

    setLinkData((prev) =>
      prev.map((row, idx) =>
        idx === index ? { ...row, isEditing: false } : row
      )
    );
  };

  const handleSaveLink = () => {
    const validationErrors = validateFields(linkData);

    if (Object.keys(validationErrors).length > 0) {
      setErrorMessage(validationErrors);
      return;
    }

    const aggregatedData = linkData.map(
      ({ rank, state_id, contract_link }) => ({
        rank,
        state_id: state_id,
        contract_link: contract_link,
      })
    );
    setContractLink(aggregatedData);
    setVisible(false);
  };

  const cancelRow = (index: number) => {
    const rowData = linkData[index];
    const rowErrors = validateRow(rowData, index);
    if (Object.keys(rowErrors).length > 0) {
      setErrorMessage((prevErrors: any) => ({
        ...prevErrors,
        ...rowErrors,
      }));
      return;
    }
    if (
      !rowData.contract_link ||
      rowData.state_name.length === 0 ||
      rowData.state_id.length === 0
    ) {
      setLinkData((prev) => prev.filter((_, idx) => idx !== index));
      return;
    }
    setLinkData((prev) =>
      prev.map((row, idx) =>
        idx === index
          ? {
              ...originalValues[row.rank],
              isEditing: false,
            }
          : row
      )
    );
  };

  const columns = [
    {
      field: "contract_link",
      header: "Link",
      body: linkBody,
      style: { width: "65%" },
    },
    {
      field: "state_name",
      header: "State",
      body: stateBody,
      style: { width: "25%" },
    },
    {
      field: "actions",
      header: "Actions",
      body: actionEditor,
      style: { width: "10%" },
    },
  ];

  const Header = (
    <div className="contract-label">Add Contract Link and State</div>
  );

  const Footer = (
    <div className="buttons flex gap-2 justify-content-end">
      <Button
        label="Cancel"
        outlined
        onClick={() => {
          setVisible(false);
          setErrorMessage({});
        }}
      />
      <Button label="Save" onClick={handleSaveLink} />
    </div>
  );

  return (
    <Dialog
      header={Header}
      footer={Footer}
      draggable={false}
      visible={visible}
      style={{ width: "60vw", height: "50vh" }}
      className="contractLink-dialog"
      onHide={() => {
        setVisible(false);
        setErrorMessage({});
      }}
    >
      <DataTable value={linkData} tableStyle={{ minWidth: "50rem" }}>
        {columns.map((column, index) => (
          <Column
            key={index}
            field={column.field}
            header={column.header}
            body={column.body}
            style={column.style}
          />
        ))}
      </DataTable>
      <div className="w-full flex justify-content-center mt-3">
        <Button label="Add Contract Link" outlined onClick={addEmptyRow} />
      </div>
    </Dialog>
  );
};

export default AddContractLinkDialogBox;
