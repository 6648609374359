// import React, { createContext,useEffect, useState } from "react";
// import Sidebar from "./layouts/Sidebar/Sidebar";
// import Header from "./layouts/Header/Header";
// import { ScrollTop } from "primereact/scrolltop";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
// } from "react-router-dom";
// import { Provider } from "react-redux";
// import { useMsal, useMsalAuthentication } from "@azure/msal-react";
// import { InteractionType } from "@azure/msal-browser";
// import Main from "./router/Router";
// import { store } from "./redux/store";
// import Insurance from "./pages/Insurance/Insurance";
// import ARManager from "./pages/ARManager/ARManager";
// import Home from "./pages/Home/Home";
// import { scrollTop } from "./assets/images/Icon";
// import TaskList from "./pages/TaskList/TaskList";
// import Pricing from "./pages/Pricing/Pricing";
// import FaxDirectory from "./pages/FaxDirectory/FaxDirectory";
// import Inventory from "./pages/Inventory/Inventory";
// import FaxScreen from "./pages/Fax/FaxScreen";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'primeicons/primeicons.css';
// import JunkFaxScreen from "./pages/junk-fax/JunkFaxesScreen";
// import ArchiveFax from "./pages/archive/ArchiveFax";
// import UserDocument from "./pages/document/UserDocument";

// function Layout({ userEmail, name }: { userEmail?: any, name?: any }) {


//   return (
//     <>

//       <Header name={name} />
//       {/* <Container className="custom-container"> */}
//       <div className="sidebar">
//         <Sidebar userEmail={userEmail} />
//       </div>
//       <div className="main-content">
//         <Main />
//       </div>
//       <ScrollTop threshold={100} style={{ backgroundColor: "#1B3D6D" }} className="w-2rem h-2rem border-round-md" icon={() => <img src={scrollTop} alt="ScrollTop" />} />
//       {/* </Container> */}
//     </>
//   );
// }

// const App: React.FC = () => {

//   useMsalAuthentication(InteractionType.Redirect);
//   const [m_strUser, setm_strUser] = useState<string | undefined>("");
//   const [name, setName] = useState<string | undefined>("");
//   // const [loading, setLoading] = useState(true);
//   const { accounts } = useMsal();
//   // const [showSpinner, setShowSpinner] = useState(true);


//   function Render() {
//     const { accounts } = useMsal();

//     try {
//       setm_strUser(accounts[0].username);
//       setName(accounts[0].name);
//     } catch (e) { }
//   }

//   useEffect(() => {
//     if (accounts.length > 0) {
//       setm_strUser(accounts[0].username);
//       setName(accounts[0].name);
//       // setLoading(false);
//       // setTimeout(() => {
//       //   setShowSpinner(false);
//       // }, 5000);
//     }
//   }, [accounts]);
//   // if (loading || showSpinner) {
//   //   return <><ComponentSpinner /></>;
//   // }


//   const [selectedMrn, setSelectedMrn] = useState(null); // State to store the MRN
//   if (m_strUser !== "") {
//     return (
//       <>
//         <Provider store={store}>
//           <Router>
//             <Routes>
//               <Route
//                 path="/"
//                 element={<Layout name={name} userEmail={m_strUser} />}
//               >
//                 <Route path="/" element={<Home />} />
//                 <Route path="/insurance" element={<Insurance />} />
//                 <Route path="/ar-manager" element={<ARManager name={name} userEmail={m_strUser} />} />
//                 <Route path="/pricing" element={<Pricing />} />
//                 <Route path="/tasklist" element={<TaskList />} />
//                 <Route path="/inventory" element={<Inventory />} />
//                 <Route path="/faxdirectory" element={<FaxDirectory name={name} userEmail={m_strUser} />} />
//                 <Route path="/fax" element={<FaxScreen />} />
//                 <Route path="/junk-fax" element={<JunkFaxScreen name={name} userEmail={m_strUser}  />} />
//                 <Route path="/archive-fax" element={<ArchiveFax />} />
//                 <Route path="/user-document/:id" element={<UserDocument userEmail={m_strUser}  />} />
//               </Route>
//             </Routes>
//           </Router>
//         </Provider>
//       </>
//     );
//   } else {
//     return (
//       <>
//         {Render()}

//         <Provider store={store}>
//           <Router>
//             <Routes>
//               <Route path="/" element={<Layout />}>
//                 Authenticating User...
//               </Route>
//             </Routes>
//           </Router>
//         </Provider>
//       </>
//     );
//   }
// }

// export default App


import React, { createContext, useEffect, useState } from "react";
import Sidebar from "./layouts/Sidebar/Sidebar";
import Header from "./layouts/Header/Header";
import { ScrollTop } from "primereact/scrolltop";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import Main from "./router/Router";
import { store } from "./redux/store";
import Insurance from "./pages/Insurance/Insurance";
import ARManager from "./pages/ARManager/ARManager";
// import Home from "./pages/Home/Home";
import { scrollTop } from "./assets/images/Icon";
import TaskList from "./pages/TaskList/TaskList";
import Pricing from "./pages/Pricing/Pricing";
import FaxDirectory from "./pages/FaxDirectory/FaxDirectory";
import Inventory from "./pages/Inventory/Inventory";
import FaxScreen from "./pages/Fax/FaxScreen";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'primeicons/primeicons.css';
import JunkFaxScreen from "./pages/junk-fax/JunkFaxesScreen";
import ArchiveFax from "./pages/archive/ArchiveFax";
import UserDocument from "./pages/document/UserDocument";
import { PatientProvider } from "./pages/Fax/Patientcontex";
import PrivateRoute from "./auth/PrivateRoute";
import Error from "./pages/Error/Error";
import Shipment from "./pages/Shipment/Shipment";
import NotesManager from "./pages/NotesManager/NotesManager";
import Census from "./pages/Census/Census";

function Layout({ userEmail, name }: { userEmail?: any, name?: any }) {
  return (
    <>
      <Header name={name} />
      <div className="sidebar">
        <Sidebar userEmail={userEmail} />
      </div>
      <div className="main-content">
        <Main />
      </div>
      <ScrollTop threshold={100} style={{ backgroundColor: "#1B3D6D" }} className="w-2rem h-2rem border-round-md" icon={() => <img src={scrollTop} alt="ScrollTop" />} />
    </>
  );
}

const App: React.FC = () => {
  useMsalAuthentication(InteractionType.Redirect);
  const [m_strUser, setm_strUser] = useState<string | undefined>("");
  const [name, setName] = useState<string | undefined>("");
  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0) {
      setm_strUser(accounts[0].username);
      setName(accounts[0].name);
    }
  }, [accounts]);

  return (
    <Provider store={store}>
      <PatientProvider> {/* Wrap your app with PatientProvider */}
        <Router>
          <Routes>
            <Route path="/" element={<Layout name={name} userEmail={m_strUser} />}>
              {/* <Route path="/" element={<Home />} /> */}
              <Route path="/access-denied" element={<Error />} />
              <Route path="/insurance" element={<PrivateRoute element={<Insurance userEmail={m_strUser}/>} userEmail={m_strUser} />} />
              <Route path="/ar-manager" element={<PrivateRoute element={<ARManager name={name} userEmail={m_strUser} />} userEmail={m_strUser}/>} />
              <Route path="/census" element={<PrivateRoute element={<Census name={name} userEmail={m_strUser} />} userEmail={m_strUser}/>} />
              <Route path="/pricing" element={<PrivateRoute element={<Pricing />} userEmail={m_strUser}/>} />
              <Route path="/tasklist" element={<PrivateRoute element={<TaskList userEmail={m_strUser} />} userEmail={m_strUser}/>} />
              <Route path="/inventory" element={<PrivateRoute element={<Inventory />} userEmail={m_strUser} />} />
              <Route path="/notes-manager" element={<PrivateRoute element={<NotesManager name={name} userEmail={m_strUser} />} userEmail={m_strUser} />} />
              <Route path="/faxdirectory" element={<PrivateRoute element={<FaxDirectory name={name} userEmail={m_strUser} />}  userEmail={m_strUser}/>} />
              <Route path="/fax" element={<PrivateRoute element={<FaxScreen />} userEmail={m_strUser} />} />
              <Route path="/fax/junk-fax" element={<PrivateRoute element={<JunkFaxScreen name={name} userEmail={m_strUser}/>} userEmail={m_strUser} />} />
              <Route path="/fax/archive-fax" element={<PrivateRoute element={<ArchiveFax />} userEmail={m_strUser} />} />
              <Route path="/fax/user-document/:id" element={<PrivateRoute element={<UserDocument userEmail={m_strUser} />} userEmail={m_strUser} />} />
              <Route path="/shipment" element={<PrivateRoute element={<Shipment />} userEmail={m_strUser} />} />
            </Route>
          </Routes>
        </Router>
      </PatientProvider>
    </Provider>
  );
}

export default App;