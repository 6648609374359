import React, { useEffect, useState } from "react";
import { DataTable, DataTableSelectEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import { SortDecre, SortIncre } from "../../assets/images/Icon";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { FormatDate } from "../../utils/formatDate";
import ViewDocumentonShipment from "../Dialog/ViewDocumentonShipment";
import { GetHistoryofShipment } from "../../redux/store/ShipmentReducer";
import HistoryPopupForShipment from "../Dialog/HistoryPopupForShipment";


const ShipmentDataTable = ({
  data,
  filters,
  userEmail,
  search_string,
}: {
  data: any;
  filters: any;
  name: string | undefined;
  userEmail?: string;
  search_string: string;
  
}) => {
    const pageOptions = [5, 10, 25, 50, 75, 100, 125];

  // const [visible, setVisible] = useState(false);
  // const [faxNumber, setFaxNumber] = useState("");
  // const [faxRecordid, setRecordid] = useState(0);

  const [visible, setVisible] = useState(false);
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [Folderpath, setFolderpath] = useState("");
  const [HistoryData, setHistoryData] = useState("");
  const dispatch = useDispatch<AppDispatch>();



  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      // Ascending
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      // Descending
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };
  
  //Format Date
  const ShipdateFormat = (rowData: any) => (
    <span>{FormatDate(rowData.ship_date)}</span>
  );

  const ShipStartdateFormat = (rowData: any) => (
    <span>{FormatDate(rowData.start)}</span>
  );
  const ShipStopdateFormat = (rowData: any) => (
    <span>{FormatDate(rowData.stop)}</span>
  );



// Custom cell renderer for the tracking_no column
const trackingNoBodyTemplate = (rowData: any) => {
  return(
  <span className="trackingprevent"
    style={{ color: "#0a3e71", cursor: "pointer",textDecoration: "underline" }}   //textDecoration: "underline"
    onClick={() => onTrackingNoClick(rowData.tracking_no)}
  >
    {rowData.tracking_no}
  </span>
  )
};

// Function to handle the SP call when tracking_no is clicked
const onTrackingNoClick = (trackingNo: string) => {
  setVisibleHistory(true)
  // console.log("Tracking No clicked:", trackingNo);
  dispatch(GetHistoryofShipment(trackingNo))  // Example: callSP(trackingNo);

  //setHistoryData(HistoryDataFromAPIPASS)
  // setHistoryData(HistoryData)

};



  // Columns
  const columns = [
    { field: "mrn", header: "MRN", sortable: true },
    { field: "last_name", header: "Last Name", sortable: true },
    { field: "first_name", header: "First Name", sortable: true },
    { field: "tick_no", header: "Ticket No.", sortable: true },
    { field: "ship_date", header: "Ship Date", sortable: true, body:ShipdateFormat},
    // { field: "confirmation_date ", header: "Conf. Date", sortable: true },
    { field: "rx_type", header: "Rx Type", sortable: true },
    { field: "shipping_method", header: "Ship Method", sortable: true },
    { field: "payor", header: "Payor", sortable: true },
    { field: "tracking_no", header: "Tracking No.", sortable: true , body: trackingNoBodyTemplate},
    { field: "status", header: "Status", sortable: true },
    { field: "team", header: "Team", sortable: true },
    { field: "start", header: "Start", sortable: true , body:ShipStartdateFormat},
    // { field: "driver", header: "Driver", sortable: true },
    { field: "stop", header: "Stop", sortable: true , body:ShipStopdateFormat},
    // { field: "ssn", header: "SSN", sortable: true },
  ];

   
  

  const onRowSelect = (e:any,data: any) => {
    // console.log(e.originalEvent, 'jsdbfjkdsbfj');
    if (
      e.originalEvent.target.closest("td span.trackingprevent")
    ) {
      return;
    }
      // console.log("Hiii",);
      setFolderpath(data.data);
      setVisible(true);
  };

  return (
    <>
      <DataTable
      // filteredStatuslist={filteredStatuslist}
        value={data}
        scrollable
        removableSort
        scrollHeight="645px"
        paginator
        // style={{ fontSize: "0.1rem !important" }} 
        sortIcon={sortIcon}
        onRowClick={(e) => onRowSelect(e, e.data)}
        selectionMode="single"
        rowsPerPageOptions={pageOptions}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rows={25}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        filters={filters}
        // loading={loading}
        globalFilterFields={columns.map((column) => column.field)}
        emptyMessage="No Data Found"
      >
       <Column headerStyle={{ paddingLeft: "3px" }}></Column>

        {columns.map((column, index) => (
          <Column
            key={index}
            field={column.field}
            header={column.header}
            body={column.body}
            sortable={column.sortable}
            // bodyStyle={{ fontSize: "0.1rem !important" }} // Inline style for specific column
          />
        ))}
      </DataTable>
      <ViewDocumentonShipment visible={visible} setVisible={setVisible} FilePathforDocumentView={Folderpath}  />
      <HistoryPopupForShipment  visible={visibleHistory} setVisible={setVisibleHistory}/>
    </>
  );
};

export default ShipmentDataTable;
