import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";

import Input from "../formElement/Input";
import InputField from "../formElement/InputField";
import InputTextArea from "../formElement/InputTextArea";

import "../../assets/css/tasklist.css";
import InputDropdown from "../formElement/InputDropdown";
import { useMsal } from "@azure/msal-react";
import { Button } from "primereact/button";
import { DropdownChangeEvent } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  addTask,
  getPatientToDoList,
  Task,
} from "../../redux/store/TasklistReducer";
import { AppDispatch, RootState } from "../../redux/store";
import { AutoComplete } from "primereact/autocomplete";
import { number, string } from "yup";
import InputDate from "../formElement/InputDate";
import {
  getFaxDocDrugList,
  getSearchPatientList,
} from "../../redux/store/FaxSliceReducer";
import axios, { CancelTokenSource } from "axios";
import { displayToast } from "../Toast/ToastMessage";

const AddNewTask = ({
  editData,
  visible,
  setVisible,
  action,
  userEmail,
  filterQuery,
}: {
  editData?: Task;
  visible: boolean;
  setVisible: any;
  action: string;
  userEmail?: string;
  filterQuery: string;
}): React.ReactElement => {
  const { tasklist, Category, assingBy, assingTo, statusList } = useSelector(
    (state: RootState) => state.tasklistReducer
  );
  const [generalTaskData, setGeneralTaskData] = useState({
    MRN: "",
    DueDate: "",
    PatientName: "",
    TaskDetails: "",
    Site: "",
    Team: "",
    Therapy: "",
    AssignedBy: "",
    AssignedTo: 0,
    Category: "",
    Comments: "",
    Status: "",
    patient_id: 0,
    patient_todo_id: 0,

    category_id: number,
    due_date: "",
    comment: "",
    status_id: 0,
    created_by: "",
  });
  const [invalidFields, setInvalidFields] = useState({
    MRN: false,
    PatientName: false,
    Site: false,

    TaskDetails: false,
    AssignedTo: false,
    Category: false,
    DueDate: false,
    Comments: false,
  });
  const [name, setName] = useState<string | undefined>("");
  const { accounts } = useMsal();
  const dispatch = useDispatch<AppDispatch>();
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    setName(accounts[0]?.name || "");
    setGeneralTaskData((prev) => ({
      ...prev,
      AssignedBy: accounts[0]?.name || "",
    }));
  }, [accounts]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setGeneralTaskData((prev) => ({ ...prev, [id]: value }));
    if (value) {
      setInvalidFields((prevInvalidFields) => ({
        ...prevInvalidFields,
        [id]: false,
      }));
    }
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setGeneralTaskData((prev) => ({
      ...prev,
      [id]: value,
    }));
    if (value) {
      setInvalidFields((prevInvalidFields) => ({
        ...prevInvalidFields,
        [id]: false,
      }));
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    if (value) {
      const localDate = new Date(value);
      const normalizedDate = new Date(
        Date.UTC(
          localDate.getFullYear(),
          localDate.getMonth(),
          localDate.getDate(),
          0,
          0,
          0,
          0
        )
      );

      const utcDateString = normalizedDate.toISOString();

      setGeneralTaskData((prev) => ({
        ...prev,
        [id]: utcDateString,
      }));

      setInvalidFields((prevInvalidFields) => ({
        ...prevInvalidFields,
        [id]: false,
      }));
    }
  };

  const handleDropdownChange = (e: DropdownChangeEvent) => {
    const {
      value,
      target: { id },
    } = e;
    setGeneralTaskData((prev) => ({
      ...prev,
      [id]: value,
    }));
    if (value) {
      setInvalidFields((prevInvalidFields) => ({
        ...prevInvalidFields,
        [id]: false,
      }));
    }
  };

  const OnSubmit = async () => {
    const updatedInvalidFields = {
      MRN: !generalTaskData.MRN,
      PatientName: !generalTaskData.PatientName,
      Site: !generalTaskData.Site,
      TaskDetails: !generalTaskData.TaskDetails,
      AssignedTo: !generalTaskData.AssignedTo,
      Category: !generalTaskData.Category,
      DueDate: !generalTaskData.DueDate,
      Comments: !generalTaskData.Comments,
    };

    const hasInvalidFields = Object.values(updatedInvalidFields).some(
      (isInvalid) => isInvalid
    );

    setInvalidFields(updatedInvalidFields);

    if (hasInvalidFields) {
      return;
    }

    setVisible(false);
    const data = {
      patient_todo_id: 0,
      mrn: generalTaskData.MRN,
      task_detail: generalTaskData.TaskDetails,
      //@ts-ignore
      assign_to: generalTaskData.AssignedTo,
      created_by: userEmail,
      //@ts-ignore
      category_id: generalTaskData.Category,
      due_date: generalTaskData.DueDate,
      comment: generalTaskData.Comments,
      status_id: 3,
    };

    await dispatch(
      addTask({
        updateData: data,
        callback: (res: any) => {
          if (res.data.success) {
            displayToast({
              msg: "Task have been added successfully.",
              type: "success",
            });
            dispatch(getPatientToDoList({ SearchString: filterQuery }));
          } else {
            displayToast({
              msg: res?.data?.message || "Something went wrong",
              type: "error",
            });
          }
        },
      })
    );
    setVisible(false);
  };

  const handleUpdate = async () => {
    setVisible(false);
    const Updatedata = {
      patient_todo_id: generalTaskData.patient_todo_id,
      mrn: generalTaskData.MRN,
      task_detail: generalTaskData.TaskDetails,
      created_by: generalTaskData.created_by,
      //@ts-ignore
      assign_to: generalTaskData.AssignedTo,
      //@ts-ignore
      category_id: generalTaskData.Category,
      due_date: new Date(generalTaskData.DueDate).toISOString(),
      comment: generalTaskData.Comments,
      //@ts-ignore
      status_id: generalTaskData.status_id,
    };

    await dispatch(
      addTask({
        updateData: Updatedata,
        callback: (res: any) => {
          if (res.data.success) {
            displayToast({
              msg: "Task have been updated successfully.",
              type: "success",
            });
            dispatch(getPatientToDoList({ SearchString: filterQuery }));
          } else {
            displayToast({
              msg: res?.data?.message || "Something went wrong",
              type: "error",
            });
          }
        },
      })
    );
    setVisible(false);
  };

  useEffect(() => {
    if (action === "edit" && editData) {
      setGeneralTaskData({
        MRN: editData.mrn,
        DueDate: editData.due_date,
        PatientName: editData.patient_first_name,
        TaskDetails: editData.task_detail,
        Site: editData.site_name,
        Team: editData.teams,
        Therapy: editData.therapy_type,
        AssignedBy: editData.assigned_by_name,
        AssignedTo: editData.assign_to,
        Category: editData.category_id,
        Comments: editData.comment,
        Status: editData.status_description,
        patient_id: editData.patient_id,

        patient_todo_id: editData.patient_todo_id,
        //@ts-ignore
        assign_to: editData.assign_to,
        //@ts-ignore

        category_id: editData.category_id,
        due_date: editData.due_date,
        task_detail: editData.task_detail,
        comment: editData.comment,
        //@ts-ignore
        status_id: editData.status_id,
        created_by: editData.created_by,
      });
    }

    if (!visible) {
      setName(accounts[0]?.name);
      setSelectedPatientm("");
      setGeneralTaskData({
        MRN: "",
        DueDate: "",
        PatientName: "",
        TaskDetails: "",
        Site: "",
        Team: "",
        Therapy: "",
        AssignedBy: "",
        AssignedTo: 0,
        Category: "",
        Comments: "",
        Status: "",
        patient_id: 0,
        patient_todo_id: 0,

        category_id: number,
        due_date: "",
        comment: "",
        status_id: 0,
        created_by: "",
      });
    }

    setInvalidFields({
      MRN: false,
      PatientName: false,
      Site: false,
      // Team: false,
      // Therapy: false,
      TaskDetails: false,
      AssignedTo: false,
      Category: false,
      DueDate: false,
      Comments: false,
    });
  }, [visible]);

  const Header = (
    <div className="Header-text">
      <p>New Task</p>
    </div>
  );

  const searchPatients = (query: string) => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Operation canceled due to a new request.");
    }
    cancelTokenRef.current = axios.CancelToken.source();

    dispatch(
      getSearchPatientList({
        query,
        mail: userEmail,
        cancelToken: cancelTokenRef.current.token,
      })
    )
      .unwrap()
      .then((response) => {
        if (Array.isArray(response)) {
          const formattedPatients = response?.map((patient) => ({
            // @ts-ignore
            mrn: patient.mrn,
            // @ts-ignore
            first_name: patient.first_name,
          }));
          // @ts-ignore
          setFilteredPatients(response);
        } else {
          setFilteredPatients([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching patients:", error);
      });
  };

  useEffect(() => {
    // @ts-ignore
    if (selectedPatient?.mrn) {
      setGeneralTaskData((prev) => ({
        ...prev,
        // @ts-ignore
        MRN: selectedPatient?.mrn,
        // @ts-ignore
        PatientName: selectedPatient?.patientName,
        // @ts-ignore
        Site: selectedPatient?.site,
        // @ts-ignore
        Team: selectedPatient?.patient_team,
        // @ts-ignore
        Therapy: selectedPatient?.therapy,
        // Status: "",
      }));
    }
  }, [selectedPatient]);

  const [selectedPatientm, setSelectedPatientm] = useState("");
  const [selectedPatientData, setSelectedPatientData] = useState(null);
  const [selectedPatientDetails, setSelectedPatientDetails] = useState("");

  const onPatientSelect = (e: any) => {
    setSelectedPatientDetails(`${e.value?.full_name} - ${e.value?.mrn}`);

    setSelectedPatientData(e.value);
    setGeneralTaskData((prev) => ({
      ...prev,
      MRN: e.value?.mrn,
      PatientName: e.value?.full_name,
      Site: e.value?.site,
      //@ts-ignore
      Team: selectedPatientData?.patient_team,
      Therapy: e.value?.therapy,
    }));

    setInvalidFields((prevInvalidFields) => ({
      ...prevInvalidFields,
      MRN: false,
      PatientName: false,
      Site: false,
    }));

    setSelectedPatientm(e.value.first_name);
  };

  return (
    <div>
      <Dialog
        draggable={false}
        header={action === "add" ? "New Task" : "Edit Task"}
        visible={visible}
        style={{ minWidth: "60vw", height: "70vh" }}
        className="task-dialog"
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div>
          <div className="flex align-items-center p-1 mb-3">
            <label className="font-semibold">Search Patient</label>
            <div className="relative w-6 p-1 ml-4">
              <i className="pi pi-search  absolute z-2 search-icon" />
              <AutoComplete
                value={selectedPatientm}
                suggestions={filteredPatients}
                completeMethod={(e) => searchPatients(e.query)}
                field="first_name"
                onSelect={onPatientSelect}
                onChange={(e) => setSelectedPatientm(e.value)}
                panelClassName="patient-search-panel taslistMrn_Field"
                placeholder="Search with MRN, Patient Name"
                className="MRN-Search w-8"
                itemTemplate={(item) => (
                  <div className="d-flex align-items-center gap-2">
                    {item?.first_name} {item?.last_name} - {item?.mrn}
                  </div>
                )}
                disabled={action !== "add"}
              />
            </div>
          </div>
          <div className="grid Input-fields">
            <div className="col-6">
              <div className="">
                <Input
                  label="MRN"
                  type="text"
                  id="MRN"
                  value={generalTaskData.MRN}
                  labelclassName="col-4 label-lg"
                  handleChange={handleInputChange}
                  inputclassName="col-6 Input-field"
                  disabled
                  invalid={invalidFields.MRN}
                  required={true}
                />
              </div>
              {/* <span> *</span> */}
              <div className="">
                <Input
                  label="Patient Name"
                  type="text"
                  id="PatientName"
                  value={generalTaskData.PatientName}
                  labelclassName="col-4 label-lg"
                  handleChange={handleInputChange}
                  inputclassName="col-6 Input-field"
                  disabled
                  invalid={invalidFields.PatientName}
                  required={true}
                />
              </div>
              <div className="">
                <Input
                  label="Site"
                  type="text"
                  value={generalTaskData.Site}
                  id="Site"
                  labelclassName="col-4 label-lg"
                  handleChange={handleInputChange}
                  inputclassName="col-6 Input-field"
                  disabled
                  invalid={invalidFields.Site}
                  required={true}
                />
              </div>
              <div className="">
                <Input
                  label="Team"
                  type="text"
                  value={generalTaskData.Team}
                  id="Team"
                  labelclassName="col-4 label-lg"
                  handleChange={handleInputChange}
                  inputclassName="col-6 Input-field"
                  disabled
                />
              </div>
              <div className="">
                <Input
                  label="Therapy"
                  type="text"
                  id="Therapy"
                  value={generalTaskData.Therapy}
                  labelclassName="col-4 label-lg"
                  handleChange={handleInputChange}
                  inputclassName="col-6 Input-field"
                  disabled
                />
              </div>
              <div className="">
                <Input
                  label="Assigned By"
                  type="text"
                  value={name}
                  disabled
                  id="AssignedBy"
                  labelclassName="col-4 label-lg"
                  inputclassName="col-6 Input-field"
                />
              </div>
              <div>
                <InputDropdown
                  options={assingTo.map((item) => {
                    return {
                      label: item.full_name,
                      value: item.employee_id,
                    };
                  })}
                  label="Assigned To"
                  optionLabel="label"
                  type="text"
                  id="AssignedTo"
                  value={Number(generalTaskData.AssignedTo)}
                  dropdownclassName="col-6 Input-field task-list-dropdown"
                  handleChange={handleDropdownChange}
                  labelclassName="col-4 label-lg"
                  invalid={invalidFields.AssignedTo}
                  filter
                  requiredRight={true}
                />
              </div>
              <div>
                <InputDropdown
                  options={Category.map((item) => {
                    return {
                      label: item.description,
                      value: item.drug_category_id,
                    };
                  })}
                  label="Category"
                  optionLabel="label"
                  type="text"
                  id="Category"
                  value={generalTaskData.Category}
                  handleChange={handleDropdownChange}
                  dropdownclassName="col-6 Input-field task-list-dropdown"
                  labelclassName="col-4 label-lg"
                  invalid={invalidFields.Category}
                  filter
                  requiredRight={true}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="input-area">
                <InputDate
                  id="DueDate"
                  value={new Date(generalTaskData.DueDate)}
                  handleChange={handleDateChange}
                  placeholder="Select a date"
                  labelclassName="col-4 label-lg"
                  inputclassName="col-7 TaskListCalender"
                  label="Due Date"
                  invalid={invalidFields.DueDate}
                  requiredRight={true}
                />
              </div>
              <div className="text-area pt-3">
                <InputTextArea
                  label="Task Details"
                  value={generalTaskData.TaskDetails}
                  rows={6}
                  id="TaskDetails"
                  handleChange={handleTextAreaChange}
                  labelclassName="col-4 label-lg"
                  inputclassName="col-7"
                  placeholder=""
                  invalid={invalidFields.TaskDetails}
                  requiredRight={true}
                />
              </div>
              <div className="text-area pt-1">
                <InputTextArea
                  label="Comments"
                  value={generalTaskData.Comments}
                  rows={6}
                  labelclassName="col-4 label-lg"
                  inputclassName="col-7 "
                  handleChange={handleTextAreaChange}
                  id="Comments"
                  invalid={invalidFields.Comments}
                  placeholder=""
                  requiredRight={true}
                />
              </div>
              {action === "edit" && (
                <div>
                  <InputDropdown
                    options={statusList.map((item) => {
                      return {
                        label: item.status_description,
                        value: item.status_id,
                      };
                    })}
                    label="Status"
                    type="text"
                    id="status_id"
                    optionLabel="label"
                    placeholder="Select status"
                    value={generalTaskData.status_id}
                    dropdownclassName="col-7 Input-field task-list-dropdown"
                    handleChange={handleDropdownChange}
                    labelclassName="col-4 label-lg"
                  />
                </div>
              )}
            </div>
            <div className="col-offset-10 p-2">
              <Button
                label={action === "add" ? "Save Task" : "Update"}
                className="custom-button  "
                onClick={action === "add" ? OnSubmit : handleUpdate}
                style={{ marginLeft: "10px" }}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddNewTask;
