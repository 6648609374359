import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Column } from "primereact/column";
import { FormatDate } from "../../../utils/formatDate";
import { SortDecre, SortIncre } from "../../../assets/images/Icon";

const ViewNotesDialogBox = ({
  visible,
  setVisible,
  selectedMrn,
}: {
  visible: boolean;
  setVisible: any;
  selectedMrn: string;
}) => {
  console.log(selectedMrn, 'selectedMrn');
  
  const { patientNotes, billingNotes, progressNoteLoading, billingNoteLoading } = useSelector(
    (state: RootState) => state.arManagerReducer
  );
  console.log(patientNotes, 'patientNotes');
  const [number, setNumber] = useState<number>(0);

  const billingColumns = [
    {
      field: "invoice_number",
      header: "Inv#",
      sortable: true,
    },
    {
      field: "date",
      header: "Date",
      sortable: true,
      body: (rowData: any) => <span>{FormatDate(rowData.date)}</span>,
    },
    {
      field: "entered_by",
      header: "Entered By",
      sortable: true,
    },
    { field: "subject", header: "Subject", sortable: true },
    {
      field: "body",
      header: "Notes Details",
      sortable: true,
    },
  ];

  const progressColumns = [
    {
      field: "date",
      header: "Date",
      sortable: true,
      body: (rowData: any) => <span>{FormatDate(rowData.date)}</span>,
    },
    {
      field: "type",
      header: "Type",
      sortable: true,
    },
    {
      field: "entered_by",
      header: "Entered By",
      sortable: true,
    },
    { field: "subject", header: "Subject", sortable: true },
    {
      field: "body",
      header: "Notes Details",
      sortable: true,
    },
  ];

  const renderHeader = (
    <div className="buttons flex gap-2">
      <Button
        outlined={number !== 0}
        label="Billing Notes"
        onClick={() => setNumber(0)}
      />
      <Button
        outlined={number !== 1}
        label="Progress Notes"
        onClick={() => setNumber(1)}
      />
    </div>
  );

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      // Ascending
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      // Descending
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  return (
    <>
      <Dialog
        style={{ width: "1500px", zIndex: 1111 }}
        draggable
        header={renderHeader}
        resizable
        visible={visible}
        onHide={() => setVisible(false)}
      >
        {number === 0 && 
        <DataTable
        value={billingNotes}
        emptyMessage="No Data Found"
        scrollable
        removableSort
        scrollHeight="612px"
        sortIcon={sortIcon}
        loading={billingNoteLoading}
      >
        {billingColumns.map((column, index) => (
          <Column
            key={index}
            field={column.field}
            header={column.header}
            sortable={column.sortable}
            body={column.body}
          />
        ))}
      </DataTable>
        }
        {number === 1 && (
          <DataTable
            value={patientNotes}
            emptyMessage="No Data Found"
            scrollable
            removableSort
            scrollHeight="612px"
            sortIcon={sortIcon}
            loading={progressNoteLoading}
          >
            {progressColumns.map((column, index) => (
              <Column
                key={index}
                field={column.field}
                header={column.header}
                sortable={column.sortable}
                body={column.body}
              />
            ))}
          </DataTable>
        )}
      </Dialog>
    </>
  );
};

export default ViewNotesDialogBox;
