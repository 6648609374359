import React, { useEffect, useState } from "react";
// import DocumentHead from "../../components/Filter/DocumentHead";
import DocumentHead from "../../components/Filter/DocumentFilter/DocumentHead";
import DocumentUserModal from "./DocumentUserModal";
import EmailModal from "./EmailModal";
// import samplePDF from "../../assets/pdf/sample (2).pdf";
import samplePDF from "../../assets/pdf/sample (2).pdf";
// import samplePDF from "../../assets/pdf/sample.pdf";
import DemoPDF from "./DemoPDF";
import { usePatientContext } from "../Fax/Patientcontex";
import SyncFusionDemo from "./SyncFusionDemo";
// import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";
import { useSelector } from "react-redux";
// import {
//     GetAddressMasterInfo,
//     getDocumentCatogory,
//     getDocumentDescription,
//     getSites,
//     getUsersAndGroups
// } from "../../redux/store/FaxSliceReducer";

const UserDocument = ({ userEmail }) => {

    // const dispatch = useDispatch();
    const { markJunkLoading } = useSelector((state) => state.FaxSliceReducer);

    const [isUserModal, setIsUserModal] = useState(false);
    const [isEmailModal, setIsEmailModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState([]);
    const [selectedPatientm, setSelectedPatientm] = useState('');
    const [description, setDescription] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [faxDetail, setFaxDetail] = useState({});
    const [selectedPatientDetails, setSelectedPatientDetails] = useState(null);
    const [selectedPatientData, setSelectedPatientData] = useState(null);
    const [checkList, setCheckList] = useState([]);
    const [notes, setNotes] = useState("");
    const [fromAndTo, setFromAndTo] = useState({
        from: 1,
        to: 1,
    });
    const [isSendEmail, setIsSendEmail] = useState(false);
    const [emailData, setEmailData] = useState({
        to: [],
        cc: "",
        subject: "",
        body: "",
    });
    const [isGroupChecked, setIsGroupChecked] = useState(false);
    const [errorFields, setErrorFields] = useState({ to: false, subject: false });
    const [isDisabled, setIsDisabled] = useState(true);

    const { selectedPatientmrn } = usePatientContext();

    useEffect(() => {
        setFromAndTo((prev) => ({
            ...prev,
            to: faxDetail?.pages,
        }))
    }, [faxDetail]);

    useEffect(() => {
        if (isUserModal) {
            setIsEmailModal(false);
        }
    }, [isUserModal]);

    useEffect(() => {
        if (isEmailModal) {
            setIsUserModal(false);
        }
    }, [isEmailModal]);

    // useEffect(() => {
    // dispatch(GetAddressMasterInfo());
    // dispatch(getSites());
    // dispatch(getUsersAndGroups());
    // dispatch(getDocumentDescription());
    // dispatch(getDocumentCatogory());
    // }, []);

    // const location = window.location.pathname;

    // if (emailLoading || assignLoading) {
    //     return (
    //         <div>
    //             <Loader label='junk fax' location={location} path='/junk-fax' />
    //         </div>
    //     );
    // }

    if (markJunkLoading) {
        return (
            <Loader path="/abc" />
        );
    };

    return (
        <div className="document-content-page">
            <DocumentHead isDisabled={isDisabled} userEmail={userEmail} isUserModal={isUserModal} setIsUserModal={setIsUserModal} isEmailModal={isEmailModal} setIsEmailModal={setIsEmailModal} />
            {/* <EmailModal /> */}
            {/* <DemoPDF pdf={samplePDF} numPages={numPages} setNumPages={setNumPages} /> */}
            <div className="row">
                <div className={`${isUserModal || isEmailModal ? "col-lg-7 col-md-12" : "col-12"}`} md={isUserModal || isEmailModal ? "7" : "12"}>
                    {/* <embed
                        src={samplePDF}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                        style={{ height: '85vh' }}
                    /> */}
                    <SyncFusionDemo
                        setFaxDetail={setFaxDetail}
                        faxDetail={faxDetail}
                        pdf={samplePDF}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        isUserModal={isUserModal}
                        isEmailModal={isEmailModal}
                        isDisabled={isDisabled}
                        setIsDisabled={setIsDisabled}
                    />
                </div>
                {isUserModal && (
                    <div className={"col-lg-5 col-md-12"}>
                        <DocumentUserModal
                            faxDetail={faxDetail}
                            // selectedIndex={selectedIndex}
                            setIsUserModal={setIsUserModal}
                            userEmail={userEmail}
                            setSelectedPatientm={setSelectedPatientm}
                            selectedPatientm={selectedPatientm}
                            setIsEmailModal={setIsEmailModal}
                            description={description}
                            setDescription={setDescription}
                            selectedPatientDetails={selectedPatientDetails}
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                            setSelectedPatientDetails={setSelectedPatientDetails}
                            checkList={checkList}
                            setCheckList={setCheckList}
                            notes={notes}
                            setNotes={setNotes}
                            selectedPatientData={selectedPatientData}
                            setSelectedPatientData={setSelectedPatientData}
                            emailData={emailData}
                            setEmailData={setEmailData}
                            isSendEmail={isSendEmail}
                            setIsSendEmail={setIsSendEmail}
                            fromAndTo={fromAndTo}
                            setFromAndTo={setFromAndTo}
                            setErrorFields={setErrorFields}
                        />
                    </div>
                )}
                {isEmailModal && (
                    <div className={"col-lg-5 col-md-12"}>
                        <EmailModal
                            setIsEmailModal={setIsEmailModal}
                            selectedPatientmrn={selectedPatientmrn}
                            emailData={emailData}
                            setEmailData={setEmailData}
                            isSendEmail={isSendEmail}
                            setIsUserModal={setIsUserModal}
                            faxDetail={faxDetail}
                            isGroupChecked={isGroupChecked}
                            setIsGroupChecked={setIsGroupChecked}
                            errorFields={errorFields}
                            setErrorFields={setErrorFields}
                        />
                    </div>
                )}
            </div>

        </div>
    );
};

export default UserDocument;