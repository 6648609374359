import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASEURL;

interface PricingState {
  companies: any[];
  data: any[];
  drugCategories: any[];
  inventory: any[];
  specialPrices: any[];
  specialPricesWithInsuranceAndInventory: any[];
  specialPricesWithInsuranceAndInventorySecound: any[];
  specialPriceData: any[];
  loadingForSpecialPrices: boolean;
  loading: boolean;
  loader: boolean;
  error: string | null;
}

// Initialize the state
const initialState: PricingState = {
  companies: [],
  data: [],
  drugCategories: [],
  inventory: [],
  specialPrices: [],
  specialPricesWithInsuranceAndInventory: [],
  specialPricesWithInsuranceAndInventorySecound: [],
  specialPriceData: [],
  loading: false,
  loadingForSpecialPrices: false,
  loader: false,
  error: null,
};

export const getInsuranceCompanyList = createAsyncThunk(
  "insurance/getInsuranceCompanyList",
  async (_, { rejectWithValue }) => {
    try {
      const endpoint = `${baseUrl}/api/Insurance/getInsuranceCompanyList`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      // console.error("Error fetching user details", error);
    }
  }
);

export const getInventoryList = createAsyncThunk(
  "inventory/getInventoryList",
  async (_, { rejectWithValue }) => {
    try {
      const endpoint = `${baseUrl}/api/PricingList/getInventoryList`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      // console.error("Error fetching user details", error);
    }
  }
);

export const getSpecialPriceListWithInventoryAndInsurance = createAsyncThunk(
  "pricing/getSpecialPriceListWithInventoryAndInsurance",
  async ({ insuranceId, inventoryId }: { insuranceId: number; inventoryId: number }, { rejectWithValue }) => {
    try {
      const endpoint = `${baseUrl}/api/PricingList/getSpecialPriceList?insuranceComID=${insuranceId}&inventoryID=${inventoryId}`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      // console.error("Error fetching user details", error);
    }
  }
);

export const getSpecialPriceListWithInventoryAndInsuranceSecound = createAsyncThunk(
  "pricing/getSpecialPriceListWithInventoryAndInsuranceSecound",
  async ({ insuranceId, inventoryId }: { insuranceId: number; inventoryId: number }, { rejectWithValue }) => {
    try {
      const endpoint = `${baseUrl}/api/PricingList/getSpecialPriceList?insuranceComID=${insuranceId}&inventoryID=${inventoryId}`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      // console.error("Error fetching user details", error);
    }
  }
);

export const updateSpecialPrice = createAsyncThunk(
  'pricing/updateSpecialPrice',
  async ({ special_price_id, start_date, end_date, created_by }:{ special_price_id:number, start_date:string, end_date:string, created_by:string }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/PricingList/updateSpecialPrice', {
        special_price_id,
        start_date,
        end_date,
        created_by
      });
      return response.data;
    } catch (error) {
      //@ts-ignore
      return rejectWithValue(error.response?.data || 'Failed to update special price');
    }
  }
);



const pricingSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInsuranceCompanyList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInsuranceCompanyList.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        //@ts-ignore
        state.companies = action.payload?.data;
      })
      .addCase(getInsuranceCompanyList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getInventoryList.pending, (state) => {
        state.loader = true;
        state.error = null;
      })
      .addCase(getInventoryList.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.loader = false;
        //@ts-ignore
        state.inventory = action.payload?.data;
      })
      .addCase(getInventoryList.rejected, (state, action) => {
        state.loader = false;
        state.error = action.payload as string;
      })

      .addCase(getSpecialPriceListWithInventoryAndInsurance.pending, (state) => {
        state.loadingForSpecialPrices = true;
        state.error = null;
      })
      .addCase(getSpecialPriceListWithInventoryAndInsurance.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.loadingForSpecialPrices = false;
        //@ts-ignore
        state.specialPricesWithInsuranceAndInventory = action.payload?.data;
      })
      .addCase(getSpecialPriceListWithInventoryAndInsuranceSecound.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.loadingForSpecialPrices = false;
        //@ts-ignore
        state.specialPricesWithInsuranceAndInventorySecound = action.payload?.data;
      })
      .addCase(getSpecialPriceListWithInventoryAndInsurance.rejected, (state, action) => {
        state.loadingForSpecialPrices = false;
        state.error = action.payload as string;
      })
      .addCase(updateSpecialPrice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSpecialPrice.fulfilled, (state, action) => {
        state.loading = false;
        state.specialPriceData = action.payload;
      })
      .addCase(updateSpecialPrice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default pricingSlice.reducer;