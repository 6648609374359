import React, { useEffect, useState } from "react";
import "../../../assets/css/shipment.css";
import MultiSelectFilter from "../../formElement/MultiSelect";
import InputField from "../../formElement/InputField";
import { Button } from "primereact/button";
import MultiSelect from "../../../components/formElement/MultiSelect";
import { FilterMatchMode } from "primereact/api";
import { DataTableFilterMeta } from "primereact/datatable";
import ShipmentDataTable from "../../DataTable/ShipmentDataTable";

const ShipmentFilter = ({
  filters,
  setFilters,
  statusData,
  rxtype,
  shipmethod,
  team,
  alldata,
}: {
  filters: any;
  setFilters: any;
  statusData: any;
  rxtype: any;
  shipmethod: any;
  team: any;
  alldata: any;
}) => {
  //All multiselect onchange code
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedRxtype, setSelectedRxtype] = useState<string[]>([]);
  const [selectedShipmethod, setSelectedShipmethod] = useState<string[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string[]>([]);
  const [FilterMultiselect, setFilterMultiselect] =
    useState<DataTableFilterMeta>({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

  // const onStatusChange = (e: any) => {
  //   setSelectedStatus(e.value);
  //   };

  //status multiselect filter
  const onStatusChange = (e: any) => {
    setSelectedStatus(e.value);
    setFilters((prevFilters: DataTableFilterMeta) => ({
      ...prevFilters,
      status: { value: e.value, matchMode: FilterMatchMode.IN },
    }));
  };

  //RxType multiselect filter
  const onRxtypeChange = (e: any) => {
    setSelectedRxtype(e.value);
    setFilters((prevFilters: DataTableFilterMeta) => ({
      ...prevFilters,
      rx_type: { value: e.value, matchMode: FilterMatchMode.IN },
    }));
  };

  //Shipmethod multiselect filter
  const onShipmethodChange = (e: any) => {
    setSelectedShipmethod(e.value);
    setFilters((prevFilters: DataTableFilterMeta) => ({
      ...prevFilters,
      shipping_method: { value: e.value, matchMode: FilterMatchMode.IN },
    }));
  };

  //Team multiselect filter
  const onTeamChange = (e: any) => {
    setSelectedTeam(e.value);
    setFilters((prevFilters: DataTableFilterMeta) => ({
      ...prevFilters,
      team: { value: e.value, matchMode: FilterMatchMode.IN },
    }));
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };

    // @ts-ignore
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  //multisect filter onchange
  // console.log(alldata, "alldata");

  const filteredStatuslst = alldata.filter((status: any) => {
    if (selectedStatus.length === 0) return true;
    return selectedStatus.includes(status.status);
  });

  //status dropdown filter code
  // const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;
  //   let _filters = { ...filters };

  //   // @ts-ignore
  //   _filters["global"].value = value;

  //   setFilters(_filters);
  //   setGlobalFilterValue(value);
  // };

  //

  useEffect(() => {
    // console.log(statusData, "statusData");
  }, [statusData]);

  return (
    <div>
      <div className="row align-items-center mx-0">
        <div className="col-lg-2 col-md-2 px-1">
          <InputField
            placeholder="Search Patient Name, MRN"
            globalFilterValue={globalFilterValue}
            onGlobalFilterChange={onGlobalFilterChange}
            //   handleKeyDown={[]}
            id="patientSearch"
          />
        </div>

        <div className="col-lg-2 col-md-2 px-1 custom-width-lg-shipment">
          {/* <div className="multi-containerShipment">  */}
          {/* <MultiSelectFilter
            filterValue="value"
            id="collectorIds"
            value={selectedStatus}
            onChange={onStatusChange}
            label="label"
            data={statusData}
            placeholder="Status"
          /> */}

          <MultiSelect
            value={selectedStatus}
            filters={FilterMultiselect}
            data={statusData}
            label="label"
            filterValue="value"
            virtualScrollerOptions={{ itemSize: 50 }}
            placeholder="Select Status"
            onChange={onStatusChange}
          />
        </div>
        <div className="col-1">
          {/* <Button
            label="Reset"
            outlined
            //onClick={""}
          /> */}

          <Button
            label="Reset"
            outlined
            onClick={() => {
              // Reset global filter value
              setGlobalFilterValue("");

              // Reset MultiSelect values
              setSelectedStatus([]);
              setSelectedRxtype([]);
              setSelectedShipmethod([]);
              setSelectedTeam([]);

              // Reset filters
              setFilters({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                status: { value: null, matchMode: FilterMatchMode.IN },
                rx_type: { value: null, matchMode: FilterMatchMode.IN },
                shipping_method: { value: null, matchMode: FilterMatchMode.IN },
                team: { value: null, matchMode: FilterMatchMode.IN },
              });
            }}
          />
        </div>
      </div>
      <div className="row align-items-center mx-0 pb-2">
        <div className="col-lg-2 col-md-2 px-1 custom-width-lg-shipment">
          {/* <div className="multi-containerShipment"> */}

          <MultiSelectFilter
            filterValue="value"
            id="collectorIds"
            value={selectedRxtype}
            onChange={onRxtypeChange}
            label="label"
            data={rxtype}
            placeholder="Rx Type"
          />
        </div>

        <div className="col-lg-2 col-md-2 px-1 custom-width-lg-shipment">
          {/* <div className="multi-containerShipment"> */}
          <MultiSelectFilter
            filterValue="value"
            id="collectorIds"
            value={selectedShipmethod}
            onChange={onShipmethodChange}
            label="label"
            data={shipmethod}
            placeholder="Ship method"
          />
        </div>
        <div className="col-lg-2 col-md-2 px-1 custom-width-lg-shipment">
          {/* <div className="multi-containerShipment"> */}
          <MultiSelectFilter
            filterValue="value"
            id="collectorIds"
            value={selectedTeam}
            onChange={onTeamChange}
            label="label"
            data={team}
            placeholder="Team"
          />
        </div>
      </div>
      {/* <ShipmentDataTable data={filteredStatuslst} filters={filters}  name=""  userEmail=" "  search_string=" " /> */}
    </div>
  );
};

export default ShipmentFilter;
