import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { InvoiceClaim } from "../types/IHealthAppTypes";

const styles = StyleSheet.create({
  page: {
    padding: 0,
    fontSize: 10,
    marginLeft: 10,
    marginTop: 5,
    paddingRight: 50,
  },
  header: {
    fontSize: 14,
    marginBottom: 0,
    textAlign: "center",
    fontWeight: "heavy",
  },
  subHeader: {
    fontWeight: "heavy",
    textAlign: "center",
    marginBottom: 5,
    fontSize: 16,
  },
  secoundsubHeader: {
    fontWeight: "heavy",
    textAlign: "center",
    marginBottom: 10,
    fontSize: 12,
  },
  tableHeader: {
    flexDirection: "row",
    // borderBottom: "1px double black",
    fontWeight: "extrabold",
    paddingBottom: 2,
    // marginBottom: 5,
  },
  row: {
    flexDirection: "row",
    borderBottom: "1px solid #E0E0E0",
    paddingVertical: 3,
    width: "98%",
    alignSelf: "center",
  },
  column: {
    flex: 1,
    textAlign: "center",
    alignSelf: "center",
    fontSize: "10px",
  },
  totalsRow: {
    flexDirection: "row",
    marginTop: 10,
    paddingVertical: 3,
    justifyContent: "flex-end",
    gap: "15px",
  },
  totalsText: {
    flex: 1,
    textAlign: "center",
    fontWeight: "bold",
  },
  line: {
    height: "0.5px",
    backgroundColor: "black",
  },
  doubleLine: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  pagenumber: {
    alignSelf: "flex-end",
    fontSize: 11,
  },
});

export const paginateData = (
  data: InvoiceClaim[],
  rowsPerPage: number
): any => {
  const pages: InvoiceClaim[][] = [];
  for (let i = 0; i < data.length; i += rowsPerPage) {
    pages.push(data.slice(i, i + rowsPerPage));
  }
  return pages;
};

const ARManagerPdfConverter: React.FC<{ data: InvoiceClaim[][] }> = ({
  data,
}) => {
  const flatData = data.flat();
  const totalBilled = flatData
    .reduce((sum: number, item: InvoiceClaim) => sum + item.billed, 0)
    .toFixed(2);

  const totalExpected = flatData
    .reduce((sum: number, item: InvoiceClaim) => sum + item.expected, 0)
    .toFixed(2);

  const totalBalance = flatData
    .reduce((sum: number, item: InvoiceClaim) => sum + item.balance, 0)
    .toFixed(2);

    const formatCurrency = (amount: number): string => {
      return amount.toLocaleString(undefined, { style: "currency", currency: "USD" });
    }

  return (
    <Document>
      {data.map((record, pageIndex) => (
        <Page size="A4" style={styles.page} key={pageIndex}>
          <Text style={styles.pagenumber}>
            Page {pageIndex} of {data.length}
          </Text>
          <Text style={styles.header}>InfuCare Rx of NJ</Text>
          <Text style={styles.subHeader}>A/R MANAGER INVOICE LIST</Text>
          <Text style={styles.secoundsubHeader}>All Payors</Text>

          <View style={styles.tableHeader}>
            <Text style={styles.column}>Inv. #</Text>
            <Text style={styles.column}>Date</Text>
            <Text style={styles.column}>DOS</Text>
            <Text style={styles.column}>Pt. Name</Text>
            <Text style={styles.column}>Payor</Text>
            <Text style={styles.column}>Billed</Text>
            <Text style={styles.column}>Expected</Text>
            <Text style={styles.column}>Balance</Text>
          </View>
          <View style={styles.doubleLine}>
            <View style={styles.line}></View>
            <View style={styles.line}></View>
          </View>
          <View></View>
          {record.map((item: any, index: number) => (
            <View style={styles.row} key={index}>
              <Text style={styles.column}>{item.invClaimNo}</Text>
              <Text style={styles.column}>
                {new Date(item.from_).toLocaleDateString()}
              </Text>
              <Text style={styles.column}>
                {new Date(item.to_).toLocaleDateString()}
              </Text>
              <Text
                style={styles.column}
              >{`${item.last_name}, ${item.first_name}`}</Text>
              <Text style={styles.column}>{item.payor || "N/A"}</Text>
              <Text style={styles.column}>{item.billed.toFixed(2)}</Text>
              <Text style={styles.column}>{item.expected.toFixed(2)}</Text>
              <Text style={styles.column}>{item.balance.toFixed(2)}</Text>
            </View>
          ))}
          <View style={styles.line}></View>
          {pageIndex === data.length - 1 && (
            <View style={styles.totalsRow}>
              <View>
                <b>
                  <Text>Total Billed</Text>
                </b>
                <Text>{formatCurrency(Number(totalBilled))}</Text>
              </View>
              <View>
                <Text>Total Expected</Text>
                <Text>{formatCurrency(Number(totalExpected))}</Text>
              </View>
              <View>
                <Text>Balance:</Text>
                <Text>{formatCurrency(Number(totalBalance))}</Text>
              </View>
            </View>
          )}
        </Page>
      ))}
    </Document>
  );
};

export default ARManagerPdfConverter;
