import React, { useState, useEffect } from "react";
import { FilterMatchMode } from "primereact/api";
import Filterdata from "../../components/Filter/FaxFilter/FilterData";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchFaxData,
    toggleHistoryModal,
    getFaxHistory,
} from "../../redux/store/FaxSliceReducer";
import ArchiveFaxDataTable from "./ArchiveFaxDataTable";
import HistoryModal from "../junk-fax/History-modal";
import GlobalFaxFilter from "../../components/Filter/FaxFilter/GlobalFilter";
import FaxLineFilter from "../../components/Filter/FaxFilter/FaxLineFilter";
import DateRangeFilter from "../../components/Filter/FaxFilter/DateRangeFilter";
import { AppDispatch, RootState } from "../../redux/store";
import { Button } from "primereact/button";
import { MultiSelect } from 'primereact/multiselect';
import { useMemo } from "react";

type FaxItem = {
    fax_detail_id: string;
    fax_line: string;
    fax_number: string;
    caller: string;
    pages: string;
    assignment_status: string;
    date_and_time: string;
    file_name: string;
};

const ArchiveFax = () => {

    const dispatch = useDispatch<AppDispatch>();

    const { loading, archiveData, allFaxData } = useSelector((state: RootState) => state.FaxSliceReducer);

    // State Variables
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [filteredData, setFilteredData] = useState<FaxItem[]>(archiveData);

    // Filters state
    const [filters, setFilters] = useState({
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        fax_line: { value: [] as string[], matchMode: FilterMatchMode.IN },
        fax_number: { value: "", matchMode: FilterMatchMode.CONTAINS },
        caller: { value: "", matchMode: FilterMatchMode.CONTAINS },
        pages: { value: "", matchMode: FilterMatchMode.CONTAINS },
        assignment_status: { value: "", matchMode: FilterMatchMode.CONTAINS },
        archive_line: { value: [], matchMode: FilterMatchMode.IN },
    });

    const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
    const [faxFilterValue, setFaxFilterValue] = useState<string>("");

    useEffect(() => {
        const filtered = archiveData.filter((item) => {
            const itemDate = new Date(item.date_and_time);
            const inDateRange =
                startDate && endDate
                    ? itemDate >= new Date(startDate) && itemDate <= new Date(endDate)
                    : true;

            return inDateRange;
        });
        setFilteredData(filtered);
    }, [startDate, endDate, archiveData]);

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFilters((prev) => ({
            ...prev,
            global: { ...prev.global, value },
        }));
        setGlobalFilterValue(value);
    };
    const onFaxFilterChange = (e: { value: string[] }) => {
        const value = e.value;
        setFilters((prev) => ({
            ...prev,
            fax_line: { ...prev.fax_line, value },
        }));
    };


    useEffect(() => {
        if (allFaxData?.length === 0) {
            dispatch(fetchFaxData());
        }
    }, [dispatch]);

    const undoBody = (rowData: FaxItem) => (
        <div className="d-flex justify-content-center">
            <div
                onClick={() => {
                    dispatch(getFaxHistory(Number(rowData.fax_detail_id))); // Fetch fax history
                    dispatch(toggleHistoryModal({ historyModal: true, historyModalHeader: rowData.file_name })); // Open modal
                }}
                className="archive-btn edit-icon py-1"
            >
                <i className="pi pi-history" /> {/* History icon */}
            </div>
        </div>
    );
    const resetFilters = () => {
        setStartDate(undefined);
        setEndDate(undefined);
        setFilters({
            global: { value: "", matchMode: FilterMatchMode.CONTAINS },
            fax_line: { value: [], matchMode: FilterMatchMode.IN }, // Change here
            fax_number: { value: "", matchMode: FilterMatchMode.CONTAINS },
            caller: { value: "", matchMode: FilterMatchMode.CONTAINS },
            pages: { value: "", matchMode: FilterMatchMode.CONTAINS },
            assignment_status: { value: "", matchMode: FilterMatchMode.CONTAINS },
            archive_line: { value: [], matchMode: FilterMatchMode.IN },
        });
        setGlobalFilterValue("")
    };

    const faxLineOptions = useMemo(() => {
        const uniqueLines = Array.from(new Set(archiveData?.map(item => item.fax_line)));
        return uniqueLines.map(line => ({
            label: line === null ? "Blank" : line, // Show "Blank" for null values
            value: line === null ? line : line // Map null values to "_blank"
        }));
    }, [archiveData]);

    return (
        <div>
            <Filterdata
                filtersCompo={
                    <div className="position-relative w-75">
                        <div className="row dataflex filter-header">
                            <div className="col-md-3 pe-1">
                                <div className="search-bar">
                                    <GlobalFaxFilter
                                        globalFilterValue={globalFilterValue}
                                        onGlobalFilterChange={onGlobalFilterChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 pe-1">
                                <DateRangeFilter
                                    startDate={startDate}
                                    setStartDate={setStartDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                />
                            </div>
                            <div className="col-md-2  pe-1 dropdown-filter">
                                <MultiSelect
                                    value={filters.fax_line.value}
                                    options={faxLineOptions} // Assuming this is how fax lines are defined
                                    onChange={onFaxFilterChange}
                                    placeholder="Select Fax Lines"
                                    // display="chip" // Optional, for a better UI
                                    maxSelectedLabels={1}
                                    panelClassName="dropdown-filter-panel"
                                    filter
                                />
                            </div>
                            <div className='col-auto'>
                                <Button onClick={resetFilters} className="fax-reset-btn">Reset</Button>
                            </div>
                        </div>
                    </div>
                }
                setFilters={setFilters}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
            />

            <div className="card border-0  mt-0">
                <div className="table-responsive fax-table" style={{ overflowX: "auto" }}>
                    <ArchiveFaxDataTable
                        data={filteredData}
                        filters={filters}
                        loading={loading}
                        undoBody={undoBody} // Pass the undoBody function
                    />
                </div>
            </div>
            <HistoryModal /> {/* History modal component */}
        </div>
    );
};

export default ArchiveFax;