import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleHistoryModal } from "../../redux/store/FaxSliceReducer";
// import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import Loader from "../../components/loader/Loader";
import { useEffect } from "react";
import { RootState } from "../../redux/store";

interface HistoryItem {
    date: string;
    userName: string;
    status: string;
    actionType: string;
    actionDetail: string;
}

const HistoryModal: React.FC = () => {
    const dispatch = useDispatch();

    // Replace 'any' with the appropriate state type for better type safety
    const { historyModal, faxHistory, historyloader, historyModalHeader } = useSelector((state: RootState) => state.FaxSliceReducer);

    const toggle = () => {
        dispatch(toggleHistoryModal({ historyModal: false, historyModalHeader: "" }));
    };

    const location = window.location.pathname;

    return (
        <Dialog
            visible={historyModal}
            onHide={toggle}
            draggable={false}
            header={`Fax History${historyModalHeader ? " for " + historyModalHeader : ""}`}
            className={`fax-history-modal`}
        >
            {historyloader ? (
                <div className="fax-history-loader-parent">
                    <span className="fax-history-loader"></span>
                    <div className="loading-message font-semibold text-lg mt-4">
                        Loading fax history...
                    </div>
                </div>
            ) : (
                <DataTable
                    value={faxHistory}
                    // loading={historyloader}
                    // loadingIcon={<Loader label='junk fax' location={location} path='/junk-fax' />}
                    emptyMessage="No history available..."
                    className="history-datatable"
                >
                    <Column field="date" header="Date" />
                    <Column field="user_name" header="User Name" />
                    <Column field="status" header="Status" />
                    <Column field="action_type" header="Action Type" />
                    <Column field="action_details" header="Action Details" />
                </DataTable>
            )}
            {/* <DataTable
                value={faxHistory}
                loading={historyloader}
                loadingIcon={<Loader label='junk fax' location={location} path='/junk-fax' />}
            >
                <Column field="date" header="Date" />
                <Column field="user_name" header="User Name" />
                <Column field="status" header="Status" />
                <Column field="action_type" header="Action Type" />
                <Column field="action_details" header="Action Details" />
            </DataTable> */}
        </Dialog>
    );
};

export default HistoryModal;