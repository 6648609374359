import React from 'react';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loader from '../../components/loader/Loader';
import { SortDecre, SortIncre } from '../../assets/images/Icon';

const ArchiveFaxDataTable = ({
    data,
    loading,
    filters,
    undoBody,
}: {
    data: any[];
    loading: boolean;
    filters: DataTableFilterMeta;
    undoBody: (rowData: any) => React.ReactNode;
}) => {
    const pageOptions = [5, 10, 15, 25, 75, 100];

    const location = window.location.pathname;
    if (loading) {
        return (
            <div>
                <Loader label='archive fax' location={location} path='/archive-fax' />
            </div>
        );
    }

    function formatDate(dateString: string): string {
        const date = new Date(dateString);
        const month = (date.getMonth() + 1);
        const day = date.getDate();
        const year = date.getFullYear();

        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;

        return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
    }

    const sortIcon = (order: any) => {
        if (order.sortOrder === 1) {
            return <img className='ml-1' src={SortIncre} alt='Sort Ascending' />;
        } else if (order.sortOrder === -1) {
            return <img className='ml-1' src={SortDecre} alt='Sort Descending' />;
        }
        return <img className='ml-1' style={{ opacity: "0" }} src={SortDecre} alt='Sort Descending' />;
    };

    return (
        <div>
            <DataTable
                scrollable
                removableSort
                scrollHeight="750px"
                value={data}
                paginator
                rowsPerPageOptions={pageOptions}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rows={25}
                currentPageReportTemplate="Showing {first}-{last} of {totalRecords} Results"
                filters={filters}
                loading={loading}
                globalFilterFields={["fax_line", "owner_name",  "recipient_fax_number", "caller", "doc_category_description", "patient_name", "caller", "assignment_status"]}
                emptyMessage="No Records found."
                sortIcon={sortIcon}
            >
                <Column
                    field="recipient_fax_number"
                    sortable
                    header="Fax Number"
                    className="custom-width"
                />
                <Column field='owner_name' sortable header="Fax Owner Name" />
                <Column
                    className="custom-width"
                    field="date_and_time"
                    sortable
                    header="Date"
                    body={(data) => formatDate(data.date_and_time)}
                />
                <Column field="fax_line" sortable header="Fax Line" />
                <Column
                    field='caller'
                    sortable
                    header="InfucareRx Number"
                    className="custom-width"
                />
                <Column
                    field="assignment_status"
                    sortable
                    header="Assignment Status"
                />
                {/* <Column
                    field="caller"
                    sortable
                    header="Caller"
                    filterField="caller"
                    dataType="date"
                /> */}
                <Column
                    field="patient_name"
                    sortable
                    header="Action Details"
                />
                <Column
                    field="doc_category_description"
                    sortable
                    header="Category/Description"
                />
                <Column field="category" body={undoBody} header="" />  {/* Pass row data to undoBody */}
            </DataTable>
        </div>
    );
};

export default ArchiveFaxDataTable;