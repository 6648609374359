import { TabPanel, TabView } from "primereact/tabview";
import Input from "../../../formElement/Input";
import InputCheck from "../../../formElement/InputCheck";
import InputDropdown from "../../../formElement/InputDropdown";
import {
  EmployeeOption,
  Option,
  QuestionDataType,
  TransformedOption,
} from "../../../../types/IHealthAppTypes";

interface Field {
  response_id: number;
  question_id: number;
  question: string;
  Question_type: "DropDown" | "Single Line Text" | "Checkboxes" | string;
  answer: string | number | null;
  question_label: string;
  options?: Option[] | EmployeeOption[];
}

interface PatientQuestionsTabViewProps {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  questionAnswerValueList: Field[];
  setQuestionData: React.Dispatch<React.SetStateAction<QuestionDataType>>;
  questionData: QuestionDataType;
}

const PatientQuestionsTabView: React.FC<PatientQuestionsTabViewProps> = ({
  activeIndex,
  setActiveIndex,
  questionAnswerValueList,
  setQuestionData,
  questionData,
}) => {
  const questionsWithShortCode = [
    "question1",
    "question2",
    "question3",
    "question4",
    "question8",
    "question9",
    "question10",
    "question13",
    "question14",
    "question15",
    "question16",
    "question17",
    "question18",
    "question19",
    "question21",
    "question22",
    "question23",
    "question25",
    "question26",
    "question27",
    "question46",
    "question49",
    "question50",
    "question58",
    "question60",
  ];

  const disabledFields = ["question14", "question24", "question33"];
  const virtualScrollerFields = ["question49", "question58"];

  const handleChange = (e: any) => {
    const { id, type, value, checked } = e.target;
    let finalValue: string | number | null;

    if (["question20", "question29", "question40", "question45"].includes(id)) {
      finalValue = value ? value.replace(/[^0-9]/g, "") : "";
    } else {
      finalValue = type === "checkbox" ? (checked ? "true" : "false") : value;
    }

    setQuestionData((prevData) => ({
      ...prevData,
      [id]: finalValue,
    }));
  };

  const transformOptions = (
    options: (Option | EmployeeOption)[],
    question_label: string
  ): TransformedOption[] => {
    return options
      .filter((option): option is Option | EmployeeOption => {
        if ("answer" in option) {
          return option.answer !== null;
        } else if ("full_name" in option) {
          return option.full_name !== undefined;
        }
        return false;
      })
      .map<TransformedOption>((option: any) => {
        if ("answer" in option) {
          const isShortCodeApplicable =
            questionsWithShortCode.includes(question_label);
          return isShortCodeApplicable
            ? { label: option.answer, value: option.short_code.toString() }
            : { label: option.answer, value: option.answer.toString() };
        } else if ("full_name" in option) {
          return {
            label: option.full_name,
            value: option.employee_id.toString(),
          };
        }
        return { label: "", value: "" };
      });
  };

  const renderInput = (field: Field) => {
    const isDisabled = disabledFields.includes(field.question_label);
    const virtualScrollerEnable = virtualScrollerFields.includes(
      field.question_label
    );
    const options = field.options
      ? transformOptions(field.options, field.question_label)
      : [];
    const isFilterEnabled = options.length > 200;
    const value =
      questionData[field.question_label as keyof QuestionDataType] ?? "";
    switch (field.Question_type) {
      case "DropDown":
        return (
          <InputDropdown
            value={value as string}
            label={field.question}
            type="text"
            id={field.question_label}
            handleChange={handleChange}
            options={options}
            optionLabel="label"
            placeholder={`Select ${field.question_label}`}
            panelClassName="max-w-18rem"
            filter={isFilterEnabled}
            virtualScrollerOptions={
              virtualScrollerEnable ? { itemSize: 32 } : undefined
            }
            disabled={isDisabled}
          />
        );
      case "Checkboxes":
        return (
          <InputCheck
            label={field.question}
            checked={value === String(1) || value === "true"}
            id={field.question_label}
            handleChange={handleChange}
            className="my-1"
            disabled={isDisabled}
          />
        );
      case "Single Line Text":
        return (
          <Input
            label={field.question}
            type="text"
            value={value}
            id={field.question_label}
            handleChange={handleChange}
            disabled={isDisabled}
          />
        );
      default:
        return null;
    }
  };

  const chunkSize = 10;
  const tabData: Field[][] = [];
  for (let i = 0; i < questionAnswerValueList.length; i += chunkSize) {
    tabData.push(questionAnswerValueList.slice(i, i + chunkSize));
  }

  return (
    <TabView
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
    >
      {tabData.map((fields, index) => (
        <TabPanel header={`Tab ${index + 1}`} key={index}>
          <div className="grid">
            {fields.map((field, idx) => (
              <div className="col-12 p-0" key={idx}>
                {renderInput(field)}
              </div>
            ))}
          </div>
        </TabPanel>
      ))}
    </TabView>
  );
};

export default PatientQuestionsTabView;
