import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DataTableFilterMeta } from 'primereact/datatable';
import { SortDecre, SortIncre } from '../../assets/images/Icon';
import Loader from '../../components/loader/Loader';

interface JunkFaxDataTableProps {
    data: any[]; // Replace 'any' with the actual type of your data
    loading: boolean;
    filters: DataTableFilterMeta; // Use the correct type for filters
    undoBody: (rowData: any) => React.ReactNode; // Function returning a React node
}

const JunkFaxDataTable: React.FC<JunkFaxDataTableProps> = ({
    data,
    loading,
    filters,
    undoBody,
}) => {

    const pageOptions = [5, 10, 15, 25, 75, 100];

    const sortIcon = (order: any) => {
        if (order.sortOrder === 1) {
            return <img className='ml-1' src={SortIncre} alt='Sort Ascending' />;
        } else if (order.sortOrder === -1) {
            return <img className='ml-1' src={SortDecre} alt='Sort Descending' />;
        }
        return <img className='ml-1' style={{ opacity: "0" }} src={SortDecre} alt='Sort Descending' />;
    };

    const location = window.location.pathname;

    if (loading) {
        return (
            <div>
                <Loader label='junk fax' location={location} path='/junk-fax' />
            </div>
        );
    }

    function formatDate(dateString: string): string {
        const date = new Date(dateString);
        const month = (date.getMonth() + 1);
        const day = date.getDate();
        const year = date.getFullYear();

        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;

        return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
    }

    return (
        <div>
            <DataTable
                scrollable
                removableSort
                scrollHeight="750px"
                value={data}
                paginator
                rowsPerPageOptions={pageOptions}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rows={25}
                currentPageReportTemplate="Showing {first}-{last} of {totalRecords} Results"
                filters={filters}
                loading={loading}
                globalFilterFields={["fax_line", "owner_name", "recipient_fax_number", "caller", "caller", "assignment_status"]}
                emptyMessage="No Records found."
                sortIcon={sortIcon}

            >
                <Column field="recipient_fax_number" sortable header="Fax Number" className="custom-width" />
                <Column field='owner_name' sortable header="Fax Owner Name" />
                <Column className="custom-width" field="date" sortable header="Date" body={(data) => formatDate(data.date)} />
                <Column field="fax_line" sortable header="Fax Line" />
                <Column field='caller' sortable header="InfucareRx Number" className="custom-width" />
                <Column field="pages" sortable header="Page(s)" />
                {/* <Column field="caller" sortable header="Caller" /> */}
                <Column field="assignment_status" sortable header="Assignment Status" />
                <Column
                    field="category" // Adjust based on your actual data structure
                    body={(rowData) => (
                        <div>
                            {undoBody(rowData)}
                        </div>
                    )}
                    header=""
                />
            </DataTable>
        </div>
    );
};

export default JunkFaxDataTable;