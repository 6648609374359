export const Inventorydata = [
  {
      "Item": "Product A",
      "Qty": 10,
      "Inventory": 50,
      "Manufacturer": "Company X",
      "Supplier": "Supplier A",
      "TPN": "Pending",
      "NDC": "123456789",
      "HCPC": "A0123",
      "Site": "Site 1",
      "Location": "Warehouse A"
  },
  {
      "Item": "Product B",
      "Qty": 20,
      "Inventory": 100,
      "Manufacturer": "Company Y",
      "Supplier": "Supplier B",
      "TPN": "Approved",
      "NDC": "987654321",
      "HCPC": "B0123",
      "Site": "Site 2",
      "Location": "Warehouse B"
  },
  {
      "Item": "Product C",
      "Qty": 5,
      "Inventory": 30,
      "Manufacturer": "Company Z",
      "Supplier": "Supplier C",
      "TPN": "Rejected",
      "NDC": "111222333",
      "HCPC": "C0123",
      "Site": "Site 3",
      "Location": "Warehouse C"
  },
  {
      "Item": "Product D",
      "Qty": 8,
      "Inventory": 40,
      "Manufacturer": "Company A",
      "Supplier": "Supplier D",
      "TPN": "Pending",
      "NDC": "444555666",
      "HCPC": "D0123",
      "Site": "Site 4",
      "Location": "Warehouse D"
  },
  {
      "Item": "Product E",
      "Qty": 15,
      "Inventory": 75,
      "Manufacturer": "Company B",
      "Supplier": "Supplier E",
      "TPN": "Approved",
      "NDC": "777888999",
      "HCPC": "E0123",
      "Site": "Site 5",
      "Location": "Warehouse E"
  },
  {
      "Item": "Product F",
      "Qty": 12,
      "Inventory": 60,
      "Manufacturer": "Company C",
      "Supplier": "Supplier F",
      "TPN": "Rejected",
      "NDC": "222333444",
      "HCPC": "F0123",
      "Site": "Site 6",
      "Location": "Warehouse F"
  },
  {
      "Item": "Product G",
      "Qty": 3,
      "Inventory": 15,
      "Manufacturer": "Company D",
      "Supplier": "Supplier G",
      "TPN": "Pending",
      "NDC": "555666777",
      "HCPC": "G0123",
      "Site": "Site 7",
      "Location": "Warehouse G"
  },
  {
      "Item": "Product H",
      "Qty": 25,
      "Inventory": 125,
      "Manufacturer": "Company E",
      "Supplier": "Supplier H",
      "TPN": "Approved",
      "NDC": "888999000",
      "HCPC": "H0123",
      "Site": "Site 8",
      "Location": "Warehouse H"
  },
  {
      "Item": "Product I",
      "Qty": 7,
      "Inventory": 35,
      "Manufacturer": "Company F",
      "Supplier": "Supplier I",
      "TPN": "Rejected",
      "NDC": "111333555",
      "HCPC": "I0123",
      "Site": "Site 9",
      "Location": "Warehouse I"
  },
  {
      "Item": "Product J",
      "Qty": 18,
      "Inventory": 90,
      "Manufacturer": "Company G",
      "Supplier": "Supplier J",
      "TPN": "Pending",
      "NDC": "666777888",
      "HCPC": "J0123",
      "Site": "Site 10",
      "Location": "Warehouse J"
  },
  {
      "Item": "Product K",
      "Qty": 11,
      "Inventory": 55,
      "Manufacturer": "Company H",
      "Supplier": "Supplier K",
      "TPN": "Approved",
      "NDC": "999000111",
      "HCPC": "K0123",
      "Site": "Site 11",
      "Location": "Warehouse K"
  },
  {
      "Item": "Product L",
      "Qty": 6,
      "Inventory": 30,
      "Manufacturer": "Company I",
      "Supplier": "Supplier L",
      "TPN": "Rejected",
      "NDC": "333444555",
      "HCPC": "L0123",
      "Site": "Site 12",
      "Location": "Warehouse L"
  },
  {
      "Item": "Product M",
      "Qty": 22,
      "Inventory": 110,
      "Manufacturer": "Company J",
      "Supplier": "Supplier M",
      "TPN": "Pending",
      "NDC": "777888999",
      "HCPC": "M0123",
      "Site": "Site 13",
      "Location": "Warehouse M"
  },
  {
      "Item": "Product N",
      "Qty": 13,
      "Inventory": 65,
      "Manufacturer": "Company K",
      "Supplier": "Supplier N",
      "TPN": "Approved",
      "NDC": "111222333",
      "HCPC": "N0123",
      "Site": "Site 14",
      "Location": "Warehouse N"
  }
];
  
export const inventorydataArray = [
  {
    purchaseDate: "10/01/2024",
    cost: '100.5',
    lotNumber: "LOT1001",
    expDate: "12/31/2025",
    quantity: '300',
    site: "Warehouse A",
    serialNumber:"1"
  },
  {
    purchaseDate: "09/15/2024",
    cost: '200.75',
    lotNumber: "LOT1002",
    expDate: "11/30/2025",
    quantity: '150',
    site: "Warehouse B",
    serialNumber:"1"
  },
  {
    purchaseDate: "10/04/2024",
    cost: '150.0',
    lotNumber: "LOT1003",
    expDate: "01/15/2026",
    quantity:'500',
    site: "Warehouse A",
    serialNumber:"1"
  },
  {
    purchaseDate: "08/20/2024",
    cost: '75.25',
    lotNumber: "LOT1004",
    expDate: "10/05/2025",
    quantity: '250',
    site: "Warehouse C",
    serialNumber:"1"
  },
  {
    purchaseDate: "07/18/2024",
    cost: '300.0',
    lotNumber: "LOT1005",
    expDate: "04/20/2026",
    quantity: '100',
    site: "Warehouse B",
    serialNumber:"1"
  },
  {
    purchaseDate: "09/10/2024",
    cost: '180.45',
    lotNumber: "LOT1006",
    expDate: "09/30/2025",
    quantity: '200',
    site: "Warehouse A",
    serialNumber:"1"
  },
  {
    purchaseDate: "06/25/2024",
    cost: '120.75',
    lotNumber: "LOT1007",
    expDate: "12/25/2025",
    quantity: '600',
    serialNumber:"1",
    site: "Warehouse C",
  },
  {
    purchaseDate: "08/01/2024",
    cost:'220.5',
    lotNumber: "LOT1008",
    expDate: "01/10/2026",
    quantity: '350',
    site: "Warehouse B",
    serialNumber:"1"
  },
];


  export const RentalData = [
    {
      serialNumber: 'G6039',
      or: '1',
      status: 'In Service',
      inUseBy: 'John Doe',
      expiryDate: '12/31/2024',
      dispens: 'MedEquip',
      lastPM: '01/15/2024',
      nextPM: '01/15/2025',
      value: '$5000',
      placedInSvc: '03/10/2023',
      outOfSvc: '03/10/2023',
      site: 'New York Hospital',
    },
    {
      serialNumber: 'G6040',
      or: '2',
      status: 'Maintenance',
      inUseBy: 'Jane Doe',
      expiryDate: '06/30/2025',
      dispens: 'EquipRent',
      lastPM: '09/12/2023',
      nextPM: '09/12/2024',
      value: '$4500',
      placedInSvc: '10/01/2022',
      outOfSvc: '03/10/2023',
      site: 'Boston Medical Center',
    },
    {
      serialNumber: 'G6041',
      or: '1',
      status: 'Out of Service',
      inUseBy: 'Pending',
      expiryDate: '11/15/2026',
      dispens: 'BioTechRentals',
      lastPM: '02/20/2024',
      nextPM: '02/20/2025',
      value: '$4000',
      placedInSvc: '05/18/2021',
      outOfSvc: '03/10/2023',
      site: 'Chicago General',
    },
    {
      serialNumber: 'G6042',
      or: '3',
      status: 'In Service',
      inUseBy: 'Dr. Smith',
      expiryDate: '11/30/2023',
      dispens: 'EquipSupply',
      lastPM: '03/10/2023',
      nextPM: '03/10/2024',
      value: '$3500',
      placedInSvc: '08/12/2020',
      outOfSvc: '03/10/2023',
      site: 'San Francisco Clinic',
    }
  ];
  