import React, { useEffect, useState } from 'react';
import "../../../assets/css/notesmanager.css"
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import MultiSelectFilter from '../../formElement/MultiSelect';
import InputDropdown from '../../formElement/InputDropdown';
import InputTextArea from '../../formElement/InputTextArea';
import InputDate from '../../formElement/InputDate';
import { RadioButton } from 'primereact/radiobutton';
import { cprUser, patientSubject } from '../../../types/IHealthAppTypes';
import { displayToast } from '../../Toast/ToastMessage';
import { addUpdateNotesManagerBillingNotes } from '../../../redux/store/NotesManagerReducer';
import Loader from '../../loader/Loader';

const AddBillingNotesDialogBox = ({
  visible,
  setVisible,
  userEmail
}: {
  visible: boolean;
  userEmail: string | undefined;
  setVisible: (value: boolean) => void;
}) => {
  const { invoices } = useSelector(
    (state: RootState) => state.notesManagerReducer
  );

  const { patientBillingSubject, cprUser } = useSelector(
    (state: RootState) => state.arManagerReducer
  );

  const [loading, setLoading] = useState(false); // Add loading state
  const [addNotes, setAddNotes] = useState({
    inputjson: [],
    subject_name: '',
    notes_details: '',
    assign_user: '',
    followUpDate: undefined as Date | undefined,
  });
  const resetFields = () => {
    setAddNotes({
      inputjson: [],
      subject_name: '',
      notes_details: '',
      assign_user: '',
      followUpDate: undefined,
    });
    setErrors({
      inputjson: false,
      subject_name: false,
      notes_details: false,
    });
  };
  

  const [errors, setErrors] = useState({
    inputjson: false,
    subject_name: false,
    notes_details: false,
  });

  useEffect(() => {
    console.log(addNotes, 'addNotes');
  }, [addNotes]);

  const handleChange = (e: { target: { id: string; value: any } }) => {
    const { id, value } = e.target;
    setAddNotes((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleDateChange = (e: any) => {
    const { id, value } = e.target;
    if (id === 'followUpDate') {
      setAddNotes((prevState) => ({
        ...prevState,
        followUpDate: value,
      }));
    }
  };

  const handleMultiSelectChange = (e: any) => {
    const { id, value } = e.target;

    if (id === 'inputjson') {
      const updatedInputJson = value.map((selectedInvNo: string) => {
        const invoice = invoices.find((inv: any) => inv.inv_no === selectedInvNo);
        return {
          inv_no: selectedInvNo,
          mrn: invoice?.mrn || '',
        };
      });

      setAddNotes((prevState) => ({
        ...prevState,
        [id]: updatedInputJson,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {
      inputjson: addNotes.inputjson.length === 0,
      subject_name: !addNotes.subject_name.trim(),
      notes_details: !addNotes.notes_details.trim(),
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const dispatch = useDispatch<AppDispatch>();

  const handleSave = async () => {
    if (validateForm()) {
      setLoading(true); // Start loading
      const payload = {
        inputjson: addNotes.inputjson.map((item: any) => ({
          mrn: item.mrn,
          billno: parseInt(item.inv_no, 10),
        })),
        usermail: userEmail,
        subject: addNotes.subject_name,
        body: addNotes.notes_details,
        followupDate: addNotes.followUpDate
          ? addNotes.followUpDate.toISOString()
          : null,
        followupUserno: parseInt(addNotes.assign_user, 10),
      };
  
      try {
        const result = await dispatch(
          addUpdateNotesManagerBillingNotes({ data: payload })
        ).unwrap();
  
        if (result?.success) {
          displayToast({
            msg: 'Note saved successfully!',
            type: 'success',
          });
          resetFields(); // Reset fields after successful save
          setVisible(false);
        } else {
          displayToast({
            msg: result?.message || 'Failed to save note!',
            type: 'error',
          });
        }
      } catch (error) {
        displayToast({
          msg: 'Failed to save note!',
          type: 'error',
        });
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      displayToast({
        msg: 'Please fill the required fields!',
        type: 'error',
      });
    }
  };
  

  const Footer = (
    <div className="buttons flex gap-2 justify-content-end">
      <Button
        label="Cancel"
        onClick={() => {
          resetFields(); // Reset fields on Cancel
          setVisible(false);
        }}
      />
      <Button label="Save" onClick={handleSave} />
    </div>
  );
  

  return (
    <>
       <div className="dialog-wrapper">
    {loading && <Loader path="/notes-manager" label="Notes Manager" location={''} />}
      <Dialog
        style={{ width: '700px', zIndex: 1111 }}
        draggable
        header="Add Notes:"
        footer={Footer}
        visible={visible}
        onHide={() => {resetFields(); setVisible(false)}}
      >
        <div className="grid m-0 align-items-center">
          <div className="col-3">
            <label className="font-medium text-sm" htmlFor="inputjson">
              Notes Type
            </label>
          </div>
          <div className="col-9">
            <RadioButton inputId="rdBillingNotes" checked disabled></RadioButton>
            <label htmlFor="rdBillingNotes" className="ml-2">
              Billing Note
            </label>
          </div>
          <div className="col-3">
            <label className="font-medium text-sm" htmlFor="inputjson">
              For Invoice
            </label>
          </div>
          <div className="col-9">
            <MultiSelectFilter
              filterValue="inv_no"
              id="inputjson"
              value={addNotes.inputjson.map((item: any) => item.inv_no)} // Extract `inv_no` for display
              label="inv_no"
              onChange={handleMultiSelectChange}
              data={invoices}
              virtualScrollerOptions={{ itemSize: 50 }} // Ensure smooth scrolling for large data
            />
            {errors.inputjson && (
              <small className="p-error">Please select at least one invoice.</small>
            )}
          </div>
        </div>

        <InputDropdown
          value={addNotes.subject_name || ''}
          handleChange={handleChange}
          label="Subject"
          labelclassName="col-3"
          dropdownclassName="col-9"
          type="text"
          optionLabel="label"
          id="subject_name"
          options={patientBillingSubject.map((subject: patientSubject) => ({
            label: subject.subject_name,
            value: subject.subject_name,
          }))}
          // className={errors.subject_name ? 'p-invalid' : ''}
        />
        {errors.subject_name && (
          <small className="p-error">Subject is required.</small>
        )}

        <InputTextArea
          label="Note Details"
          id="notes_details"
          labelclassName="col-3"
          value={addNotes.notes_details}
          inputclassName="col-9"
          placeholder="Note Details"
          handleChange={handleChange}
          rows={5}
        />
        {errors.notes_details && (
          <small className="p-error">Note details cannot be empty.</small>
        )}

        <InputDropdown
          value={addNotes.assign_user}
          handleChange={handleChange}
          label="Follow up Assigned To"
          labelclassName="col-3"
          dropdownclassName="col-9"
          type="text"
          optionLabel="label"
          id="assign_user"
          options={(cprUser || []).map((emp_name: cprUser) => ({
            label: `${emp_name.first_name} ${emp_name.last_name}`,
            value: emp_name.user_no,
          }))}
        />
        <InputDate
          value={addNotes.followUpDate}
          label="Follow up Date"
          id="followUpDate"
          labelclassName="col-3"
          inputclassName="col-9"
          handleChange={handleDateChange}
        />
      </Dialog>
      </div>
    </>
  );
};

export default AddBillingNotesDialogBox;
