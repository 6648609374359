import * as React from "react";
import { InputNumber } from "primereact/inputnumber";

const InputNum = ({
  value,
  label,
  id,
  labelclassName,
  inputClassName,
  onChange,
  maxLength,
}: {
  value?: number | null;
  label: string;
  id: string;
  labelclassName?: string;
  inputClassName?: string;
  onChange?: any;
  maxLength?: number;
}): React.ReactElement => {
  return (
    <>
      <div className="grid m-0 align-items-center">
        <div className={`${labelclassName ? `${labelclassName}` : "col-6"}`}>
          <label className="font-medium text-sm" htmlFor={id}>
            {label}
          </label>
        </div>
        <div className={`${inputClassName ? `${inputClassName}` : "col-6"}`}>
          <InputNumber value={value} id={id} onValueChange={onChange} maxLength={maxLength}/>
        </div>
      </div>
    </>
  );
};

export default InputNum;
